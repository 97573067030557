import React, { Dispatch, SetStateAction } from 'react'
import { styled } from '@mui/system'
import { Autocomplete, Grid, Typography } from '@mui/material'
import { TextField } from '../../../../../ui'
import { TextField as MuiTextField } from '@mui/material'
import { generateUtilityClasses } from '@mui/material'
import DropdownFieldSearch from '../../../../../ui/DropdownFieldSearch'
import { useSelector } from 'react-redux'
import { selectDropdownByName, selectDropdownByNameActiveOnly } from '../../../../../services'
import { Field } from 'react-final-form'

interface Props {
  onDropdownFormChange: () => void
  setCurrentVin: Dispatch<SetStateAction<string>>
  disableFields: boolean
}

export const AttributesForm = ({ onDropdownFormChange, setCurrentVin, disableFields }: Props) => {
  const carOdometerType = useSelector((state) => selectDropdownByName(state, 'CarOdometerType'))
  const carBodyType = useSelector((state) => selectDropdownByNameActiveOnly(state, 'CarBodyType'))
  const exteriorColor = useSelector((state) => selectDropdownByName(state, 'CarExteriorColor'))
  const interiorColor = useSelector((state) => selectDropdownByName(state, 'CarInteriorColor'))
  return (
    <Grid container spacing={3}>
      <Grid item xs={12} sm={6}>
        <TextField disabled={disableFields} name='stockNumber' label='Stock Number' fullWidth autoComplete='off' />
      </Grid>
      <Grid item xs={12} sm={6}>
        <TextField
          disabled={disableFields}
          onChange={(e) => {
            setCurrentVin(e.target.value)
          }}
          name='vIN'
          label='VIN'
          fullWidth
          autoComplete='off'
          required
        />
      </Grid>
      <Grid item xs={12} sm={3} md={2}>
        <TextField disabled={disableFields} name='modelYear' label='Year' fullWidth autoComplete='off' />
      </Grid>
      <Grid item xs={12} sm={3} md={4}>
        <TextField disabled={disableFields} name='make' label='Make' fullWidth autoComplete='off' required />
      </Grid>
      <Grid item xs={12} sm={6}>
        <TextField disabled={disableFields} name='model' label='Model' fullWidth autoComplete='off' />
      </Grid>
      <Grid item xs={12} sm={6}>
        <TextField disabled={disableFields} name='modelTrim' label='Trim' fullWidth autoComplete='off' />
      </Grid>
      {carBodyType && (
        <Grid item xs={12} sm={6}>
          <DropdownFieldSearch
            disabled={disableFields}
            name='bodyTypeID'
            label='Body'
            options={carBodyType}
            optionKey='ID'
            optionValue='BodyType'
            autoComplete='off'
            onChange={() => {
              onDropdownFormChange()
            }}
          />
        </Grid>
      )}

      <Grid item xs={12} sm={6}>
        <TextField disabled={disableFields} name='mileage' label='Mileage' fullWidth autoComplete='off' />
      </Grid>
      {carOdometerType && (
        <Grid item xs={12} sm={6}>
          <DropdownFieldSearch
            name='odometerTypeID'
            label='Odometer Type'
            fullWidth
            options={carOdometerType?.ReturnData}
            optionKey='ID'
            optionValue='OdometerType'
            initialValue={1}
            disableClearable
            onChange={() => {
              onDropdownFormChange()
            }}
          />
        </Grid>
      )}
      <Grid item xs={12} sm={6}>
        {/* <TextField disabled={disableFields} name='exteriorColor' label='Color' fullWidth autoComplete='off' /> */}
        <Field name='exteriorColor'>
          {(props) => (
            <Autocomplete
              freeSolo
              onChange={(e, value) => {
                props.input.onChange(value)
              }}
              value={props.input.value}
              options={exteriorColor?.ReturnData?.map((option: any) => option.Color) ?? []}
              renderInput={(params) => (
                <MuiTextField
                  onChange={(e) => {
                    props.input.onChange(e.target.value)
                  }}
                  {...params}
                  label='Color'
                />
              )}
            />
          )}
        </Field>
      </Grid>
      <Grid item xs={12} sm={6}>
        <Field name='interiorColor'>
          {(props) => (
            <Autocomplete
              freeSolo
              onChange={(e, value) => {
                props.input.onChange(value)
              }}
              value={props.input.value}
              options={exteriorColor?.ReturnData?.map((option: any) => option.Color) ?? []}
              renderInput={(params) => (
                <MuiTextField
                  onChange={(e) => {
                    props.input.onChange(e.target.value)
                  }}
                  {...params}
                  label='Interior Color'
                />
              )}
            />
          )}
        </Field>
      </Grid>
    </Grid>
  )
}

export default AttributesForm
