import React from 'react'
import { styled } from '@mui/system'
import { CardHeader, Grid, Typography } from '@mui/material'
import { generateUtilityClasses } from '@mui/material'
import { AmountField, PercentField } from '../../../../../../ui'
import DropdownFieldSearch from '../../../../../../ui/DropdownFieldSearch'
import { useFormState } from 'react-final-form'
import { useSelector } from 'react-redux'
import { selectDropdownByName } from '../../../../../../services'

const classes = generateUtilityClasses('Grid', ['root', 'row'])

const StyledGrid = styled(Grid)(({ theme }) => ({
  [`&.${classes.root}`]: {},
  [`& .${classes.row}`]: {
    margin: theme.spacing(1, 0, 2, 0),
  },
}))

interface Props {
  onDropdownFormChange: () => void
}

export const FeesForm = ({ onDropdownFormChange }: Props) => {
  const { values } = useFormState()
  const discountEarningMethod = useSelector((state) => selectDropdownByName(state, 'DiscountEarningMethod'))

  return (
    <>
      <CardHeader title='Fees' />
      <Grid container spacing={2} px={3} pt={3}>
        <Grid item xs={12} sm={6}>
          <DropdownFieldSearch
            name='DiscountType'
            label='Discount Type'
            options={[
              { key: 'rate', value: 'Rate' },
              { key: 'amount', value: 'Amount' },
            ]}
            onChange={() => {
              onDropdownFormChange()
            }}
            initialValue='rate'
          />
        </Grid>
        {values.DiscountType === 'rate' && (
          <Grid item xs={12} sm={6}>
            <PercentField variant='standard' name='DiscountRate' label='Discount Rate (%)' fullWidth />
          </Grid>
        )}
        {values.DiscountType === 'amount' && (
          <>
            <Grid item xs={12} sm={6}>
              <AmountField variant='standard' name='DiscountAmount' label='Discount Amount' fullWidth />
            </Grid>
            <Grid item xs={12} sm={6}>
              <PercentField variant='standard' disabled={true} name='DiscountRate' label='Discount Rate' fullWidth />
            </Grid>
          </>
        )}
        {discountEarningMethod && (
          <Grid item xs={12} sm={6}>
            <DropdownFieldSearch
              name='DiscountEarningMethod'
              label='Discount Earning Method'
              fullWidth
              options={discountEarningMethod?.ReturnData}
              optionKey='DiscountEarningMethod'
              optionValue='Description'
              onChange={() => {
                onDropdownFormChange()
              }}
            />
          </Grid>
        )}

        <Grid item xs={12} sm={6}>
          <PercentField variant='standard' name='ServicingRate' label='Servicing Rate (%)' fullWidth />
        </Grid>
      </Grid>
    </>
  )
}

export default FeesForm
