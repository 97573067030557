import { CardHeader, Grid, Typography, generateUtilityClasses } from '@mui/material'
import { styled } from '@mui/system'
import React, { Dispatch, SetStateAction } from 'react'
import DropdownFieldSearch from '../../../../ui/DropdownFieldSearch'
import { AmountField, DateField, DropdownCheckboxes, TextField } from '../../../../ui'
import { useSelector } from 'react-redux'
import { getBranch } from '../../../../redux/slices/userDropdownSlice'
import { useFormState } from 'react-final-form'
import { useGetAccountStatusQuery } from '../../../../services'
import { UspListAccountStatusResult } from '../../../../models'

const classes = generateUtilityClasses('Grid', ['root', 'row', 'optionalText'])

const StyledGrid = styled(Grid)(({ theme }) => ({
  [`&.${classes.root}`]: {
    minHeight: '700px',
  },
  [`& .${classes.row}`]: {
    margin: theme.spacing(1, 0, 2, 0),
  },
}))

interface Props {
  setIsPersonalWorklist: Dispatch<SetStateAction<boolean>>
  setLoginWorklist: Dispatch<SetStateAction<boolean>>
}

const CriteriaCard = ({ setIsPersonalWorklist, setLoginWorklist }: Props) => {
  const { values } = useFormState()
  const branchData = useSelector(getBranch)
  const { data } = useGetAccountStatusQuery({})

  return (
    <>
      <CardHeader title='Criteria' />
      <Grid container spacing={2} padding={3}>
        <Grid item xs={12} sm={6}>
          <DropdownFieldSearch
            name='FilterBy'
            label='Filter By'
            options={[
              { key: 'PastDue', value: 'Days Past Due' },
              { key: 'DueDate', value: 'Due Date' },
              { key: 'NextScheduledDueDate', value: 'Next Scheduled Due Date' },
            ]}
            autoComplete='off'
          />
        </Grid>
        {values.FilterBy !== 'PastDue' ? (
          <Grid item xs={12} sm={3}>
            <DateField name='startDate' label='Start Date' fullWidth />
          </Grid>
        ) : (
          <Grid item xs={12} sm={3}>
            <TextField name='StartingDaysPastDue' label='Starting Days Past Due' fullWidth />
          </Grid>
        )}
        {values.FilterBy !== 'PastDue' ? (
          <Grid item xs={12} sm={3}>
            <DateField name='endDate' label='End Date' fullWidth />
          </Grid>
        ) : (
          <Grid item xs={12} sm={3}>
            <TextField name='EndingDaysPastDue' label='Ending Days Past Due' fullWidth />
          </Grid>
        )}
        <Grid item xs={12} sm={6}>
          <DropdownCheckboxes
            name='branch'
            label='Branch'
            labelId='BranchId'
            fullWidth
            options={branchData}
            optionKey='BranchID'
            optionValue='Branch'
            variant='outlined'
          />
          {/* <DropdownFieldSearch name='branch' label='Branch' options={branchData} optionKey='BranchID' optionValue='Branch' autoComplete='off' /> */}
        </Grid>

        <Grid item xs={12} sm={6}>
          <DropdownCheckboxes
            name='AccountStatus'
            label='Account Status'
            options={data as UspListAccountStatusResult[]}
            autoComplete='off'
            optionKey='AccountStatus'
            optionValue='AccountStatus'
            fullWidth
            variant='outlined'
            defaultValue='Active'
          />
        </Grid>
        <Grid container item xs={12} sm={6} spacing={2}>
          <Grid item xs={12} sm={6}>
            <DropdownFieldSearch
              name='PastDue'
              label='Past Due'
              options={[
                { key: 'Greater Than', value: 'Greater Than' },
                { key: 'Less Than', value: 'Less Than' },
                { key: 'Greater Than', value: 'Greater Than' },
              ]}
              autoComplete='off'
            />
          </Grid>
          <Grid item xs={12} sm={6}>
            <AmountField variant='standard' name='Amount' label='Amount' fullWidth />
          </Grid>
        </Grid>
        <Grid item xs={12} sm={values.IsPersonalWorklist ? 6 : 3}>
          <DropdownFieldSearch
            onChange={(event) => setIsPersonalWorklist(event)}
            name='IsPersonalWorklist'
            label='Is Personal Worklist?'
            options={[
              { key: true, value: 'Yes' },
              { key: false, value: 'No' },
            ]}
            autoComplete='off'
          />
        </Grid>
        {!values.IsPersonalWorklist && (
          <Grid item xs={12} sm={3}>
            <TextField name='WorklistName' label='Worklist Name' fullWidth />
          </Grid>
        )}
        {!values.IsPersonalWorklist && (
          <Grid item xs={12} sm={6}>
            <DropdownFieldSearch
              onChange={(event) => setLoginWorklist(event)}
              name='LoginWorklist'
              label='Do you want to log in to this Worklist?'
              options={[
                { key: true, value: 'Yes' },
                { key: false, value: 'No' },
              ]}
              autoComplete='off'
            />
          </Grid>
        )}

        <Grid item xs={12} sm={6}>
          <DropdownFieldSearch
            name='OrderBy'
            label='Order By'
            options={[
              { key: 'DPD', value: 'Days Past Due' },
              { key: 'APD', value: 'Amount Past Due' },
              { key: 'Name', value: 'Name' },
            ]}
            autoComplete='off'
          />
        </Grid>
      </Grid>
    </>
  )
}

export default CriteriaCard
