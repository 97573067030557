import React, { useEffect } from 'react'
import { styled } from '@mui/material/styles'
import Grid from '@mui/material/Grid'
import { Card, generateUtilityClasses } from '@mui/material'
import Paper from '@mui/material/Paper'
import { MonthlyIncomeForm, MonthlyExpensesForm, DTIForm, HistoryForm } from '../../components'
import { useFormState } from 'react-final-form'
import { checkIfObjectContainKeysWithPrefix } from '../../../../../utils/general'

const classes = generateUtilityClasses('Grid', ['root', 'pageBody', 'fieldsWrap', 'formItem'])

const StyledGrid = styled(Grid)(({ theme }) => ({
  [`&.${classes.root}`]: {
    display: 'flex',
    flexDirection: 'column',
    height: '100%',
    width: '100%',
  },
  [`& .${classes.pageBody}`]: {
    padding: theme.spacing(2, 2, 0, 2),
  },
  [`& .${classes.formItem}`]: {
    minHeight: '340px',
    padding: theme.spacing(2, 3, 1, 3),
    margin: theme.spacing(0),
    borderRadius: 0,
  },
}))

interface Props {
  setErrorsInTabs: (value: any) => void

  onDropdownFormChange: () => void
  disableFields: boolean
}

export const Income = ({ setErrorsInTabs, onDropdownFormChange, disableFields }: Props) => {
  const { errors, submitFailed } = useFormState()

  useEffect(() => {
    setErrorsInTabs((prev: any) => ({ ...prev, income: submitFailed && checkIfObjectContainKeysWithPrefix(errors, 'Income') }))
  }, [errors, submitFailed])

  return (
    <Grid container spacing={2}>
      <Grid container item xs={12} sm={6}>
        <Card>
          <Grid item xs={12} sm={12}>
            <MonthlyIncomeForm disableFields={disableFields} />
          </Grid>
          <Grid item xs={12} sm={12}>
            <MonthlyExpensesForm disableFields={disableFields} />
          </Grid>
        </Card>
      </Grid>
      <Grid container item xs={12} sm={6} spacing={2} alignContent={'flex-start'}>
        <Grid item xs={12} sm={12}>
          <HistoryForm disableFields={disableFields} onDropdownFormChange={onDropdownFormChange} />
        </Grid>
        <Grid item xs={12} sm={12}>
          <DTIForm />
        </Grid>
      </Grid>
    </Grid>
  )
}

export default Income
