import React from 'react'
import { styled } from '@mui/system'
import { CardHeader, Grid, Typography } from '@mui/material'
import { TextField, DateField } from '../../../../../ui'
import { generateUtilityClasses } from '@mui/material'
import AmountField from '../../../../../ui/AmountField'
import { useFormState } from 'react-final-form'
import DropdownFieldSearch from '../../../../../ui/DropdownFieldSearch'

const classes = generateUtilityClasses('Grid', ['root', 'row', 'optionalText'])

const StyledGrid = styled(Grid)(({ theme }) => ({
  [`&.${classes.root}`]: {},
  [`& .${classes.row}`]: {
    margin: theme.spacing(1, 0, 2, 0),
  },
  [`& .${classes.optionalText}`]: {
    fontFamily: 'Open Sans, sans-serif',
    fontWeight: 400,
    Size: '0.8750em',
    fontStyle: 'italic',
    lineHeight: '1.1875em',
    marginLeft: theme.spacing(1),
  },
}))

interface Props {
  onDropdownFormChange: () => void
  disableFields: boolean
}

export const PreviousEmployerDateForm = ({ onDropdownFormChange, disableFields }: Props) => {
  const { values } = useFormState()
  return (
    <>
      <Grid container spacing={2} pl={3} pr={3} pb={3}>
        <Grid item xs={12} sm={3}>
          <DateField
            disabled={disableFields}
            onChange={() => {
              onDropdownFormChange()
            }}
            name='EmploymentPreviousDateStarted'
            label='Date Started'
            disableFuture
            fullWidth
          />
        </Grid>
        <Grid item xs={12} sm={3}>
          <DateField
            disabled={disableFields}
            onChange={() => {
              onDropdownFormChange()
            }}
            name='EmploymentPreviousDateEnded'
            label='Date Ended'
            fullWidth
          />
        </Grid>
        <Grid item xs={12} sm={6}>
          <DropdownFieldSearch
            disabled={disableFields}
            name='EmploymentPreviousIsSalaried'
            label='Is Salaried?'
            options={[
              { key: true, value: 'Yes' },
              { key: false, value: 'No' },
            ]}
            onChange={() => {
              onDropdownFormChange()
            }}
          />
        </Grid>
        {values?.EmploymentPreviousIsSalaried && (
          <Grid item xs={12} sm={3}>
            <AmountField variant='standard' disabled={disableFields} name='EmploymentPreviousYearlySalary' label='Yearly Salary' fullWidth />
          </Grid>
        )}
        {!values?.EmploymentPreviousIsSalaried && (
          <Grid item xs={12} sm={3}>
            <AmountField variant='standard' disabled={disableFields} name='EmploymentPreviousHourlyWage' label='Hourly Wage' fullWidth />
          </Grid>
        )}
        {!values.EmploymentPreviousIsSalaried && (
          <Grid item xs={12} sm={3}>
            <TextField
              disabled={disableFields}
              type='number'
              name='EmploymentPreviousHoursPerWeek'
              label='Hours Per Week'
              fullWidth
              autoComplete='off'
            />
          </Grid>
        )}
        <Grid item xs={12} sm={6}>
          <DropdownFieldSearch
            disabled={disableFields}
            name='EmploymentPreviousPaySchedule'
            label='Pay Schedule'
            options={[
              { key: 'W', value: 'Weekly' },
              { key: 'B', value: 'Bi-Weekly' },
              { key: 'S', value: 'Semi-Monthly' },
            ]}
            onChange={() => {
              onDropdownFormChange()
            }}
          />
        </Grid>
        <Grid item xs={12} sm={6}>
          <TextField disabled={disableFields} minRows={5} multiline name='EmploymentPreviousComment' label='Comment' fullWidth autoComplete='off' />
        </Grid>
      </Grid>
    </>
  )
}

export default PreviousEmployerDateForm
