import React, { Dispatch, SetStateAction, useEffect } from 'react'
import { styled } from '@mui/system'
import { Dialog, DialogActions, DialogContent, DialogTitle, generateUtilityClasses } from '@mui/material'
import { Form, useForm } from 'react-final-form'
import Box from '@mui/material/Box'
import Grid from '@mui/material/Grid'
import Typography from '@mui/material/Typography'
import Modal from '@mui/material/Modal'
import { TextField } from '../../../../../ui'
import { getCurrentDate } from '../../../../../utils/general'
import Button from '@mui/material/Button'
import { useAddCarNoteMutation } from '../../../../../services'
import { useLocation, useParams } from 'react-router-dom'
import { enqueueNotification } from '../../../../../redux/slices/notificationsSlice'
import { useDispatch } from 'react-redux'
import { TNoteRequest } from '../../InventoryEditNotes'
import validateFormValues from '../../../../../utils/validateForm'
import { addInventoryNoteValidation } from '../../../../../validation/addInventoryNoteValidation'

interface Props {
  open: boolean
  onClose: () => void
  getCarNote: (e: any) => void
  noteRequestData: TNoteRequest
  setFormChanged: Dispatch<SetStateAction<boolean>>
  formChanged: boolean
}

const classes = generateUtilityClasses('Box', ['root', 'row', 'buttonWrap', 'saveBtn'])

const StyledBox = styled(Box)(({ theme }) => ({
  [`&.${classes.root}`]: {
    position: 'absolute' as 'absolute',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    width: '390px',
    height: '400px',
    backgroundColor: theme.palette.background.main,
    boxShadow: 24,
    padding: theme.spacing(3),
    display: 'flex',
    flexDirection: 'column',
  },
  [`& .${classes.row}`]: {
    margin: theme.spacing(1, 0, 2, 0),
  },
  [`& .${classes.buttonWrap}`]: {
    display: 'flex',
    justifyContent: 'flex-start',
    alignSelf: 'flex-end',
    flexDirection: 'row-reverse',
    padding: theme.spacing(6, 0, 2, 0),
    marginTop: 'auto',
  },
  [`& .${classes.saveBtn}`]: {
    marginLeft: theme.spacing(2),
  },
}))

export const AddFormModal = ({ formChanged, open, onClose, getCarNote, noteRequestData, setFormChanged }: Props) => {
  const dispatch = useDispatch()
  const { carID } = useParams()
  const location = useLocation()
  const [addCarNote, { data: carNoteData, isSuccess: carNoteIsSuccess }] = useAddCarNoteMutation()
  const { submit } = useForm()

  const handleSubmit = (values: any) => {
    setFormChanged(false)
    addCarNote({
      carId: carID as string,
      uspCarNoteInsert: {
        note: values?.note,
        isSystemGenerated: false,
        originalNoteID: null,
        isCancelled: false,
      },
    })
  }

  useEffect(() => {
    if (carNoteIsSuccess) {
      dispatch(enqueueNotification({ message: carNoteData?.Response || 'Success' }))
      submit()
      // if (location.pathname.includes(`/inventory/edit/${carID}/notes`)) {
      getCarNote({
        carId: carID as string,
        uspCarNoteView: {
          isIncludeAllHistoryByCar: noteRequestData?.isIncludeAllHistoryByCar,
          isIncludeCancelledNotes: noteRequestData?.isIncludeCancelledNotes,
        },
      })
      // }
      onClose()
    }
  }, [carNoteIsSuccess])

  return (
    <Dialog open={open} onClose={onClose} PaperProps={{ sx: { minWidth: { xs: '90%', sm: '400px' } } }}>
      <DialogTitle>Add Note</DialogTitle>
      <Form onSubmit={handleSubmit} validate={validateFormValues(addInventoryNoteValidation)}>
        {({ handleSubmit }: any) => (
          <form onSubmit={handleSubmit} onChange={() => setFormChanged(true)} style={{ width: '100%' }}>
            <DialogContent>
              <Grid container py={1}>
                <Grid item xs={12}>
                  <TextField name='note' label='Note' multiline fullWidth />
                </Grid>
              </Grid>
            </DialogContent>
            <DialogActions>
              <Button variant='contained' color='error' onClick={onClose}>
                Cancel
              </Button>
              <Button disabled={!formChanged} type='submit' variant='contained'>
                Save
              </Button>
            </DialogActions>
          </form>
        )}
      </Form>
    </Dialog>
  )
}

export default AddFormModal
