import React, { Dispatch, SetStateAction, useEffect, useState } from 'react'
import {
  Box,
  Grid,
  IconButton,
  MenuItem,
  Paper,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  TableSortLabel,
  Tooltip,
  Typography,
  generateUtilityClasses,
} from '@mui/material'
import { styled } from '@mui/system'
import EditIcon from '@mui/icons-material/Edit'
import DeleteIcon from '@mui/icons-material/Delete'
import { storageGet, storageSet } from '../../../../utils/storage'
import { useDispatch } from 'react-redux'
import { visuallyHidden } from '@mui/utils'
import { getComparator, stableSort } from '../../../../utils/sorting'
import ConfirmDialog from '../../../../components/ConfirmDialog'
import { UspConfigurationCreditAppExpenseViewResult } from '../../../../models'
import { useDeleteCreditAppExpenseMutation } from '../../../../services'
import { enqueueNotification } from '../../../../redux/slices/notificationsSlice'
import { CustomCircular } from '../../../../ui'
import { usePopover } from '../../../../components/custom-popover'
import CustomPopover from '../../../../components/custom-popover/custom-popover'
import Iconify from '../../../../components/iconify'
import Label from '../../../../components/label'

const classes = generateUtilityClasses('Grid', ['root', 'activeItem', 'loading', 'tableHead', 'tableCellName', 'inactiveItem', 'lastColumn'])

const StyledGrid = styled(Grid)(({ theme }) => ({
  [`&.${classes.root}`]: {
    display: 'flex',
    justifyContent: 'end',
    alignItems: 'center',
    margin: theme.spacing(3, 3, 3, 3),
  },
  [`& .${classes.activeItem}`]: {
    padding: theme.spacing(0.5, 0, 0, 0),
    width: '84px',
    height: '22px',
    fontSize: '0.8em',
    fontWeight: 500,
    color: '#FFFFFF',
    textAlign: 'center',
    backgroundColor: theme.palette.success.main,
    borderRadius: '4px',
  },
  [`& .${classes.inactiveItem}`]: {
    padding: theme.spacing(0.5, 0, 0, 0),
    width: '84px',
    height: '22px',
    fontSize: '0.8em',
    fontWeight: 500,
    color: '#FFFFFF',
    textAlign: 'center',
    backgroundColor: theme.palette.error.light,
    borderRadius: '4px',
  },
  [`& .${classes.lastColumn}`]: {
    fontWeight: 600,
    background: theme.palette?.background?.main,
    width: '80px',
  },
  [`& .${classes.tableHead}`]: {
    fontWeight: 600,
    background: theme.palette?.background?.main,
  },
  [`& .${classes.tableCellName}`]: {
    cursor: 'pointer',
    color: theme.palette.primary.main,
  },
  [`& .${classes.loading}`]: {
    verticalAlign: 'top',
  },
}))

interface Props {
  data: UspConfigurationCreditAppExpenseViewResult[]
  loading: boolean
  setData: Dispatch<SetStateAction<UspConfigurationCreditAppExpenseViewResult[]>>
  setOpenAddEditModal: Dispatch<SetStateAction<boolean>>
  setType: Dispatch<SetStateAction<string>>
  setRowData: Dispatch<SetStateAction<UspConfigurationCreditAppExpenseViewResult>>
}

const LeadsApplicantExpensesTable = ({ data, loading, setData, setOpenAddEditModal, setRowData, setType }: Props) => {
  const [deleteCreditAppExpense, { isSuccess: deleteIsSuccess }] = useDeleteCreditAppExpenseMutation()
  const initialOrder = (storageGet('credit_card_expenses_order') || 'asc') as 'asc' | 'desc'
  const initialOrderBy = (storageGet('credit_card_expenses_order_by') || 'ExpenseLabel') as keyof UspConfigurationCreditAppExpenseViewResult
  const [order, setOrder] = useState<'asc' | 'desc'>(initialOrder)
  const [orderBy, setOrderBy] = useState<keyof UspConfigurationCreditAppExpenseViewResult>(initialOrderBy)
  const [deleteConfirmDialog, setDeleteConfirmDialog] = useState<{ creditAppExpenseId: string } | undefined>(undefined)
  const dispatch = useDispatch()
  const [selected, setSelected] = useState<any>(null)
  const popover = usePopover()

  const onRequestSort = (event: React.MouseEvent<unknown>, property: keyof UspConfigurationCreditAppExpenseViewResult) => {
    const isAsc = orderBy === property && order === 'asc'
    setOrder(isAsc ? 'desc' : 'asc')
    setOrderBy(property)
    storageSet('credit_card_expenses_order', isAsc ? 'desc' : 'asc')
    storageSet('credit_card_expenses_order_by', property)
  }

  const createSortHandler = (property: keyof UspConfigurationCreditAppExpenseViewResult) => (event: React.MouseEvent<unknown>) => {
    onRequestSort(event, property)
  }

  const handleDelete = async (): Promise<void> => {
    if (deleteConfirmDialog?.creditAppExpenseId) {
      await deleteCreditAppExpense(deleteConfirmDialog)
    }
    setDeleteConfirmDialog(undefined)
  }

  const handleOpen = () => {
    setType('edit')
    setOpenAddEditModal(true)
  }

  useEffect(() => {
    if (deleteConfirmDialog?.creditAppExpenseId) {
      if (deleteIsSuccess) {
        let updatedData: UspConfigurationCreditAppExpenseViewResult[]
        updatedData = data.map((item) => (item.ID?.toString() === deleteConfirmDialog.creditAppExpenseId ? { ...item, IsActive: false } : item))
        setData(updatedData)
        dispatch(enqueueNotification({ message: 'Success' }))
      }
    }
  }, [deleteIsSuccess])

  return (
    <>
      <TableContainer sx={{ overflow: 'auto', maxHeight: 'calc(100vh - 118px)' }}>
        <Table sx={{ minWidth: 650 }} aria-label='simple table' stickyHeader>
          <TableHead>
            <TableRow>
              <TableCell className={classes.tableHead} sortDirection={orderBy === 'ExpenseLabel' ? order : false}>
                <TableSortLabel
                  active={orderBy === 'ExpenseLabel'}
                  direction={orderBy === 'ExpenseLabel' ? order : 'asc'}
                  onClick={createSortHandler('ExpenseLabel')}
                >
                  Name
                  {orderBy === 'ExpenseLabel' ? (
                    <Box component='span' sx={visuallyHidden}>
                      {order === 'desc' ? 'sorted descending' : 'sorted ascending'}
                    </Box>
                  ) : null}
                </TableSortLabel>
              </TableCell>
              <TableCell className={classes.lastColumn} sortDirection={orderBy === 'IsActive' ? order : false}>
                <TableSortLabel
                  active={orderBy === 'IsActive'}
                  direction={orderBy === 'IsActive' ? order : 'asc'}
                  onClick={createSortHandler('IsActive')}
                >
                  Status
                  {orderBy === 'IsActive' ? (
                    <Box component='span' sx={visuallyHidden}>
                      {order === 'desc' ? 'sorted descending' : 'sorted ascending'}
                    </Box>
                  ) : null}
                </TableSortLabel>
              </TableCell>
              <TableCell className={classes.lastColumn} align={'right'}>
                Action
              </TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {stableSort(getComparator(order, orderBy), data as any)?.map((item) => (
              <TableRow key={item.ID} sx={{ '&:last-child td, &:last-child th': { border: 0 } }}>
                <TableCell
                  className={classes.tableCellName}
                  onClick={() => {
                    handleOpen()
                    setRowData(item as any)
                  }}
                >
                  {item.ExpenseLabel}
                </TableCell>
                <TableCell>
                  <Label color={item.IsActive ? 'success' : 'error'}>{item.IsActive ? 'Active' : 'Inactive'}</Label>
                </TableCell>
                <TableCell align={'right'}>
                  <IconButton
                    onClick={(event: React.MouseEvent<HTMLElement>) => {
                      setSelected(item)
                      popover.onOpen(event)
                    }}
                  >
                    <Iconify icon='eva:more-vertical-fill' />
                  </IconButton>
                </TableCell>
              </TableRow>
            ))}
            {data?.length === 0 && (
              <TableRow>
                <TableCell colSpan={10} align='center'>
                  No results
                </TableCell>
              </TableRow>
            )}
          </TableBody>
        </Table>
      </TableContainer>
      <CustomCircular open={loading} />
      <ConfirmDialog
        open={!!deleteConfirmDialog}
        onOk={handleDelete}
        handleClose={() => setDeleteConfirmDialog(undefined)}
        notification={'Are you sure you wish to delete?'}
      />

      <CustomPopover open={popover.open} onClose={popover.onClose} arrow='top-right' sx={{ width: 140 }}>
        <MenuItem
          onClick={(e) => {
            handleOpen()
            setRowData(selected as any)
            popover.onClose()
          }}
        >
          <Iconify icon='solar:pen-bold' />
          Edit
        </MenuItem>
        <MenuItem
          disabled={selected?.IsActive ? false : true}
          onClick={() => {
            setDeleteConfirmDialog({ creditAppExpenseId: selected.ID?.toString() as string })
            popover.onClose()
          }}
          sx={{ color: 'error.main' }}
        >
          <Iconify icon='solar:trash-bin-trash-bold' />
          Delete
        </MenuItem>
      </CustomPopover>
    </>
  )
}

export default LeadsApplicantExpensesTable
