import { Button, Card, CardHeader, Dialog, DialogActions, DialogContent, DialogTitle, Grid } from '@mui/material'
import React, { Dispatch, SetStateAction, useEffect } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import {
  selectDropdownByNameActiveOnly,
  selectDropdownByNameIsCollectorOnly,
  selectDropdownByNameIsManagerOnly,
  selectDropdownByNameIsSalespersonOnly,
  usePurchaseAccountsMutation,
} from '../../../../../services'
import { AmountField, DropdownCheckboxes, DropdownField, TextField } from '../../../../../ui'
import { Form, useForm, useFormState } from 'react-final-form'
import DropdownFieldSearch from '../../../../../ui/DropdownFieldSearch'
import { enqueueNotification } from '../../../../../redux/slices/notificationsSlice'
import { getBranch } from '../../../../../redux/slices/userDropdownSlice'

function Settings({}: {}) {
  const salesPerson = useSelector((state) => selectDropdownByNameIsSalespersonOnly()(state, 'UserAll'))
  const manager = useSelector((state) => selectDropdownByNameIsManagerOnly()(state, 'UserAll'))
  const collector = useSelector((state) => selectDropdownByNameIsCollectorOnly()(state, 'UserAll'))
  const advertisement = useSelector((state) => selectDropdownByNameActiveOnly(state, 'Advertisement'))
  const branch = useSelector(getBranch)
  const [purchaseAccounts, { data: purchaseAccountsData, isLoading: purchaseAccountsIsLoading, isSuccess, error }] = usePurchaseAccountsMutation()
  const { change } = useForm()
  const { values } = useFormState()
  return (
    <Card>
      <CardHeader title='Settings' />
      <Grid container p={3} spacing={2}>
        {branch && (
          <Grid item xs={12} sm={6}>
            <DropdownFieldSearch
              variant='outlined'
              name='branch'
              label='Branch'
              options={branch}
              optionKey={'BranchID'}
              optionValue={'Branch'}
              fullWidth
            />
          </Grid>
        )}

        <Grid item xs={12} sm={6}>
          {collector && <DropdownFieldSearch name='collector' label='Collector' options={collector} optionKey={'UserID'} optionValue={'Name'} />}
        </Grid>

        <Grid item xs={12} sm={6}>
          {manager && <DropdownFieldSearch name='manager' label='F&I Manager' options={manager} optionKey={'UserID'} optionValue={'Name'} />}{' '}
        </Grid>

        <Grid item xs={12} sm={6}>
          {salesPerson && (
            <DropdownFieldSearch name='salesperson' label='Salesperson' options={salesPerson} optionKey={'UserID'} optionValue={'Name'} />
          )}
        </Grid>
        <Grid item xs={12} sm={6}>
          {advertisement && (
            <DropdownFieldSearch name='leadSource' label='Lead Source' options={advertisement} optionKey={'ID'} optionValue={'Advertisement'} />
          )}
        </Grid>
      </Grid>
    </Card>
  )
}

export default Settings
