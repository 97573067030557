import React from 'react'
import { useSortable } from '@dnd-kit/sortable'
import { CSS } from '@dnd-kit/utilities'
import { UspCarImageViewResult } from '../../../models'
import { Divider, Stack, Typography } from '@mui/material'
import Iconify from '../../../components/iconify/iconify'

export function SortableItem({ image, id }: { image: UspCarImageViewResult; id: number }) {
  const { attributes, listeners, setNodeRef, transform, transition } = useSortable({
    id: id,

    animateLayoutChanges: () => false,
  })

  const style = {
    transform: CSS.Transform.toString(transform),
    transition,
  }

  return (
    <Stack
      ref={setNodeRef}
      style={style}
      {...attributes}
      {...listeners}
      //   direction={'row'}
      paddingY={2}
      alignItems={'center'}
      sx={{ cursor: 'pointer' }}
    >
      {/* <Iconify icon={'qlementine-icons:drag-16'} {...attributes} {...listeners} style={{ cursor: 'pointer' }} /> */}
      <img src={image.ImageLink} style={{ width: '60px', height: '60px', objectFit: 'contain' }} />
      {/* <Stack direction={'column'}>
        <Typography variant='caption'>{image.CarImageID}</Typography>
      </Stack> */}
    </Stack>
  )
}
