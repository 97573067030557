import React, { useEffect } from 'react'
import { styled } from '@mui/system'
import { generateUtilityClasses, Grid, Typography } from '@mui/material'
import { Form } from 'react-final-form'
import { TextField } from '../../../../../ui'
import { useEditCarNoteMutation } from '../../../../../services'
import { useParams } from 'react-router-dom'
import { useDispatch } from 'react-redux'
import { enqueueNotification } from '../../../../../redux/slices/notificationsSlice'

interface Props {
  handleFormChange: () => void
}

export const NoteCommentsHeader = ({ handleFormChange }: Props) => {
  const { carID } = useParams()
  const [editCarNote, { data, isSuccess }] = useEditCarNoteMutation()

  return (
    <Grid container spacing={{ xs: 2, md: 3 }}>
      <Grid item xs={12}>
        <Typography variant='h6' gutterBottom>
          Comments
        </Typography>
      </Grid>
      <Grid item xs={12} sm={6}>
        <TextField multiline name='comment' label='General' onChange={() => handleFormChange()} fullWidth autoComplete='off' />
      </Grid>
      <Grid item xs={12} sm={6}>
        <TextField multiline name='serviceNote' label='Service' onChange={() => handleFormChange()} fullWidth autoComplete='off' />
      </Grid>
    </Grid>
  )
}

export default NoteCommentsHeader
