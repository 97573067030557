import React from 'react'
import { styled } from '@mui/system'
import { CardHeader, Grid, Typography } from '@mui/material'
import { AmountField, PercentField } from '../../../../../ui'
import { generateUtilityClasses } from '@mui/material'
import { useFormState } from 'react-final-form'
import DropdownFieldSearch from '../../../../../ui/DropdownFieldSearch'

const classes = generateUtilityClasses('Grid', ['root', 'row', 'optionalText'])

const StyledGrid = styled(Grid)(({ theme }) => ({
  [`&.${classes.root}`]: {},
  [`& .${classes.row}`]: {
    margin: theme.spacing(1, 0, 2, 0),
  },
  [`& .${classes.optionalText}`]: {
    fontFamily: 'Open Sans, sans-serif',
    fontWeight: 400,
    Size: '0.8750em',
    fontStyle: 'italic',
    lineHeight: '1.1875em',
    marginLeft: theme.spacing(1),
  },
}))

const FeesForm = () => {
  const { values } = useFormState()

  return (
    <>
      <CardHeader title='Fees' />
      <Grid container spacing={2} px={3} pt={3}>
        <Grid item xs={12} sm={6}>
          <DropdownFieldSearch
            name='DiscountType'
            label='Discount Type'
            options={[
              { key: 'rate', value: 'Rate' },
              { key: 'amount', value: 'Amount' },
            ]}
            initialValue='rate'
          />
        </Grid>
        {values.DiscountType === 'rate' && (
          <Grid item xs={12} sm={6}>
            <PercentField variant='standard' name='DiscountRate' label='Discount Rate (%)' fullWidth />
          </Grid>
        )}
        {values.DiscountType === 'amount' && (
          <Grid item xs={12} sm={6}>
            <AmountField variant='standard' name='DiscountAmount' label='Discount Amount' fullWidth />
          </Grid>
        )}

        <Grid item xs={12} sm={6}>
          <DropdownFieldSearch
            name='DiscountPickups'
            label='Discount Pickups?'
            options={[
              { key: true, value: 'Yes' },
              { key: false, value: 'No' },
            ]}
          />
        </Grid>
        <Grid item xs={12} sm={6}>
          <PercentField variant='standard' name='SpreadRate' label='Spread Rate (%)' fullWidth />
        </Grid>
      </Grid>
    </>
  )
}

export default FeesForm
