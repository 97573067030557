import React, { useCallback, useEffect } from 'react'
import { Button, Grid, Typography, generateUtilityClasses } from '@mui/material'
import { styled } from '@mui/system'
import { TextField } from '../../../../ui'
import { useFormState } from 'react-final-form'
import { debounce } from 'lodash'
import DropdownFieldSearch from '../../../../ui/DropdownFieldSearch'
import { useSelector } from 'react-redux'
import { getStates } from '../../../../redux/slices/userDropdownSlice'
import AddIcon from '@mui/icons-material/Add'

const classes = generateUtilityClasses('Grid', ['root', 'titleWrap', 'fieldWrap', 'checkboxWrap', 'searchBtn'])

const StyledGrid = styled(Grid)(({ theme }) => ({
  [`&.${classes.root}`]: {
    padding: theme.spacing(1),
    margin: 0,
    display: 'flex',
    backgroundColor: theme.palette.background.main,
    width: '100%',
    maxWidth: 'unset',
    position: 'sticky',
    top: 0,
    zIndex: 10,
  },
  [`& .${classes.titleWrap}`]: {
    margin: theme.spacing(2, 2, 1, 2),
  },
  [`& .${classes.fieldWrap}`]: {
    width: theme.spacing(15),
    margin: theme.spacing(0, 4, 0, 0),
  },

  [`& .${classes.searchBtn}`]: {
    width: '120px',
    margin: theme.spacing(0, 2, 0, 'auto'),
  },
}))
interface Props {
  title: string
  handleOpenModal?: () => void
  submit: (...args: any) => any
}
const Header = ({ title, submit, handleOpenModal }: Props) => {
  const states = useSelector(getStates)
  const { values } = useFormState()

  const updateWithDebounce = useCallback(
    debounce(() => submit(values), 500),
    [],
  )

  useEffect(() => {
    // search with debounce
    if (values?.City || values?.City !== '' || values?.State || values?.State !== '') {
      updateWithDebounce()
    }
  }, [values?.City, values?.State])

  return (
    <>
      <Grid container spacing={2} px={3} pt={3}>
        <Grid item xs={12} sm={4}>
          <DropdownFieldSearch name='State' label='State' options={states} optionKey='State' optionValue='State' autoComplete='off' />
        </Grid>
        <Grid item xs={12} sm={4}>
          <TextField name='City' fullWidth label='City' />
        </Grid>
      </Grid>
    </>
  )
}

export default Header
