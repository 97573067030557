import React, { useEffect, useState } from 'react'
import { AuthTemplate } from '../../components/AuthTemplate'
import selectSessionBg from '../../assets/selectSession-bg.png'
import { Button, Typography, CircularProgress, Grid, useTheme } from '@mui/material'
import { Form } from 'react-final-form'
import { useSelector, useDispatch } from 'react-redux'
import { getClients, setSession, setBranchData } from '../../redux/slices/authSlice'
import { sessionLabels, sessionValidation } from '../../validation'
import { SelectField, CustomCircular } from '../../ui'
import validateForm from '../../utils/validateForm'
import { useUserLoggedInMutation, useLazyCompanyTypesQuery } from '../../services'
import { SessionFormFields, ClientBranchData } from '../../models'
import { appRoute } from '../../constants'
import { useNavigate } from 'react-router-dom'

export const SelectSession = () => {
  const theme = useTheme()
  const navigate = useNavigate()
  const dispatch = useDispatch()
  const clients = useSelector(getClients)
  const [formValues, setFormValues] = useState({})
  const [branches, setBranches] = useState<ClientBranchData[]>([])
  const [companyTypes, { data: companyTypesData, isLoading: companyTypeIsLoading, isSuccess: companyTypeIsSuccess }] = useLazyCompanyTypesQuery()
  const [userLogin, { isSuccess: loginDataIsSuccess }] = useUserLoggedInMutation()

  const handleChangeClient = (value: string) => {
    setFormValues({ client: value })
    companyTypes({ clientUid: value })
  }

  const handleChangeCompanyTypes = (value?: string) => {
    const currentCompany = companyTypesData && companyTypesData?.find(({ CompanyType }) => CompanyType === value)
    const branchDataLength = currentCompany?.BranchData?.length || 0
    const BranchData = currentCompany?.BranchData || []
    dispatch(setBranchData(BranchData))
    if (branchDataLength > 1 && !(Array.isArray(currentCompany) && currentCompany[0].IsRestrictedToBranch)) {
      setFormValues({
        ...formValues,
        companyType: currentCompany?.CompanyType,
        branch: currentCompany?.BranchID,
      })
      setBranches(BranchData)
    } else {
      setFormValues({
        ...formValues,
        companyType: currentCompany?.CompanyType,
        branch: currentCompany?.BranchID,
      })
    }
  }

  const handleChangeBranch = (value: string) => {
    setFormValues({
      ...formValues,
      branch: value,
    })
  }

  useEffect(() => {
    // set default values
    const clientsLength = clients?.length
    if (clientsLength === 1) {
      const clientUid = clients && clients[0]?.client_uid
      handleChangeClient(clientUid as string)
    }
  }, [clients])

  useEffect(() => {
    if (companyTypeIsSuccess) {
      const companyTypesDataLength = companyTypesData?.length || 0
      if (companyTypesData && companyTypesDataLength === 1) {
        handleChangeCompanyTypes(companyTypesData[0].CompanyType as string)
      }
    }
  }, [companyTypeIsSuccess])

  const handleSubmit = async (values: SessionFormFields) => {
    const { branch, companyType, client } = values
    await userLogin({
      'CLIENT-UID': client,
      'COMPANY-TYPE': companyType,
      'COMPANY-BRANCH': branch,
    })
  }

  useEffect(() => {
    if (loginDataIsSuccess) {
      dispatch(setSession(formValues))
      navigate(appRoute.SignIn, { replace: true })
    }
  }, [loginDataIsSuccess])

  return (
    <AuthTemplate backgroundImg={selectSessionBg}>
      <Typography
        sx={{
          marginTop: 6,
          fontWeight: 500,
          fontSize: '40px',
          color: theme.palette.text.primary,
        }}
        textAlign='center'
      >
        Session Details
      </Typography>
      <Typography sx={{ marginTop: 3, fontSize: '16px', color: theme.palette.text.secondary }} textAlign='center'>
        Select your session details.
      </Typography>
      <Form initialValues={formValues} onSubmit={handleSubmit} validate={validateForm(sessionValidation)}>
        {({ handleSubmit, submitting, invalid }: any) => (
          <form onSubmit={handleSubmit}>
            {clients?.length > 1 && (
              <Grid sx={{ marginTop: 5 }}>
                <SelectField
                  id='client-helper'
                  labelId='client-helper'
                  fullWidth
                  autoFocus
                  name='client'
                  optionKey={'client_uid'}
                  optionValue={'client_name'}
                  label={sessionLabels.client}
                  inputProps={{ sx: { borderRadius: '8px' } }}
                  options={clients}
                  onChange={handleChangeClient}
                />
              </Grid>
            )}
            {companyTypesData && companyTypesData?.length > 1 && (
              <Grid sx={{ marginTop: 5 }}>
                <SelectField
                  labelId='company-type'
                  id='company-type'
                  name='companyType'
                  fullWidth
                  autoFocus
                  optionKey={'CompanyType'}
                  optionValue={'CompanyType'}
                  label={sessionLabels.companyType}
                  inputProps={{ sx: { borderRadius: '8px' } }}
                  options={companyTypesData}
                  onChange={handleChangeCompanyTypes}
                />
              </Grid>
            )}
            {branches?.length > 1 && (
              <Grid sx={{ marginTop: 5 }}>
                <SelectField
                  labelId='BranchID'
                  id='Branch'
                  name='branch'
                  fullWidth
                  autoFocus
                  optionKey={'BranchID'}
                  optionValue={'Branch'}
                  label={sessionLabels.branch}
                  inputProps={{ sx: { borderRadius: '8px' } }}
                  options={branches}
                  onChange={handleChangeBranch}
                />
              </Grid>
            )}
            <CustomCircular open={companyTypeIsLoading} />
            <Button
              type='submit'
              sx={{
                borderRadius: '8px',
                background: '#1570EF',
                marginTop: 5,
                paddingY: '12px',
              }}
              color='primary'
              variant='contained'
              fullWidth
              disabled={invalid || submitting}
            >
              {submitting ? <CircularProgress /> : 'Next'}
            </Button>
          </form>
        )}
      </Form>
    </AuthTemplate>
  )
}
