import { CardHeader, Grid, Typography, generateUtilityClasses } from '@mui/material'
import { styled } from '@mui/system'
import React, { useEffect } from 'react'
import DropdownFieldSearch from '../../../../../ui/DropdownFieldSearch'
import { TextField } from '../../../../../ui'
import { useSelector } from 'react-redux'
import { getStates } from '../../../../../redux/slices/userDropdownSlice'
import { useForm } from 'react-final-form'
import { useLazyGetInfoByZipCodeQuery } from '../../../../../services'
import AddressField from '../../../../../ui/AddressField'

const classes = generateUtilityClasses('Grid', ['root', 'titleRow', 'row'])

const StyledGrid = styled(Grid)(({ theme }) => ({
  [`&.${classes.root}`]: {
    minHeight: '400px',
  },
  [`& .${classes.titleRow}`]: {
    margin: theme.spacing(0.5, 0, 1, 0),
  },
  [`& .${classes.row}`]: {
    margin: theme.spacing(1, 0, 2, 0),
  },
}))

const PayForm = () => {
  const states = useSelector(getStates)
  const form = useForm()
  const addressValue = form.getFieldState('Address1')?.value || ''
  const [getInfoByZipCode, { data: infoByZipCodeData, isSuccess: infoByZipCodeIsSuccess, isLoading: infoByZipCodeIsLoading }] =
    useLazyGetInfoByZipCodeQuery()

  useEffect(() => {
    if (addressValue) {
      const { street, city, state, zip } = addressValue

      if (zip) {
        getInfoByZipCode({ zipCode: zip?.toString() as string })
      }

      // Only update the form fields if the parsed values are not null
      if (street) form.change('Address1', street)
      if (city) form.change('City', city)
      if (state) form.change('State', state)
      if (zip) form.change('ZipCode', zip)
    }
  }, [addressValue, form])
  return (
    <>
      <CardHeader title='Pay To The Order Of' />

      <Grid container spacing={2} p={3}>
        <Grid item xs={12} sm={6}>
          <TextField name='Attention' label='Attention' fullWidth autoComplete='off' />
        </Grid>
        <Grid item xs={12} sm={6}>
          <TextField name='PayeeName' label='Payee Name' fullWidth autoComplete='off' />
        </Grid>
        <Grid item xs={12} sm={6}>
          <AddressField name='Address1' label='Address Line 1' fullWidth autoComplete='off' />
        </Grid>
        <Grid item xs={12} sm={6}>
          <TextField name='Address2' label='Address Line 2' fullWidth autoComplete='off' />
        </Grid>
        <Grid item xs={12} sm={6}>
          <TextField name='City' label='City' fullWidth />
        </Grid>
        <Grid item xs={12} sm={3}>
          <DropdownFieldSearch name='State' label='State' options={states} optionKey='State' optionValue='State' autoComplete='off' />
        </Grid>
        <Grid item xs={12} sm={3}>
          <TextField name='ZipCode' label='Zip Code' fullWidth autoComplete='off' />
        </Grid>
      </Grid>
    </>
  )
}

export default PayForm
