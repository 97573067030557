import dayjs from 'dayjs'
import * as yup from 'yup'


const today = dayjs().endOf('day')

export const addLoanValidation: yup.SchemaOf<any> = yup
  .object({
    modelYear: yup
    .string()
    .required("Model Year is a required field"),
    make:yup
    .string()
    .required("Make is a required field"),
    firstDueDate:  yup
    .date()
    .nullable()
    .required("First Due Date is a required field")
    .typeError('Invalid Date')
    .test('match-due-days', 'Due Date must be greater than Contract Date', function (value) {
      const { contractDate } = this.parent
      if (!value) return false
      const dueDate = dayjs(value)
      if (dayjs(contractDate) >= dueDate) {
        return false 
      } else {
        return true
      }
    }),
    contractDate:  yup.date()
    .nullable()
    .required("Contract Date is a required field")
    .typeError('Invalid Date'),

    amountFinanced :yup
    .string()
    .required("Amount Financed is a required field"),
    financeCharges:yup
    .string()
    .required("Finance Charges is a required field"),
    paymentSchedule: yup
    .string()
    .required("Payment Schedule is a required field"),
    paymentAmount:yup
    .string()
    .required("Payment Amount is a required field"),
 
    bookValue1:yup
    .string()
    .required("Book Value 1 is a required field"),
 
    bookValue2:yup
    .string()
    .required("Book Value 2 is a required field"),
 
    state:yup
    .string()
    .required("State is a required field"),
    interestMethod:yup
    .string()
    .required("Interest Method is a required field"),
    apr:yup
    .string()
    .required("APR is a required field"),
    lastPaidDate: yup.date().when('midStream', {
      is: true,
      then: yup.date()
      .required('Last Paid Date is a required field')
      .typeError('Invalid Date'),
      otherwise: yup.date().nullable(),
    }),
    
    nextDueDate: yup
    .date().when('midStream', {
      is: true,
      then: yup.date().nullable()
      .required("Next Due Date is a required field")
      .typeError('Invalid Date')
      .test('match-due-days', 'Next Due Date must be greater than First Due Date', function (value) {
        const { firstDueDate } = this.parent
        if (!value) return false
        const dueDate = dayjs(value)
        if (dayjs(firstDueDate) > dueDate) {
          return false 
        } else {
          return true
        }
      }),
      otherwise: yup.date().nullable(),
    })
    ,
    principalBalance:yup
    .string() 
         .when('midStream', {
          is: true,
          then: yup.string().required("Principal Balance is a required field"),
          otherwise: yup.string().nullable(),
  
        }),
    
    interestPaid:yup
    .string() .when('midStream', {
      is: true,
      then: yup.string().required("Interest Paid is a required field"),
      otherwise: yup.string().nullable(),

    }),
    paidTowardsAdvance:yup
    .string().when('midStream', {
      is: true,
      then: yup.string().required("Paid towards Advance is a required field"),
      otherwise: yup.string().nullable(),

    }),
    branch:yup
    .string()
    .required("Branch is a required field"),
    Dealer:yup
    .string()
    .required(),
    discountAmount:yup
    .string()
    .required("Discount Amount is a required field"),
    // ApplicantLastName: yup
    //   .string()
    //   .nullable()
    //   .notRequired()
    //   .when('ApplicantAccountType', {
    //     is: 'individual',
    //     then: yup.string().nullable().required().label(addLeadsLabels.ApplicantLastName),
    //     otherwise: yup.string().nullable(),

    //   }),
  })
  .required()
