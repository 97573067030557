import { Button, CircularProgress, Dialog, DialogActions, DialogContent, DialogTitle, Grid } from '@mui/material'
import React, { Dispatch, SetStateAction, useEffect, useState } from 'react'
import { Form } from 'react-final-form'
import { useDispatch, useSelector } from 'react-redux'
import { useNavigate } from 'react-router-dom'
import { ClientBranchData, SessionFormFields } from '../../models'
import { dropdownsApi, useLazyCompanyTypesQuery, useLazyGetUserRoleAccessRightsQuery, useUserLoggedInMutation } from '../../services'
import { CustomCircular, SelectField } from '../../ui'
import { sessionLabels, sessionValidation } from '../../validation'
import { getClients, setBranchData, setSession, setUserAccessRights, setCompanyTypes } from '../../redux/slices/authSlice'
import { appRoute } from '../../constants'
import validateForm from '../../utils/validateForm'
import { storageGet } from '../../utils/storage'
import { enqueueNotification } from '../../redux/slices/notificationsSlice'

function SelectSessionModal({ open, setOpen }: { open: boolean; setOpen: Dispatch<SetStateAction<boolean>> }) {
  const navigate = useNavigate()
  const dispatch = useDispatch<any>()
  const clients = useSelector(getClients)
  const [formValues, setFormValues] = useState({})
  const [branches, setBranches] = useState<ClientBranchData[]>([])
  const [companyTypes, { data: companyTypesData, isLoading: companyTypeIsLoading, isSuccess: companyTypeIsSuccess }] = useLazyCompanyTypesQuery()
  const [userLogin, { isSuccess: loginDataIsSuccess, isLoading, data }] = useUserLoggedInMutation()
  const [getUserAccessRights, { data: userAccessRightsData, isSuccess: userAccessRightsIsSuccess }] = useLazyGetUserRoleAccessRightsQuery()

  const handleChangeClient = (value: string) => {
    setFormValues({ client: value })
    companyTypes({ clientUid: value })
  }

  const handleChangeCompanyTypes = (value?: string) => {
    const currentCompany = companyTypesData && companyTypesData?.find(({ CompanyType }) => CompanyType === value)
    const branchDataLength = currentCompany?.BranchData?.length || 0
    const BranchData = currentCompany?.BranchData || []
    dispatch(setBranchData(BranchData))
    if (branchDataLength > 1 && !(Array.isArray(currentCompany) && currentCompany[0].IsRestrictedToBranch)) {
      setFormValues({
        ...formValues,
        companyType: currentCompany?.CompanyType,
        branch: currentCompany?.BranchID,
      })
      setBranches(BranchData)
    } else {
      setFormValues({
        ...formValues,
        companyType: currentCompany?.CompanyType,
        branch: currentCompany?.BranchID,
      })
    }
  }

  const handleChangeBranch = (value: string) => {
    setFormValues({
      ...formValues,
      branch: value,
    })
  }

  useEffect(() => {
    // set default values
    const access = storageGet('client')

    if (access) {
      handleChangeClient(access as string)
    }
  }, [clients])

  useEffect(() => {
    if (companyTypeIsSuccess) {
      const companyTypesDataLength = companyTypesData?.length || 0
      if (companyTypesData && companyTypesDataLength === 1) {
        handleChangeCompanyTypes(companyTypesData[0].CompanyType as string)
      }
    }
  }, [companyTypeIsSuccess])

  const handleSubmit = async (values: SessionFormFields) => {
    const { branch, companyType, client } = values
    await userLogin({
      'CLIENT-UID': client,
      'COMPANY-TYPE': companyType,
      'COMPANY-BRANCH': branch,
    })
  }

  async function fetchAndSetUserAccessRights() {
    try {
      const response = await getUserAccessRights({})
      if (response?.isSuccess) {
        dispatch(setUserAccessRights(response.data))
        dispatch(dropdownsApi.endpoints.getAllDropdowns.initiate(undefined, { forceRefetch: true }))
      }
    } catch (error) {
      dispatch(enqueueNotification({ message: error }))
    }
  }

  useEffect(() => {
    if (loginDataIsSuccess) {
      dispatch(setSession(formValues))
      dispatch(setCompanyTypes(companyTypesData))
      setOpen(false)
      fetchAndSetUserAccessRights()
      navigate(appRoute.SignIn, { replace: true })
    }
  }, [loginDataIsSuccess, isLoading, data])
  return (
    <Dialog open={open} onClose={() => setOpen(false)} sx={{ '& .MuiPaper-root': { minWidth: 400 } }}>
      <DialogTitle>Select Session</DialogTitle>

      <Form initialValues={formValues} onSubmit={handleSubmit} validate={validateForm(sessionValidation)}>
        {({ handleSubmit, submitting, invalid }: any) => (
          <form onSubmit={handleSubmit}>
            <DialogContent>
              <Grid container spacing={2} py={2}>
                {clients?.length > 1 && (
                  <Grid item xs={12}>
                    <SelectField
                      id='client-helper'
                      labelId='client-helper'
                      fullWidth
                      autoFocus
                      name='client'
                      optionKey={'client_uid'}
                      optionValue={'client_name'}
                      label={sessionLabels.client}
                      inputProps={{ sx: { borderRadius: '8px' } }}
                      options={clients}
                      onChange={handleChangeClient}
                    />
                  </Grid>
                )}
                {companyTypesData && companyTypesData?.length > 1 && (
                  <Grid item xs={12}>
                    <SelectField
                      labelId='company-type'
                      id='company-type'
                      name='companyType'
                      fullWidth
                      autoFocus
                      optionKey={'CompanyType'}
                      optionValue={'CompanyType'}
                      label={sessionLabels.companyType}
                      inputProps={{ sx: { borderRadius: '8px' } }}
                      options={companyTypesData}
                      onChange={handleChangeCompanyTypes}
                    />
                  </Grid>
                )}
                {branches?.length > 1 && (
                  <Grid item xs={12}>
                    <SelectField
                      labelId='BranchID'
                      id='Branch'
                      name='branch'
                      fullWidth
                      autoFocus
                      optionKey={'BranchID'}
                      optionValue={'Branch'}
                      label={sessionLabels.branch}
                      inputProps={{ sx: { borderRadius: '8px' } }}
                      options={branches}
                      onChange={handleChangeBranch}
                    />
                  </Grid>
                )}
              </Grid>
              <CustomCircular open={companyTypeIsLoading} />
            </DialogContent>

            <DialogActions>
              <Button
                type='submit'
                sx={{
                  borderRadius: '8px',
                  paddingY: '12px',
                }}
                variant='contained'
                fullWidth
                disabled={invalid || submitting}
              >
                {submitting ? <CircularProgress /> : 'Next'}
              </Button>
            </DialogActions>
          </form>
        )}
      </Form>
    </Dialog>
  )
}

export default SelectSessionModal
