import React from 'react'
import { generateUtilityClasses, Grid, Typography } from '@mui/material'
import { styled } from '@mui/system'
import DropdownFieldSearch from '../../../../../ui/DropdownFieldSearch'
import { DateField } from '../../../../../ui'
import { useSelector } from 'react-redux'
import { selectDropdownByNameActiveOnly } from '../../../../../services'

const classes = generateUtilityClasses('Grid', ['root', 'row', 'firstNameWrap'])

const StyledGrid = styled(Grid)(({ theme }) => ({
  [`&.${classes.root}`]: {},
  [`& .${classes.firstNameWrap}`]: {
    margin: theme.spacing(0, 0, 2, 0),
  },
  [`& .${classes.row}`]: {
    margin: theme.spacing(1, 0, 2, 0),
  },
}))

interface Props {
  onDropdownFormChange: () => void
  disableFields: boolean
}

const ApplicationForm = ({ onDropdownFormChange, disableFields }: Props) => {
  const user = useSelector((state) => selectDropdownByNameActiveOnly(state, 'UserAll'))

  return (
    <Grid container item xs={12} spacing={2} p={3}>
      <Grid item xs={12} sm={6}>
        <DropdownFieldSearch
          disabled={disableFields}
          name='TitleAppliedFor'
          label='Title Applied For?'
          options={[
            { key: true, value: 'Yes' },
            { key: false, value: 'No' },
          ]}
          optionKey='key'
          optionValue='value'
          onChange={() => {
            onDropdownFormChange()
          }}
        />
      </Grid>
      <Grid item xs={12} sm={6}>
        <DateField disabled={disableFields} onDropdownFormChange={onDropdownFormChange} name='DateApplied' label='Date Applied' fullWidth />
      </Grid>
      {user && (
        <Grid item xs={12} sm={6}>
          <DropdownFieldSearch
            disabled={disableFields}
            name='AppliedForBy'
            label='Applied For By'
            options={user}
            optionKey='UserID'
            optionValue='UserCode'
            onChange={() => {
              onDropdownFormChange()
            }}
          />
        </Grid>
      )}
    </Grid>
  )
}

export default ApplicationForm
