import React from 'react'
import { styled } from '@mui/system'
import { CardHeader, FormControlLabel, Grid, Switch, Typography } from '@mui/material'
import { generateUtilityClasses } from '@mui/material'
import { CheckboxField } from '../../../../../../ui'
import { Field, useFormState } from 'react-final-form'

const classes = generateUtilityClasses('Grid', ['root'])

const StyledGrid = styled(Grid)(() => ({
  [`&.${classes.root}`]: {},
}))

interface Props {}

export const InventoryForm = ({}: Props) => {
  const { values } = useFormState()

  return (
    <>
      <CardHeader title='Inventory' />
      <Grid container spacing={2} padding={3}>
        <Grid item xs={12} sm={4} md={3}>
          <Field name='AccessInventory'>
            {(props) => (
              <FormControlLabel
                sx={{ width: '100%' }}
                control={
                  <Switch
                    name={props.input.name}
                    checked={props.input.value}
                    onChange={(e) => {
                      props.input.onChange(e.target.checked)
                    }}
                  />
                }
                labelPlacement='end'
                label='Access Inventory'
              />
            )}
          </Field>
        </Grid>
        {values.AccessInventory && (
          <>
            <Grid item xs={12} sm={4} md={3}>
              <Field name='AddInventory'>
                {(props) => (
                  <FormControlLabel
                    sx={{ width: '100%' }}
                    control={
                      <Switch
                        name={props.input.name}
                        checked={props.input.value}
                        onChange={(e) => {
                          props.input.onChange(e.target.checked)
                        }}
                      />
                    }
                    labelPlacement='end'
                    label='Add Inventory'
                  />
                )}
              </Field>
            </Grid>
            <Grid item xs={12} sm={4} md={3}>
              <Field name='DeleteInventory'>
                {(props) => (
                  <FormControlLabel
                    sx={{ width: '100%' }}
                    control={
                      <Switch
                        name={props.input.name}
                        checked={props.input.value}
                        onChange={(e) => {
                          props.input.onChange(e.target.checked)
                        }}
                      />
                    }
                    labelPlacement='end'
                    label='Delete Inventory'
                  />
                )}
              </Field>
            </Grid>
            <Grid item xs={12} sm={4} md={3}>
              <Field name='EditInventory'>
                {(props) => (
                  <FormControlLabel
                    sx={{ width: '100%' }}
                    control={
                      <Switch
                        name={props.input.name}
                        checked={props.input.value}
                        onChange={(e) => {
                          props.input.onChange(e.target.checked)
                        }}
                      />
                    }
                    labelPlacement='end'
                    label='Edit Inventory'
                  />
                )}
              </Field>
            </Grid>
            <Grid item xs={12} sm={4} md={3}>
              <Field name='ViewInventoryCost'>
                {(props) => (
                  <FormControlLabel
                    sx={{ width: '100%' }}
                    control={
                      <Switch
                        name={props.input.name}
                        checked={props.input.value}
                        onChange={(e) => {
                          props.input.onChange(e.target.checked)
                        }}
                      />
                    }
                    labelPlacement='end'
                    label='View Inventory Cost'
                  />
                )}
              </Field>
            </Grid>
            <Grid item xs={12} sm={4} md={3}>
              <Field name='ViewPurchasePrice'>
                {(props) => (
                  <FormControlLabel
                    sx={{ width: '100%' }}
                    control={
                      <Switch
                        name={props.input.name}
                        checked={props.input.value}
                        onChange={(e) => {
                          props.input.onChange(e.target.checked)
                        }}
                      />
                    }
                    labelPlacement='end'
                    label='View Purchase Price'
                  />
                )}
              </Field>
            </Grid>
          </>
        )}
      </Grid>
    </>
  )
}

export default InventoryForm
