import { closestCenter, DndContext, DragEndEvent, KeyboardSensor, PointerSensor, useSensor, useSensors } from '@dnd-kit/core'
import { Button, Dialog, DialogActions, DialogContent, DialogTitle, Divider } from '@mui/material'
import { arrayMove, SortableContext, sortableKeyboardCoordinates, verticalListSortingStrategy } from '@dnd-kit/sortable'
import { SortableItem } from './sortableItem'
import { GetCarImagesApiResponse } from '../../../models'
import { useEffect, useState } from 'react'
import { restrictToVerticalAxis } from '@dnd-kit/modifiers'
import { usePostCarImagesMutation } from '../../../services'
import { LoadingButton } from '@mui/lab'

function ReorderPicturesModal({
  carId,
  refresh,
  images,
  open,
  onClose,
}: {
  carId: string
  refresh: any
  images: GetCarImagesApiResponse
  open: boolean
  onClose: () => void
}) {
  const [imageList, setImageList] = useState(images)
  const [postCarImages, { isLoading: postCarImagesIsLoading, isSuccess: postCarImagesIsSuccess }] = usePostCarImagesMutation()

  useEffect(() => {
    setImageList(images)
  }, [images])

  useEffect(() => {
    if (postCarImagesIsSuccess) {
      refresh()
      onClose()
    }
  }, [postCarImagesIsLoading, postCarImagesIsSuccess])

  const sensors = useSensors(
    useSensor(PointerSensor),
    useSensor(KeyboardSensor, {
      coordinateGetter: sortableKeyboardCoordinates,
    }),
  )

  function handleDragEnd(event: DragEndEvent) {
    const { active, over } = event

    if (over && active.id !== over.id) {
      setImageList((items) => {
        const oldIndex = items.findIndex((item) => item.CarImageID === active.id)
        const newIndex = items.findIndex((item) => item.CarImageID === over.id)

        return arrayMove(items, oldIndex, newIndex)
      })
    }
  }

  return (
    <Dialog open={open}>
      <DialogTitle>Re-Order Pictures</DialogTitle>
      <DialogContent>
        <DndContext sensors={sensors} collisionDetection={closestCenter} onDragEnd={handleDragEnd} modifiers={[restrictToVerticalAxis]}>
          <SortableContext items={imageList.map((el) => el.CarImageID)} strategy={verticalListSortingStrategy}>
            {imageList.map((el) => (
              <>
                <SortableItem image={el} id={el.CarImageID} />
                <Divider />
              </>
            ))}
          </SortableContext>
        </DndContext>
      </DialogContent>
      <DialogActions>
        <Button color='inherit' variant='outlined' onClick={onClose}>
          Cancel
        </Button>

        <LoadingButton
          type='submit'
          variant='contained'
          onClick={() => {
            postCarImages({
              carId: carId,
              imageOrderData: {
                imageOrderData: imageList.map((el, index) => {
                  return { CarImageID: el.CarImageID, ImageOrder: index++ }
                }),
              },
            })
          }}
          loading={postCarImagesIsLoading}
        >
          Submit
        </LoadingButton>
      </DialogActions>
    </Dialog>
  )
}

export default ReorderPicturesModal
