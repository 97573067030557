import React from 'react'
import { styled } from '@mui/system'
import { CardHeader, FormControlLabel, Grid, Switch, Typography } from '@mui/material'
import { generateUtilityClasses } from '@mui/material'
import { CheckboxField } from '../../../../../../ui'
import { Field, useFormState } from 'react-final-form'

const classes = generateUtilityClasses('Grid', ['root'])

const StyledGrid = styled(Grid)(() => ({
  [`&.${classes.root}`]: {},
}))

interface Props {}

export const AccountingForm = ({}: Props) => {
  const { values } = useFormState()

  return (
    <>
      <CardHeader title='Accounting' />
      <Grid container spacing={2} padding={3}>
        <Grid item xs={12} sm={4} md={3}>
          <Field name='AccessAccounting'>
            {(props) => (
              <FormControlLabel
                sx={{ width: '100%' }}
                control={
                  <Switch
                    name={props.input.name}
                    checked={props.input.value}
                    onChange={(e) => {
                      props.input.onChange(e.target.checked)
                    }}
                  />
                }
                labelPlacement='end'
                label='Access Accounting'
              />
            )}
          </Field>
        </Grid>
        {values.AccessAccounting && (
          <>
            <Grid item xs={12} sm={4} md={3}>
              <Field name='AddBankDeposits'>
                {(props) => (
                  <FormControlLabel
                    sx={{ width: '100%' }}
                    control={
                      <Switch
                        name={props.input.name}
                        checked={props.input.value}
                        onChange={(e) => {
                          props.input.onChange(e.target.checked)
                        }}
                      />
                    }
                    labelPlacement='end'
                    label='Add Bank Deposits'
                  />
                )}
              </Field>
            </Grid>
            <Grid item xs={12} sm={4} md={3}>
              <Field name='AddJournalEntry'>
                {(props) => (
                  <FormControlLabel
                    sx={{ width: '100%' }}
                    control={
                      <Switch
                        name={props.input.name}
                        checked={props.input.value}
                        onChange={(e) => {
                          props.input.onChange(e.target.checked)
                        }}
                      />
                    }
                    labelPlacement='end'
                    label='Add Journal Entry'
                  />
                )}
              </Field>
            </Grid>
            <Grid item xs={12} sm={4} md={3}>
              <Field name='AddLedgerOnlyJournalEntry'>
                {(props) => (
                  <FormControlLabel
                    sx={{ width: '100%' }}
                    control={
                      <Switch
                        name={props.input.name}
                        checked={props.input.value}
                        onChange={(e) => {
                          props.input.onChange(e.target.checked)
                        }}
                      />
                    }
                    labelPlacement='end'
                    label='Add Ledger Only Journal Entry'
                  />
                )}
              </Field>
            </Grid>
            <Grid item xs={12} sm={4} md={3}>
              <Field name='AddBills'>
                {(props) => (
                  <FormControlLabel
                    sx={{ width: '100%' }}
                    control={
                      <Switch
                        name={props.input.name}
                        checked={props.input.value}
                        onChange={(e) => {
                          props.input.onChange(e.target.checked)
                        }}
                      />
                    }
                    labelPlacement='end'
                    label='Add Bills'
                  />
                )}
              </Field>
            </Grid>
            <Grid item xs={12} sm={4} md={3}>
              <Field name='DeleteBills'>
                {(props) => (
                  <FormControlLabel
                    sx={{ width: '100%' }}
                    control={
                      <Switch
                        name={props.input.name}
                        checked={props.input.value}
                        onChange={(e) => {
                          props.input.onChange(e.target.checked)
                        }}
                      />
                    }
                    labelPlacement='end'
                    label='Delete Bills'
                  />
                )}
              </Field>
            </Grid>
            <Grid item xs={12} sm={4} md={3}>
              <Field name='NewBillsareApprovedToPay'>
                {(props) => (
                  <FormControlLabel
                    sx={{ width: '100%' }}
                    control={
                      <Switch
                        name={props.input.name}
                        checked={props.input.value}
                        onChange={(e) => {
                          props.input.onChange(e.target.checked)
                        }}
                      />
                    }
                    labelPlacement='end'
                    label='New Bills are Approved To Pay'
                  />
                )}
              </Field>
            </Grid>
            <Grid item xs={12} sm={4} md={3}>
              <Field name='CanApproveBillsforPayment'>
                {(props) => (
                  <FormControlLabel
                    sx={{ width: '100%' }}
                    control={
                      <Switch
                        name={props.input.name}
                        checked={props.input.value}
                        onChange={(e) => {
                          props.input.onChange(e.target.checked)
                        }}
                      />
                    }
                    labelPlacement='end'
                    label='Can Approve Bills for Payment'
                  />
                )}
              </Field>
            </Grid>
            <Grid item xs={12} sm={4} md={3}>
              <Field name='IssueChecks'>
                {(props) => (
                  <FormControlLabel
                    sx={{ width: '100%' }}
                    control={
                      <Switch
                        name={props.input.name}
                        checked={props.input.value}
                        onChange={(e) => {
                          props.input.onChange(e.target.checked)
                        }}
                      />
                    }
                    labelPlacement='end'
                    label='Issue Checks'
                  />
                )}
              </Field>
            </Grid>
            <Grid item xs={12} sm={4} md={3}>
              <Field name='PayBills'>
                {(props) => (
                  <FormControlLabel
                    sx={{ width: '100%' }}
                    control={
                      <Switch
                        name={props.input.name}
                        checked={props.input.value}
                        onChange={(e) => {
                          props.input.onChange(e.target.checked)
                        }}
                      />
                    }
                    labelPlacement='end'
                    label='Pay Bills'
                  />
                )}
              </Field>
            </Grid>
            <Grid item xs={12} sm={4} md={3}>
              <Field name='PayUnapprovedBills'>
                {(props) => (
                  <FormControlLabel
                    sx={{ width: '100%' }}
                    control={
                      <Switch
                        name={props.input.name}
                        checked={props.input.value}
                        onChange={(e) => {
                          props.input.onChange(e.target.checked)
                        }}
                      />
                    }
                    labelPlacement='end'
                    label='Pay Unapproved Bills'
                  />
                )}
              </Field>
            </Grid>
            <Grid item xs={12} sm={4} md={3}>
              <Field name='VoidChecks'>
                {(props) => (
                  <FormControlLabel
                    sx={{ width: '100%' }}
                    control={
                      <Switch
                        name={props.input.name}
                        checked={props.input.value}
                        onChange={(e) => {
                          props.input.onChange(e.target.checked)
                        }}
                      />
                    }
                    labelPlacement='end'
                    label='Void Checks'
                  />
                )}
              </Field>
            </Grid>
            <Grid item xs={12} sm={4} md={3}>
              <Field name='AccessChartofAccounts'>
                {(props) => (
                  <FormControlLabel
                    sx={{ width: '100%' }}
                    control={
                      <Switch
                        name={props.input.name}
                        checked={props.input.value}
                        onChange={(e) => {
                          props.input.onChange(e.target.checked)
                        }}
                      />
                    }
                    labelPlacement='end'
                    label='Access Chart of Accounts'
                  />
                )}
              </Field>
            </Grid>
            <Grid item xs={12} sm={4} md={3}>
              <Field name='ChooseRestrictedChartofAccounts'>
                {(props) => (
                  <FormControlLabel
                    sx={{ width: '100%' }}
                    control={
                      <Switch
                        name={props.input.name}
                        checked={props.input.value}
                        onChange={(e) => {
                          props.input.onChange(e.target.checked)
                        }}
                      />
                    }
                    labelPlacement='end'
                    label='Choose Restricted Chart of Accounts'
                  />
                )}
              </Field>
            </Grid>
            <Grid item xs={12} sm={4} md={3}>
              <Field name='AccessReconciliations'>
                {(props) => (
                  <FormControlLabel
                    sx={{ width: '100%' }}
                    control={
                      <Switch
                        name={props.input.name}
                        checked={props.input.value}
                        onChange={(e) => {
                          props.input.onChange(e.target.checked)
                        }}
                      />
                    }
                    labelPlacement='end'
                    label='Access Reconciliations'
                  />
                )}
              </Field>
            </Grid>
            <Grid item xs={12} sm={4} md={3}>
              <Field name='AccessFinancials'>
                {(props) => (
                  <FormControlLabel
                    sx={{ width: '100%' }}
                    control={
                      <Switch
                        name={props.input.name}
                        checked={props.input.value}
                        onChange={(e) => {
                          props.input.onChange(e.target.checked)
                        }}
                      />
                    }
                    labelPlacement='end'
                    label='Access Financials'
                  />
                )}
              </Field>
            </Grid>
            <Grid item xs={12} sm={4} md={3}>
              <Field name='ClosePeriods'>
                {(props) => (
                  <FormControlLabel
                    sx={{ width: '100%' }}
                    control={
                      <Switch
                        name={props.input.name}
                        checked={props.input.value}
                        onChange={(e) => {
                          props.input.onChange(e.target.checked)
                        }}
                      />
                    }
                    labelPlacement='end'
                    label='Close Periods'
                  />
                )}
              </Field>
            </Grid>
          </>
        )}
      </Grid>
    </>
  )
}

export default AccountingForm
