import { createSlice } from '@reduxjs/toolkit'
import { RootState } from '../store'
import { storageGet, storageRemove, storageSet } from '../../utils/storage'
import { ClientBranchData, UserClients, UspGetDataUserRoleResult } from '../../models'

export type Auth = {
  refresh?: string | null
  access?: string | null
  clients_list?: UserClients[]
  branch?: string | null
  branchData?: ClientBranchData[]
  companyType?: string | null
  client?: string | null
  authInProgress?: boolean
  userAccessRights?: UspGetDataUserRoleResult[] | null
  companyTypes?: any[]
}

export const slice = createSlice({
  name: 'auth',
  initialState: {
    refresh: undefined,
    access: undefined,
    clients_list: [],
    branch: undefined,
    branchData: [],
    companyType: undefined,
    client: undefined,
    authInProgress: false, // required to prevent routing before auth is complete
    userAccessRights: undefined,
    companyTypes: [],
  } as Auth,
  reducers: {
    onInit: (state) => {
      const access = storageGet('access')
      const refresh = storageGet('refresh')
      const clients_list = JSON.parse(storageGet('clients_list') as string)
      const branch = storageGet('branch')
      const branchData = JSON.parse(storageGet('branchData') as string)
      const companyType = storageGet('companyType')
      const client = storageGet('client')
      const userAccessRights = JSON.parse(storageGet('user_access_rights') as string)
      const companyTypes = JSON.parse(storageGet('company_types') as string)
      state.clients_list = clients_list
      state.refresh = refresh
      state.access = access
      state.branch = branch
      state.branchData = branchData
      state.companyType = companyType
      state.client = client
      state.userAccessRights = userAccessRights
      state.companyTypes = companyTypes
    },
    setUserAccessRights: (state, { payload }) => {
      state.userAccessRights = payload
      storageSet('user_access_rights', JSON.stringify(payload))
    },
    setCompanyTypes: (state, { payload }) => {
    state.companyTypes = payload
      storageSet('company_types', JSON.stringify(payload))
    },
    setCredentials: (state, { payload }) => {
      const { access, refresh, clients_list } = payload || {}
      state.access = access
      state.refresh = refresh
      state.clients_list = clients_list || []
      storageSet('access', access)
      storageSet('refresh', refresh)
      storageSet('clients_list', JSON.stringify(clients_list))
    },
    setBranchData: (state, { payload }) => {
      state.branchData = payload || []
      storageSet('branchData', JSON.stringify(payload))
    },
    setSession: (state, { payload }) => {
      const { branch, companyType, client } = payload || {}
      state.branch = branch
      state.companyType = companyType
      state.client = client
      storageSet('branch', branch)
      storageSet('companyType', companyType)
      storageSet('client', client)
    },
    setAuthInProgress: (state, { payload }) => {
      state.authInProgress = payload
    },
    onLogout: (state) => {
      state.access = undefined
      state.refresh = undefined
      state.clients_list = undefined
      state.branch = undefined
      state.branchData = undefined
      state.companyType = undefined
      state.client = undefined
      state.userAccessRights = undefined
      storageRemove('access')
      storageRemove('refresh')
      storageRemove('clients_list')
      storageRemove('branch')
      storageRemove('branchData')
      storageRemove('companyType')
      storageRemove('client')
      storageRemove('user_access_rights')
    },
  },
})

export const isAuthorized = (state: RootState): boolean => !!state?.auth?.access
export const isSessionSelected = (state: RootState): boolean => !!(state?.auth?.branch && state?.auth?.companyType && state?.auth?.client)
export const getClients = (state: RootState): UserClients[] => state?.auth?.clients_list || []

export const getAuthInProgress = (state: RootState): boolean => state?.auth?.authInProgress

export const getUserAccessRights = (state: RootState): any => state?.auth?.userAccessRights || {}
export const getCompanyTypes = (state: RootState): any => state?.auth?.companyTypes || {}

export const getAuth = (state: RootState): boolean => state?.auth
export const { onInit, setCredentials, setBranchData,  setAuthInProgress, onLogout, setUserAccessRights,setSession, setCompanyTypes } = slice.actions

export default slice.reducer
