import { CardHeader, Grid } from '@mui/material'
import React from 'react'
import { PhoneTextField, TextField } from '../../../../../../ui'
import AddressField from '../../../../../../ui/AddressField'
import DropdownFieldSearch from '../../../../../../ui/DropdownFieldSearch'
import { useSelector } from 'react-redux'
import { getPhoneTypes, getStates } from '../../../../../../redux/slices/userDropdownSlice'
import { useFormState } from 'react-final-form'

function ContactForm() {
  const states = useSelector(getStates)
  const { values } = useFormState()
  const phoneTypes = useSelector(getPhoneTypes)

  return (
    <>
      <CardHeader title='Contact Info' />
      <Grid container item spacing={2} px={3} pt={3}>
        <Grid item xs={12} sm={2}>
          <DropdownFieldSearch
            name='PhoneType'
            label='Phone Type'
            options={phoneTypes}
            optionKey='PhoneType'
            optionValue='PhoneType'
            initialValue='Biz'
          />
        </Grid>
        <Grid item xs={12} sm={values?.PhoneType === 'Biz' ? 2 : 4}>
          <PhoneTextField name='PhoneNumber' label='Phone' fullWidth />
        </Grid>
        {values?.PhoneType === 'Biz' && (
          <>
            <Grid item xs={12} sm={2}>
              <TextField name='PhoneExtension' label='Ext' fullWidth />
            </Grid>
          </>
        )}

        <Grid item xs={12} sm={2}>
          <DropdownFieldSearch
            name='SecondaryPhoneType'
            label='Phone Type'
            options={phoneTypes}
            optionKey='PhoneType'
            optionValue='PhoneType'
            initialValue='Cell'
          />
        </Grid>
        <Grid item xs={12} sm={values?.SecondaryPhoneType === 'Biz' ? 2 : 4}>
          <PhoneTextField name='SecondaryPhoneNumber' label='Phone' fullWidth />
        </Grid>
        {values?.SecondaryPhoneType === 'Biz' && (
          <>
            <Grid item xs={12} sm={2}>
              <TextField name='SecondaryPhoneExtension' label='Ext' fullWidth />
            </Grid>
          </>
        )}

        <Grid item xs={12} sm={6}>
          <TextField name='Email' label='Email' fullWidth />
        </Grid>
      </Grid>
    </>
  )
}

export default ContactForm
