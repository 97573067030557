import React, { useEffect, useCallback } from 'react'
import { debounce } from 'lodash'
import { styled } from '@mui/system'
import { Container, Typography, Grid, Button } from '@mui/material'
import InputAdornment from '@mui/material/InputAdornment'
import SearchIcon from '@mui/icons-material/Search'
import { generateUtilityClasses } from '@mui/material'
import { useNavigate } from 'react-router-dom'
import { useFormState } from 'react-final-form'
import AddIcon from '@mui/icons-material/Add'
import { CheckboxField, TextField } from '../../../../../../ui'
import { appRoute } from '../../../../../../constants'
import Iconify from '../../../../../../components/iconify'

const classes = generateUtilityClasses('Container', ['root', 'titleWrap', 'searchWrap', 'checkboxWrap', 'searchBtn'])

const StyledFormWrap = styled(Container)(({ theme }) => ({
  [`&.${classes.root}`]: {
    padding: theme.spacing(1),
    margin: 0,
    display: 'flex',
    backgroundColor: theme.palette.background.main,
    width: '100%',
    maxWidth: 'unset',
    position: 'sticky',
    top: 0,
    zIndex: 10,
  },
  [`& .${classes.titleWrap}`]: {
    margin: theme.spacing(2, 2, 1, 2),
  },
  [`& .${classes.searchWrap}`]: {
    width: theme.spacing(30),
    margin: theme.spacing(0, 4, 0, 0),
  },
  [`& .${classes.checkboxWrap}`]: {
    width: theme.spacing(30),
    margin: theme.spacing(0, 4, 0, 0),
  },
  [`& .${classes.searchBtn}`]: {
    width: '120px',
    margin: theme.spacing(0, 2, 0, 'auto'),
  },
}))

export const DealersSearchBlock = ({ submit }: { submit: (...args: any) => any }) => {
  const navigate = useNavigate()
  const { values } = useFormState()

  const updateWithDebounce = useCallback(
    debounce(() => submit(values), 500),
    [],
  )

  useEffect(() => {
    // search with debounce
    if (values?.searchString || values?.searchString !== '') {
      updateWithDebounce()
    }
  }, [values?.searchString])

  useEffect(() => {
    submit(values)
  }, [values?.isIncludeInactive])

  return (
    <Grid container spacing={2} px={3} pt={3} alignItems={'center'}>
      <Grid item xs={12} sm={6}>
        <TextField
          name='searchString'
          placeholder='Search'
          fullWidth
          InputProps={{
            id: 'search',
            autoComplete: 'search',
            startAdornment: (
              <InputAdornment position='start'>
                <Iconify icon='eva:search-fill' sx={{ color: 'text.disabled' }} />
              </InputAdornment>
            ),
          }}
        />
      </Grid>
      <Grid item xs={12} sm={6}>
        <CheckboxField name='isIncludeInactive' label='Include Inactive' />
      </Grid>
    </Grid>
  )
}

export default DealersSearchBlock
