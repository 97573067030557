import React from 'react'
import { styled } from '@mui/system'
import { CardHeader, Grid, Typography } from '@mui/material'
import { generateUtilityClasses } from '@mui/material'
import { AmountField } from '../../../../../../ui'

const classes = generateUtilityClasses('Grid', ['root', 'row'])

const StyledGrid = styled(Grid)(({ theme }) => ({
  [`&.${classes.root}`]: {},
  [`& .${classes.row}`]: {
    margin: theme.spacing(1, 0, 2, 0),
  },
}))

interface Props {}

export const ReservesForm = ({}: Props) => {
  return (
    <>
      <CardHeader title='Reserves' />

      <Grid container spacing={2} px={3} pt={3}>
        <Grid item xs={12} sm={6}>
          <AmountField variant='standard' name='ReserveAmount1' label='Reserve Amount 1' fullWidth />
        </Grid>
        <Grid item xs={12} sm={6}>
          <AmountField variant='standard' name='ReserveAmount2' label='Reserve Amount 2' fullWidth />
        </Grid>
        <Grid item xs={12} sm={6}>
          <AmountField variant='standard' name='ReserveAmount3' label='Reserve Amount 3' fullWidth />
        </Grid>
        <Grid item xs={12} sm={6}>
          <AmountField variant='standard' name='ReserveAmount4' label='Reserve Amount 4' fullWidth />
        </Grid>
      </Grid>
    </>
  )
}

export default ReservesForm
