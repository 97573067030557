import React, { useEffect } from 'react'
import { styled } from '@mui/system'
import { CardHeader, Grid, Typography } from '@mui/material'
import { generateUtilityClasses } from '@mui/material'
import { useForm, useFormState } from 'react-final-form'
import { useSelector } from 'react-redux'
import { getPhoneTypes, getStates } from '../../../../../../redux/slices/userDropdownSlice'
import { PhoneTextField, TextField } from '../../../../../../ui'
import AddressField from '../../../../../../ui/AddressField'
import DropdownFieldSearch from '../../../../../../ui/DropdownFieldSearch'
import { useLazyGetInfoByZipCodeQuery } from '../../../../../../services'

const classes = generateUtilityClasses('Grid', ['root', 'row', 'row'])

const StyledGrid = styled(Grid)(({ theme }) => ({
  [`&.${classes.root}`]: {},
  [`& .${classes.row}`]: {
    margin: theme.spacing(1, 0, 2, 0),
  },
  [`& .${classes.row}`]: {
    margin: theme.spacing(0, 0, 2, 0),
  },
}))

interface Props {}

export const InsuranceForm = ({}: Props) => {
  const states = useSelector(getStates)
  const form = useForm()
  const addressValue = form.getFieldState('Address1')?.value || ''
  const { values } = useFormState()
  const phoneTypes = useSelector(getPhoneTypes)
  const [getInfoByZipCode, { data: infoByZipCodeData }] = useLazyGetInfoByZipCodeQuery()

  useEffect(() => {
    if (addressValue) {
      const { street, city, state, zip } = addressValue
      if (zip) {
        getInfoByZipCode({ zipCode: zip?.toString() as string })
      }

      // Only update the form fields if the parsed values are not null
      if (street) form.change('Address1', street)
      if (city) form.change('City', city)
      if (state) form.change('State', state)
      if (zip) form.change('PostalCode', zip)
      if (infoByZipCodeData) {
        form.change('County', infoByZipCodeData?.County)
      }
    }
  }, [addressValue, form, infoByZipCodeData])
  return (
    <>
      <CardHeader title='Insurance' />
      <Grid container item spacing={2} px={3} pt={3}>
        <Grid item xs={12} sm={6}>
          <TextField name='InsuranceCompany' label='Insurance Company' fullWidth />
        </Grid>
        <Grid item xs={12} sm={6}>
          <TextField name='Agent' label='Agent' fullWidth />
        </Grid>
      </Grid>
    </>
  )
}

export default InsuranceForm
