import React from 'react'
import { styled } from '@mui/system'
import { CardHeader, FormControlLabel, Grid, Switch, Typography } from '@mui/material'
import { TextField } from '../../../../../ui'
import { generateUtilityClasses } from '@mui/material'
import { Field, useFormState } from 'react-final-form'
import RadioField from '../../../../../ui/RadioField'
import DropdownFieldSearch from '../../../../../ui/DropdownFieldSearch'
import { getContactSuffixes } from '../../../../../redux/slices/userDropdownSlice'
import { useSelector } from 'react-redux'
import { userLabels } from '../../../../../validation'

const classes = generateUtilityClasses('Grid', ['root', 'row', 'firstNameWrap'])

const StyledGrid = styled(Grid)(({ theme }) => ({
  [`&.${classes.root}`]: {},
  [`& .${classes.row}`]: {
    margin: theme.spacing(1, 0, 2, 0),
  },
  [`& .${classes.firstNameWrap}`]: {
    margin: theme.spacing(0, 0, 2, 0),
  },
}))

interface Props {}

export const VendorForm = ({}: Props) => {
  const { values } = useFormState()
  const contactSuffixes = useSelector(getContactSuffixes)

  const accountOptions: any[] = [
    { key: 'individual', value: 'Individual' },
    { key: 'business', value: 'Business' },
  ]
  return (
    <>
      <CardHeader title='Name' />
      <Grid container spacing={2} px={3} pt={3}>
        <Field name='AccountType'>
          {(props) => (
            <div>
              <FormControlLabel
                sx={{ m: 2, top: 25, position: 'absolute', left: 80 }}
                control={
                  <Switch
                    name={props.input.name}
                    checked={props.input.value === 'business'}
                    onChange={(e) => {
                      props.input.onChange(e.target.checked ? 'business' : 'individual')
                    }}
                  />
                }
                label='Business'
              />
            </div>
          )}
        </Field>
        {values?.AccountType === 'individual' ? (
          <>
            <Grid item xs={12} sm={6}>
              <TextField name='FirstName' label={userLabels.firstName} fullWidth />
            </Grid>
            <Grid item xs={12} sm={6}>
              <TextField name='MiddleName' label='Middle Name' fullWidth />
            </Grid>
            <Grid item xs={12} sm={4}>
              <TextField name='LastName' label={userLabels.lastName} fullWidth />
            </Grid>
            <Grid item xs={12} sm={2}>
              <DropdownFieldSearch name='Suffix' label='Suffix' options={contactSuffixes} optionKey='Suffix' optionValue='Suffix' optionNone />
            </Grid>{' '}
            <Grid item xs={12} sm={6}>
              <TextField name='NickName' label={userLabels.nickName} fullWidth />
            </Grid>
          </>
        ) : (
          <>
            <Grid item xs={12} sm={6}>
              <TextField name='Name' label={userLabels.businessName} fullWidth />
            </Grid>
            <Grid item xs={12} sm={6}>
              <TextField name='DBA' label='DBA' fullWidth />
            </Grid>
          </>
        )}
      </Grid>
    </>
  )
}

export default VendorForm
