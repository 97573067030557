import React, { useEffect } from 'react'
import { styled } from '@mui/system'
import { CardHeader, Grid, Typography } from '@mui/material'
import { useSelector } from 'react-redux'
import { TextField } from '../../../../../ui'
import { generateUtilityClasses } from '@mui/material'
import { getStates } from '../../../../../redux/slices/userDropdownSlice'
import { useForm } from 'react-final-form'
import { useLazyGetInfoByZipCodeQuery } from '../../../../../services'
import DropdownFieldSearch from '../../../../../ui/DropdownFieldSearch'
import AddressField from '../../../../../ui/AddressField'

const classes = generateUtilityClasses('Grid', ['root', 'row', 'optionalText'])

const StyledGrid = styled(Grid)(({ theme }) => ({
  [`&.${classes.root}`]: {},
  [`& .${classes.row}`]: {
    margin: theme.spacing(1, 0, 2, 0),
  },
  [`& .${classes.optionalText}`]: {
    fontFamily: 'Open Sans, sans-serif',
    fontWeight: 400,
    Size: '0.8750em',
    fontStyle: 'italic',
    lineHeight: '1.1875em',
    marginLeft: theme.spacing(1),
  },
}))

interface Props {
  onDropdownFormChange: () => void
  disableFields: boolean
  searchConractData: any
}

export const AddressForm = ({ onDropdownFormChange, disableFields, searchConractData }: Props) => {
  const states = useSelector(getStates)
  const form = useForm()
  const addressValue = form.getFieldState('ApplicantAddress1')?.value || ''
  const [getInfoByZipCode, { data: infoByZipCodeData }] = useLazyGetInfoByZipCodeQuery()

  useEffect(() => {
    if (addressValue) {
      const { street, city, state, zip } = addressValue

      if (zip) {
        getInfoByZipCode({ zipCode: zip?.toString() as string })
      }

      // Only update the form fields if the parsed values are not null
      if (street) form.change('ApplicantAddress1', street)
      if (city) form.change('ApplicantCity', city)
      if (state) form.change('ApplicantState', state)
      if (zip) form.change('ApplicantPostalCode', zip)
      if (infoByZipCodeData) {
        form.change('ApplicantCounty', infoByZipCodeData?.County)
      }
    }
  }, [addressValue, form, infoByZipCodeData])

  return (
    <>
      <CardHeader title='Address (Optional)' />
      <Grid container spacing={2} padding={3}>
        <Grid item xs={12} sm={6}>
          <AddressField disabled={disableFields} name='ApplicantAddress1' label='Address Line 1' fullWidth autoComplete='off' />
        </Grid>
        <Grid item xs={12} sm={6}>
          <TextField disabled={disableFields} name='ApplicantAddress2' label='Address Line 2' fullWidth autoComplete='off' />
        </Grid>
        <Grid item xs={12} sm={4}>
          <TextField disabled={disableFields} name='ApplicantCity' label='City' fullWidth autoComplete='off' />
        </Grid>
        <Grid item xs={12} sm={2}>
          <DropdownFieldSearch
            disabled={disableFields}
            name='ApplicantState'
            label='State'
            options={states}
            optionKey='State'
            optionValue='State'
            autoComplete='off'
            onChange={() => {
              onDropdownFormChange()
            }}
          />
        </Grid>
        <Grid item xs={12} sm={2}>
          <TextField disabled={disableFields} name='ApplicantPostalCode' label='Zip Code' fullWidth autoComplete='off' />
        </Grid>
        <Grid item xs={12} sm={4}>
          <TextField disabled={disableFields} name='ApplicantCounty' label='County' options={states} fullWidth autoComplete='off' />
        </Grid>
      </Grid>
    </>
  )
}

export default AddressForm
