import React from 'react'
import { generateUtilityClasses, Grid, Typography } from '@mui/material'
import DropdownFieldSearch from '../../../../../ui/DropdownFieldSearch'
import DateField from '../../../../../ui/DateField'
import { TextField } from '../../../../../ui'
import AmountField from '../../../../../ui/AmountField'
import { styled } from '@mui/system'

const classes = generateUtilityClasses('Grid', ['root', 'row', 'optionalText'])

const StyledGrid = styled(Grid)(({ theme }) => ({
  [`&.${classes.root}`]: {},
  [`& .${classes.row}`]: {
    margin: theme.spacing(1, 0, 2, 0),
  },
}))

interface Props {
  onDropdownFormChange: () => void
  disableFields: boolean
}
const VehicleForm = ({ onDropdownFormChange, disableFields }: Props) => {
  return (
    <Grid container item xs={12} spacing={2} p={3}>
      <Grid item xs={12} sm={6}>
        <DropdownFieldSearch
          disabled={disableFields}
          name='IsVehiclePickedUp'
          label='Is Vehicle Picked Up?'
          options={[
            { key: true, value: 'Yes' },
            { key: false, value: 'No' },
          ]}
          autoComplete='off'
          onChange={() => {
            onDropdownFormChange()
          }}
        />
      </Grid>
      <Grid item xs={12} sm={6}>
        <DateField disabled={disableFields} onDropdownFormChange={onDropdownFormChange} name='DatePickedUp' label='Date Picked Up' fullWidth />
      </Grid>
      <Grid item xs={12} sm={6}>
        <TextField disabled={disableFields} name='VehicleLocation' label='Vehicle Location' fullWidth />
      </Grid>
      <Grid item xs={12} sm={6}>
        <TextField disabled={disableFields} name='CurrentMileage' label='Current Mileage' fullWidth />
      </Grid>

      <Grid item xs={12} sm={6}>
        <AmountField variant='standard' disabled={disableFields} name='BookValue' label='Book Value' fullWidth />
      </Grid>
      <Grid item xs={12} sm={6}>
        <AmountField variant='standard' disabled={disableFields} name='EstimatedRepairs' label='Estimated Repairs' fullWidth />
      </Grid>
    </Grid>
  )
}

export default VehicleForm
