import React from 'react'
import { styled } from '@mui/system'
import { CardHeader, Grid, Typography } from '@mui/material'
import { generateUtilityClasses } from '@mui/material'
import { useSelector } from 'react-redux'
import { getPermissionsList } from '../../../../../redux/slices/userDropdownSlice'
import { useFormState } from 'react-final-form'
import DropdownFieldSearch from '../../../../../ui/DropdownFieldSearch'
import { selectDropdownByNameActiveOnly } from '../../../../../services'

const classes = generateUtilityClasses('Grid', ['root', 'row', 'optionalText'])

const StyledGrid = styled(Grid)(({ theme }) => ({
  [`&.${classes.root}`]: {},
  [`& .${classes.row}`]: {
    margin: theme.spacing(1, 0, 2, 0),
  },
  [`& .${classes.optionalText}`]: {
    fontFamily: 'Open Sans, sans-serif',
    fontWeight: 400,
    Size: '0.8750em',
    fontStyle: 'italic',
    lineHeight: '1.1875em',
    marginLeft: theme.spacing(1),
  },
}))

interface Props {}

export const ServiceDepartmentForm = ({}: Props) => {
  const { values } = useFormState()
  const roles = useSelector((state) => selectDropdownByNameActiveOnly(state, 'Roles'))
  const permissionsList = useSelector(getPermissionsList)
  const service = permissionsList?.Service
  const companies = service?.Companies
  const branches = service?.Branches

  return (
    <>
      <CardHeader title='Service Department' />
      <Grid container spacing={2} px={3} pt={3}>
        <Grid item xs={12} sm={6}>
          <DropdownFieldSearch
            name='isAllowedAccessService'
            label='Is Allowed Access?'
            options={[
              { key: true, value: 'Yes' },
              { key: false, value: 'No' },
            ]}
          />
        </Grid>
        {values?.isAllowedAccessService && roles && (
          <>
            <Grid item xs={12} sm={6}>
              <DropdownFieldSearch name='serviceRoleID' label='Role' options={roles} optionKey='ID' optionValue='UserRole' required />
            </Grid>
            <Grid item xs={12} sm={6}>
              <DropdownFieldSearch name='serviceBranchID' label='Branch' options={branches} optionKey='BranchID' optionValue='Branch' required />
            </Grid>
            <Grid item xs={12} sm={6}>
              <DropdownFieldSearch
                name='isRestrictedToServiceBranch'
                label='Restrict Access To Branch?'
                options={[
                  { key: true, value: 'Yes' },
                  { key: false, value: 'No' },
                ]}
              />
            </Grid>
            {!values?.isRestrictedToServiceBranch && (
              <Grid item xs={12} sm={6}>
                <DropdownFieldSearch
                  name='restrictedToServiceCompanyID'
                  label='Restrict To Company (Optional)'
                  options={companies}
                  optionKey='ID'
                  optionValue='Company'
                  optionNone
                />
              </Grid>
            )}
          </>
        )}
      </Grid>
    </>
  )
}

export default ServiceDepartmentForm
