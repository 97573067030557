import React, { useEffect, useMemo, useState } from 'react'
import { useParams } from 'react-router-dom'
import { styled } from '@mui/material/styles'
import Grid from '@mui/material/Grid'
import { Header } from '../components/Header'
import { generateUtilityClasses, IconButton, Modal, Stack, Typography } from '@mui/material'
import { withCars } from '../../../hoc'
import { useDispatch } from 'react-redux'
import { useDeleteCarImageMutation, useLazyGetCarImagesQuery, useUploadCarImageMutation } from '../../../services'
import Button from '@mui/material/Button'
import ViewIcon from '@mui/icons-material/Visibility'
import DeleteIcon from '@mui/icons-material/Delete'
import { enqueueNotification } from '../../../redux/slices/notificationsSlice'
import { CustomCircular } from '../../../ui'
import CloseIcon from '@mui/icons-material/Close'
import ConfirmDialog from '../../../components/ConfirmDialog'
import ReorderPicturesModal from './reorderPicturesModal'
import CustomBreadcrumbs from '../../../components/custom-breadcrumbs/custom-breadcrumbs'
import UploadImageModal from './uploadImageModal'

const classes = generateUtilityClasses('Grid', ['root', 'pageBody', 'titleWrap'])

const StyledGrid = styled('form')(({ theme }) => ({
  [`&.${classes.root}`]: {
    display: 'flex',
    flexDirection: 'column',
    minHeight: '100vh',
  },
  [`& .${classes.pageBody}`]: {
    padding: theme.spacing(0, 4, 2, 4),
  },
  [`& .${classes.titleWrap}`]: {
    display: 'flex',
    gap: '10px',
    alignItems: 'center',
    paddingLeft: '0px',
    margin: theme.spacing(2, 2, 2, 0),
  },
}))

const HoverButtons = styled('div')(({ theme }) => ({
  display: 'none',
  position: 'absolute',
  top: '50%',
  left: '50%',
  transform: 'translate(-50%, -50%)',
  alignItems: 'center',
  borderRadius: theme.shape.borderRadius,
  padding: theme.spacing(1),
}))

const StyledButton = styled(Button)(({ theme }) => ({
  color: theme.palette.common.white,
  width: '120px',
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'flex-start',
  gap: '10px',
  textAlign: 'left',
  border: '1px solid transparent',
  '&:hover': {
    border: '1px solid white',
    backgroundColor: 'rgba(255, 255, 255, 0.2)',
  },
}))

const StyledImageListItem = styled('div')(({ theme }) => ({
  display: 'block',
  position: 'relative',
  paddingBottom: '100%',
  '& img': {
    position: 'absolute',
    objectFit: 'cover',
    width: '100%',
    height: '100%',
    top: '0',
    bottom: '0',
    right: '0',
    left: '0',
  },
}))

const StyledModal = styled('div')(({ theme }) => ({
  background: 'transparent',
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'center',
  width: '80vw',
  height: '80vh',
  position: 'absolute',
  top: '50%',
  left: '50%',
  transform: 'translate(-50%, -50%)',
}))

const CloseButton = styled(IconButton)(({ theme }) => ({
  position: 'fixed',
  top: theme.spacing(0),
  right: theme.spacing(0),
  color: theme.palette.grey[500],
  zIndex: 1301,
  padding: 0,
  margin: 0,
}))

const EnlargedImage = styled('img')({
  width: '100%',
  height: '100%',
  objectFit: 'contain',
})

const ImageWrapper = styled('div')(({ theme }) => ({
  position: 'relative',
  '&:hover': {
    '& .Overlay': {
      opacity: 0.5,
    },
    '& .HoverButtons': {
      display: 'flex',
      gap: theme.spacing(1),
      flexDirection: 'column',
      zIndex: 2,
    },
  },
}))

const Overlay = styled('div')({
  position: 'absolute',
  top: 0,
  left: 0,
  right: 0,
  bottom: 0,
  backgroundColor: 'rgba(0, 0, 0, 0.5)',
  opacity: 0,
  transition: 'opacity 0.3s ease',
  borderRadius: '5px',
})

export const InventoryEditPictures = (props: any) => {
  const { getCarImages, carImagesData, carImagesIsLoading } = props
  const [deleteCarImages, { isLoading: deleteCarImagesIsLoading, isSuccess: deleteCarImagesIsSuccess }] = useDeleteCarImageMutation()
  const { carID } = useParams()
  const dispatch = useDispatch()
  const [formChanged, setFormChanged] = useState(false)
  const fileInputRef = React.useRef<HTMLInputElement>(null)
  const [anchorEl, setAnchorEl] = React.useState<HTMLButtonElement | null>(null)
  const [selectedImage, setSelectedImage] = React.useState<string | null>(null)
  const [deleteConfirmDialog, setDeleteConfirmDialog] = React.useState<any>(null)
  const [openReorder, setOpenReorder] = React.useState<boolean>(false)
  const [openUpload, setOpenUpload] = React.useState<boolean>(false)

  const open = Boolean(anchorEl)
  const id = open ? 'image-popover' : undefined

  const handleViewClick = (event: React.MouseEvent<HTMLButtonElement>, imageLink: string) => {
    setAnchorEl(event.currentTarget)
    setSelectedImage(imageLink)
  }

  const handleClose = () => {
    setAnchorEl(null)
    setSelectedImage(null)
  }

  const handleDeleteImage = () => {
    setDeleteConfirmDialog(undefined)
    deleteCarImages({ carId: carID?.toString() as string, carImageId: deleteConfirmDialog })
  }

  useEffect(() => {
    if (deleteCarImagesIsSuccess) {
      getCarImages({ carId: carID?.toString() as string })
      dispatch(enqueueNotification({ message: 'Success' }))
    }
  }, [deleteCarImagesIsSuccess])

  const isLoading = useMemo(() => carImagesIsLoading || deleteCarImagesIsLoading, [deleteCarImagesIsLoading, carImagesIsLoading])

  return (
    <StyledGrid className={classes.root}>
      {/* <Header
        modelYear={carData?.ModelYear}
        make={carData?.Make}
        model={carData?.Model}
        vin={carData?.VIN}
        stockNumber={carData?.StockNumber}
        carIsSuccess={props?.carIsSuccess}
        carData={props?.carData}
        getCar={props?.getCar}
        formChanged={formChanged}
        setFormChanged={setFormChanged}
        onDropdownFormChange={() => setFormChanged(true)}
      /> */}
      <Grid container spacing={{ xs: 2, md: 3 }} columns={{ xs: 4, sm: 8, md: 12 }} rowSpacing={1} columnSpacing={1} className={classes.pageBody}>
        <Grid item xs={12}>
          <CustomBreadcrumbs
            heading=''
            links={[]}
            action={
              <Stack direction={'row'} spacing={1}>
                {/* <Button
                  variant='contained'
                  onClick={() => {
                    setOpenUpload(true)
                  }}
                >
                  Upload
                </Button> */}
                {carImagesData && carImagesData.length > 0 && (
                  <Button variant='contained' onClick={() => setOpenReorder(true)}>
                    Re-Order
                  </Button>
                )}
              </Stack>
            }
            sx={{
              mb: {
                xs: 3,
                md: 5,
              },
            }}
          />
        </Grid>

        {/* <Grid item xs={12} className={classes.titleWrap}>
          <Typography variant='h3'>Images</Typography>
          <input ref={fileInputRef} type='file' onChange={handleFileSelection} style={{ display: 'none' }} />
          <Button variant='contained' onClick={handleUploadButtonClick}>
            Upload
          </Button>

          {carImagesData && carImagesData.length > 0 && (
            <Button variant='contained' onClick={() => setOpenReorder(true)}>
              Re-Order
            </Button>
          )}
        </Grid> */}

        <Grid container gap={2}>
          {(carImagesData || []).map((item: any) => (
            <Grid item xs={12} sm={5} md={5} lg={2} xl={2}>
              <ImageWrapper key={item.CarImageID}>
                <StyledImageListItem>
                  <img style={{ borderRadius: '10px' }} src={item.ImageLink} alt='Inventory Car Image' loading='lazy' />
                  <Overlay className='Overlay' />
                  <HoverButtons className='HoverButtons'>
                    <StyledButton variant='outlined' onClick={(e) => handleViewClick(e, item?.ImageLink)}>
                      <ViewIcon />
                      View
                    </StyledButton>
                    <StyledButton variant='outlined' onClick={() => setDeleteConfirmDialog(item.CarImageID)}>
                      <DeleteIcon />
                      Delete
                    </StyledButton>
                  </HoverButtons>
                </StyledImageListItem>
              </ImageWrapper>
              <Modal id={id} open={open} onClose={handleClose}>
                <StyledModal onClick={handleClose}>
                  <CloseButton aria-label='close' onClick={handleClose}>
                    <CloseIcon />
                  </CloseButton>
                  {selectedImage && <EnlargedImage src={selectedImage} alt='Enlarged' />}
                </StyledModal>
              </Modal>
            </Grid>
          ))}
        </Grid>
      </Grid>
      <CustomCircular open={isLoading} />
      <ConfirmDialog
        open={!!deleteConfirmDialog}
        onOk={handleDeleteImage}
        handleClose={() => setDeleteConfirmDialog(undefined)}
        notification={'Image will be removed'}
      />

      <ReorderPicturesModal
        carId={(carID ?? '').toString() as string}
        refresh={() => getCarImages({ carId: carID?.toString() as string })}
        onClose={() => {
          setOpenReorder(false)
        }}
        open={openReorder}
        images={carImagesData ?? []}
      />
    </StyledGrid>
  )
}

export default withCars(InventoryEditPictures)
