import { m } from 'framer-motion'

import Box from '@mui/material/Box'
import Stack from '@mui/material/Stack'
import Avatar from '@mui/material/Avatar'
import Divider from '@mui/material/Divider'
import { alpha } from '@mui/material/styles'
import MenuItem from '@mui/material/MenuItem'
import IconButton from '@mui/material/IconButton'
import Typography from '@mui/material/Typography'

import { paths } from '../../routes/paths'
import { useRouter } from '../../routes/hooks'

import { useMockedUser } from '../../hooks/use-mocked-user'

import { varHover } from '../../components/animate'
import { useSnackbar } from '../../components/snackbar'
import CustomPopover, { usePopover } from '../../components/custom-popover'
import { useDispatch, useSelector } from 'react-redux'
import { getUserAccessRights, onLogout } from '../../redux/slices/authSlice'
import { clearUserDropdowns } from '../../redux/slices/userDropdownSlice'
import SelectSessionModal from '../../components/select-session/selectSessionModal'
import { useState } from 'react'

// ----------------------------------------------------------------------

const OPTIONS = [
  {
    label: 'Home',
    linkTo: '/',
  },
  // {
  //   label: 'Profile',
  //   linkTo: paths.dashboard.user.profile,
  // },
  // {
  //   label: 'Settings',
  //   linkTo: paths.dashboard.user.account,
  // },
]

// ----------------------------------------------------------------------

export default function AccountPopover() {
  const router = useRouter()
  const dispatch = useDispatch()
  const [open, setOpen] = useState(false)
  // const { user } = useMockedUser()
  const userAccess = useSelector(getUserAccessRights)

  const popover = usePopover()

  const handleLogout = (): void => {
    dispatch(onLogout())
    dispatch(clearUserDropdowns())
  }

  const handleClickItem = (path: string) => {
    popover.onClose()
    router.push(path)
  }
  return (
    <>
      <IconButton
        component={m.button}
        whileTap='tap'
        whileHover='hover'
        variants={varHover(1.05)}
        onClick={popover.onOpen}
        sx={{
          width: 40,
          height: 40,
          background: (theme) => alpha(theme.palette.grey[500], 0.08),
          ...(popover.open && {
            background: (theme) => `linear-gradient(135deg, ${theme.palette.primary.light} 0%, ${theme.palette.primary.main} 100%)`,
          }),
        }}
      >
        <Avatar
          src=''
          alt={userAccess?.Name}
          sx={{
            width: 40,
            height: 40,
            fontSize: 20,
            border: (theme) => `solid 2px ${theme.palette.background.default}`,
          }}
        >
          {userAccess?.Initials}
        </Avatar>
      </IconButton>

      <CustomPopover open={popover.open} onClose={popover.onClose} sx={{ width: 200, p: 0 }}>
        <Box sx={{ p: 2, pb: 1.5 }}>
          <Typography variant='subtitle2' noWrap>
            {userAccess?.Name}
          </Typography>

          <Typography variant='body2' sx={{ color: 'text.secondary' }} noWrap>
            {userAccess?.Username}
          </Typography>
        </Box>

        <Divider sx={{ borderStyle: 'dashed' }} />

        <Stack sx={{ p: 1 }}>
          {OPTIONS.map((option) => (
            <MenuItem key={option.label} onClick={() => handleClickItem(option.linkTo)}>
              {option.label}
            </MenuItem>
          ))}
        </Stack>

        <Divider sx={{ borderStyle: 'dashed' }} />
        <Stack sx={{ p: 1 }}>
          <MenuItem onClick={() => setOpen(true)} sx={{ fontWeight: 'fontWeightBold' }}>
            Switch Company
          </MenuItem>
        </Stack>
        <Divider sx={{ borderStyle: 'dashed' }} />

        <MenuItem onClick={handleLogout} sx={{ m: 1, fontWeight: 'fontWeightBold', color: 'error.main' }}>
          Logout
        </MenuItem>
      </CustomPopover>
      <SelectSessionModal open={open} setOpen={setOpen} />
    </>
  )
}
