import { useEffect, useCallback } from 'react'
import { debounce } from 'lodash'
import { Typography, Grid, Box, InputAdornment } from '@mui/material'
import { TextField, DropdownCheckboxes } from '../../../../../../../ui'
import { useSelector } from 'react-redux'
import { getBranch } from '../../../../../../../redux/slices/userDropdownSlice'
import { useFormState } from 'react-final-form'
import SearchIcon from '@mui/icons-material/Search'
import Iconify from '../../../../../../../components/iconify'
interface Props {
  submit: (...args: any) => any
  setCurrentStage?: (stage: 'inventory' | 'credit' | 'dealStructure' | 'dealResult') => void
}

export const InventorySearchBlock = ({ submit }: Props) => {
  const branchData = useSelector(getBranch)
  const { values } = useFormState()

  const updateWithDebounce = useCallback(
    debounce(() => submit(values), 500),
    [],
  )

  useEffect(() => {
    // search with debounce
    if (values?.searchString || values?.searchString !== null) {
      updateWithDebounce()
    }
  }, [values?.searchString])

  useEffect(() => {
    submit(values)
  }, [values?.branch, values?.isIncludeSoldCars, values.isPendingTradeOnly])

  return (
    <Box display='flex' gap={3} p={3}>
      <Grid container spacing={2}>
        <Grid item xs={12} sm={6}>
          <TextField
            name='searchString'
            placeholder='Search'
            fullWidth
            InputProps={{
              startAdornment: (
                <InputAdornment position='start'>
                  <Iconify icon='eva:search-fill' sx={{ color: 'text.disabled' }} />
                </InputAdornment>
              ),
            }}
          />
        </Grid>
        <Grid item xs={12} sm={6}>
          {branchData?.length > 1 && (
            <DropdownCheckboxes
              name='branch'
              label='Branch'
              labelId='BranchId'
              fullWidth
              options={branchData}
              optionKey='BranchID'
              optionValue='Branch'
            />
          )}
        </Grid>
      </Grid>
    </Box>
  )
}

export default InventorySearchBlock
