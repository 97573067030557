import dayjs from 'dayjs'
import * as yup from 'yup'
export const addLeadsLabels = {
  ApplicantFirstName: 'First Name',
  ApplicantMiddleName: 'Middle Name',
  ApplicantLastName: 'Last Name',
  ApplicantBusinessName: 'Business Name',
  EmploymentCurrentName: 'Name',
  IncomeMonthlyIncome: 'Income',
  ApplicantEmail: 'Email',
  EmploymentCurrentWorkEmail: 'Email',
  Email: 'Email',
}

const today = dayjs().endOf('day')

export const addLeadsValidation: yup.SchemaOf<any> = yup
  .object({
    ApplicantFirstName: yup
      .string()
      .when('ApplicantAccountType', {
        is: 'individual',
        then: yup.string().nullable().required().label(addLeadsLabels.ApplicantFirstName),
        otherwise: yup.string().nullable(),
      }),
    ApplicantLastName: yup
      .string()
      .nullable()
      .notRequired()
      .when('ApplicantAccountType', {
        is: 'individual',
        then: yup.string().nullable().required().label(addLeadsLabels.ApplicantLastName),
        otherwise: yup.string().nullable(),

      }),
    ApplicantBusinessName: yup
      .string()
      .nullable()
      .notRequired()
      .when('ApplicantAccountType', {
        is: 'business',
        then: yup.string().required().label(addLeadsLabels.ApplicantBusinessName),
        otherwise: yup.string().nullable(),

      }),
    ApplicantEmail: yup.string().email().nullable().label(addLeadsLabels.ApplicantEmail),
    EmploymentCurrentWorkEmail: yup.string().email().nullable().label(addLeadsLabels.EmploymentCurrentWorkEmail),
    EmploymentPreviousWorkEmail: yup.string().email().nullable().label(addLeadsLabels.EmploymentCurrentWorkEmail),
    Email: yup.string().email().nullable().label(addLeadsLabels.Email),
    EmploymentCurrentDateStarted: yup.date().nullable().notRequired().typeError('Invalid Date').max(today, 'Future date not allowed'),
    ApplicantDateOfBirth: yup.date().nullable().notRequired().typeError('Invalid Date').max(today, 'Future date not allowed'),
    ApplicantLicenseIssued: yup.date().nullable().notRequired().typeError('Invalid Date').max(today, 'Future date not allowed'),
    EmploymentPreviousDateStarted: yup.date().nullable().notRequired().typeError('Invalid Date').max(today, 'Future date not allowed'),
  })
  .required()
