import React from 'react'
import FeePopover from '../../../../Deals/DealsAddEdit/subpages/DealStructure/components/FeePopover'
import { Card, CardHeader, Grid, IconButton } from '@mui/material'
import { AmountField } from '../../../../../ui'
import Iconify from '../../../../../components/iconify'
import ProductPopover from '../../../../Deals/DealsAddEdit/subpages/DealStructure/components/ProductPopover'

function ContractItemization({ productsForm, feesForm }: any) {
  const [anchorElFee, setAnchorElFee] = React.useState<HTMLButtonElement | null>(null)
  const [anchorElProducts, setAnchorElProducts] = React.useState<HTMLButtonElement | null>(null)

  const handleClickFee = (event: React.MouseEvent<HTMLButtonElement>) => {
    setAnchorElFee(event?.currentTarget)
  }

  const handleClickProducts = (event: React.MouseEvent<HTMLButtonElement>) => {
    setAnchorElProducts(event?.currentTarget)
  }

  return (
    <Card>
      <CardHeader title='Contract Itemization (Optional)' />
      <Grid container spacing={3} sx={{ p: 3 }}>
        <Grid item xs={12} sm={6}>
          <AmountField variant='standard' name='salesPrice' label='Sales Price' fullWidth autoComplete='off' />
        </Grid>
        <Grid item xs={12} sm={6}>
          <AmountField variant='standard' name='cashDown' label='Cash Down' fullWidth autoComplete='off' />
        </Grid>
        <Grid item xs={12} sm={6}>
          <AmountField variant='standard' name='tradeAllowance' label='Trade Allowance' fullWidth autoComplete='off' />
        </Grid>
        <Grid item xs={12} sm={6}>
          <AmountField variant='standard' name='tradeLien' label='Trade Lien' fullWidth autoComplete='off' />
        </Grid>
        <Grid item xs={12} sm={6}>
          <AmountField
            variant='standard'
            // disabled={disableFields}
            name='Fees'
            label='Fees'
            fullWidth
            readonly
            endAdornment={
              <IconButton
                //   disabled={disableFields}
                color='primary'
                onClick={handleClickFee}
              >
                <Iconify icon='solar:pen-bold' />
              </IconButton>
            }
          />
        </Grid>
        <Grid item xs={12} sm={6}>
          <AmountField
            variant='standard'
            // disabled={disableFields}
            name='Products'
            label='Products' // TODO REMOVE EDITING POSSIBILITY FOR PRODUCTS, FEE, PAYMENTS
            fullWidth
            readonly
            endAdornment={
              <IconButton
                //   disabled={disableFields}
                color='primary'
                onClick={handleClickProducts}
              >
                <Iconify icon='solar:pen-bold' />
              </IconButton>
            }
          />
        </Grid>
        <Grid item xs={12} sm={6}>
          <AmountField variant='standard' name='stateTax' label='State Tax' fullWidth autoComplete='off' />
        </Grid>
        <Grid item xs={12} sm={6}>
          <AmountField variant='standard' name='cityTax' label='City Tax' fullWidth autoComplete='off' />
        </Grid>
        <Grid item xs={12} sm={6}>
          <AmountField variant='standard' name='countyTax' label='County Tax' fullWidth autoComplete='off' />
        </Grid>
        <Grid item xs={12} sm={6}>
          <AmountField variant='standard' name='miscellaneousTax' label='Miscellaneous Tax' fullWidth autoComplete='off' />
        </Grid>
        <Grid item xs={12} sm={6}>
          <AmountField variant='standard' name='docStamps' label='Doc Stamps' fullWidth autoComplete='off' />
        </Grid>
      </Grid>
      <ProductPopover anchorEl={anchorElProducts} setAnchorEl={setAnchorElProducts} productsForm={productsForm} />
      <FeePopover anchorEl={anchorElFee} setAnchorEl={setAnchorElFee} feesForm={feesForm} />
    </Card>
  )
}

export default ContractItemization
