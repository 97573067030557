import React, { useEffect, useState } from 'react'
import { Form } from 'react-final-form'
import Header from '../components/Header/Header'
import { useSearchRepoTypeMutation } from '../../../services'
import { UspConfigurationRepoTypeViewResult } from '../../../models'
import CollectionsRepoTypesTable from './components/CollectionsRepoTypesTable'
import AddEditRepoTypesModal from './components/AddEditRepoTypesModal'
import { Button, Card, Container, Grid } from '@mui/material'
import CustomBreadcrumbs from '../../../components/custom-breadcrumbs/custom-breadcrumbs'
import Iconify from '../../../components/iconify'

const SettingsCollectionsRepoTypes = () => {
  const [rowData, setRowData] = useState({})
  const [openAddEditModal, setOpenAddEditModal] = useState(false)
  const [type, setType] = useState('')
  const [searchRepoTypes, { data, isLoading }] = useSearchRepoTypeMutation()
  const [repoTypesData, setRepoTypesData] = useState<UspConfigurationRepoTypeViewResult[]>([])

  const handleSubmit = (values?: any) => {
    searchRepoTypes({
      simpleSearch: {
        searchString: values?.searhString,
        isIncludeInactive: values?.isIncludeInactive,
      },
    })
  }

  const handleOpenModal = () => {
    setOpenAddEditModal(true)
    setType('add')
  }

  const handleCloseModal = () => {
    setOpenAddEditModal(false)
  }

  useEffect(() => {
    if (data) {
      setRepoTypesData(data)
    }
  }, [data])

  return (
    <Container>
      <CustomBreadcrumbs
        heading='Repo Types'
        links={[
          {
            name: 'List',
          },
        ]}
        action={
          <Button onClick={() => handleOpenModal()} variant='contained' startIcon={<Iconify icon='mingcute:add-line' />}>
            Add new
          </Button>
        }
        sx={{
          mb: {
            xs: 3,
            md: 5,
          },
        }}
      />
      <Form onSubmit={handleSubmit} initialValues={{ searhString: null, isIncludeInactive: false }}>
        {({ handleSubmit, form: { submit } }: any) => (
          <>
            <form onSubmit={handleSubmit}>
              <Card>
                <Grid container spacing={2}>
                  <Grid item xs={12}>
                    <Header submit={submit} handleOpenModal={handleOpenModal} />
                  </Grid>
                  <Grid item xs={12}>
                    <CollectionsRepoTypesTable
                      setOpenAddEditModal={setOpenAddEditModal}
                      setType={setType}
                      setRowData={setRowData}
                      loading={isLoading}
                      data={repoTypesData}
                      setData={setRepoTypesData}
                    />
                  </Grid>
                </Grid>
              </Card>
            </form>
            <AddEditRepoTypesModal
              submit={submit}
              open={openAddEditModal}
              onClose={handleCloseModal}
              type={type}
              rowData={rowData as UspConfigurationRepoTypeViewResult}
            />
          </>
        )}
      </Form>
    </Container>
  )
}

export default SettingsCollectionsRepoTypes
