import React, { useEffect } from 'react'
import { styled } from '@mui/system'
import { CardHeader, Grid, Typography } from '@mui/material'
import { useSelector } from 'react-redux'
import { TextField, PhoneTextField } from '../../../../../ui'
import { generateUtilityClasses } from '@mui/material'
import { getPhoneTypes } from '../../../../../redux/slices/userDropdownSlice'
import { useForm, useFormState } from 'react-final-form'
import { userLabels } from '../../../../../validation'
import { useParams } from 'react-router-dom'
import DropdownFieldSearch from '../../../../../ui/DropdownFieldSearch'

const classes = generateUtilityClasses('Grid', ['root', 'row', 'optionalText'])

const StyledGrid = styled(Grid)(({ theme }) => ({
  [`&.${classes.root}`]: {},
  [`& .${classes.row}`]: {
    margin: theme.spacing(1, 0, 2, 0),
  },
  [`& .${classes.optionalText}`]: {
    fontFamily: 'Open Sans, sans-serif',
    fontWeight: 400,
    Size: '0.8750em',
    fontStyle: 'italic',
    lineHeight: '1.1875em',
    marginLeft: theme.spacing(1),
  },
}))

interface Props {}

export const ContactForm = ({}: Props) => {
  const { userId } = useParams()
  const { values, touched, initialValues } = useFormState()
  const { change } = useForm()
  const phoneTypes = useSelector(getPhoneTypes)

  useEffect(() => {
    // apply email on username if not toched
    if (!touched?.username && !initialValues?.username) {
      change('username', values?.email)
    }
  }, [values?.email])

  return (
    <>
      <CardHeader title='Contact Info (Optional)' />
      <Grid container spacing={2} px={3} pt={3}>
        <Grid item xs={12} sm={2}>
          <DropdownFieldSearch
            name='PhoneType'
            label='Phone Type'
            options={phoneTypes}
            optionKey='PhoneType'
            optionValue='PhoneType'
            initialValue='Biz'
          />
        </Grid>
        <Grid item xs={12} sm={values?.PhoneType === 'Biz' ? 2 : 4}>
          <PhoneTextField name='PhoneNumber' label='Phone' fullWidth />
        </Grid>
        {values?.PhoneType === 'Biz' && (
          <>
            <Grid item xs={12} sm={2}>
              <TextField name='PhoneExtension' label='Ext' fullWidth />
            </Grid>
          </>
        )}

        <Grid item xs={12} sm={2}>
          <DropdownFieldSearch
            name='SecondaryPhoneType'
            label='Phone Type'
            options={phoneTypes}
            optionKey='PhoneType'
            optionValue='PhoneType'
            initialValue='Cell'
          />
        </Grid>
        <Grid item xs={12} sm={values?.SecondaryPhoneType === 'Biz' ? 2 : 4}>
          <PhoneTextField name='SecondaryPhoneNumber' label='Phone' fullWidth />
        </Grid>
        {values?.SecondaryPhoneType === 'Biz' && (
          <>
            <Grid item xs={12} sm={2}>
              <TextField name='SecondaryPhoneExtension' label='Ext' fullWidth />
            </Grid>
          </>
        )}

        <Grid item xs={12} sm={6}>
          <TextField name='Email' label='Email' fullWidth />
        </Grid>
      </Grid>
    </>
  )
}

export default ContactForm
