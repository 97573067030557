import React, { useEffect, useState } from 'react'
import { Form } from 'react-final-form'
import Header from '../components/Header/Header'
import { useSearchCreditAppExpenseMutation } from '../../../services'
import { UspConfigurationCreditAppExpenseViewResult } from '../../../models'
import LeadsApplicantExpensesTable from './components/LeadsApplicantExpensesTable'
import EditApplicantExpensesModal from './components/EditApplicantExpensesModal'
import { Card, Container, Grid } from '@mui/material'
import CustomBreadcrumbs from '../../../components/custom-breadcrumbs/custom-breadcrumbs'

const SettingsLeadsApplicantExpenses = () => {
  const [rowData, setRowData] = useState({})
  const [openAddEditModal, setOpenAddEditModal] = useState(false)
  const [type, setType] = useState('')
  const [searchCreditAppExpenses, { data, isLoading }] = useSearchCreditAppExpenseMutation()
  const [creditAppExpensesData, setCreditAppExpensesData] = useState<UspConfigurationCreditAppExpenseViewResult[]>([])

  const handleSubmit = (values?: any) => {
    searchCreditAppExpenses({
      simpleSearch: {
        searchString: values?.searhString,
        isIncludeInactive: values?.isIncludeInactive,
      },
    })
  }

  const handleOpenModal = () => {
    setOpenAddEditModal(true)
    setType('add')
  }

  const handleCloseModal = () => {
    setOpenAddEditModal(false)
  }

  useEffect(() => {
    if (data) {
      setCreditAppExpensesData(data)
    }
  }, [data])

  return (
    <Container>
      <CustomBreadcrumbs
        heading='Applicant Expenses'
        links={[
          {
            name: 'List',
          },
        ]}
        // action={
        //   <Button
        //     onClick={() => navigate(appRoute?.VendorAddEdit.replace(':vendorId', 'add'))}
        //     variant='contained'
        //     startIcon={<Iconify icon='mingcute:add-line' />}
        //   >
        //     Add new
        //   </Button>
        // }
        sx={{
          mb: {
            xs: 3,
            md: 5,
          },
        }}
      />
      <Form onSubmit={handleSubmit} initialValues={{ searhString: null, isIncludeInactive: false }}>
        {({ handleSubmit, form: { submit } }: any) => (
          <>
            <form onSubmit={handleSubmit}>
              <Card>
                <Grid container spacing={2}>
                  <Grid item xs={12}>
                    <Header submit={submit} title='' handleOpenModal={handleOpenModal} />
                  </Grid>
                  <Grid item xs={12}>
                    <LeadsApplicantExpensesTable
                      loading={isLoading}
                      data={creditAppExpensesData}
                      setData={setCreditAppExpensesData}
                      setOpenAddEditModal={setOpenAddEditModal}
                      setType={setType}
                      setRowData={setRowData}
                    />
                  </Grid>
                </Grid>
              </Card>
            </form>
            <EditApplicantExpensesModal
              submit={submit}
              open={openAddEditModal}
              onClose={handleCloseModal}
              type={type}
              rowData={rowData as UspConfigurationCreditAppExpenseViewResult}
            />
          </>
        )}
      </Form>
    </Container>
  )
}

export default SettingsLeadsApplicantExpenses
