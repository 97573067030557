import React, { useEffect } from 'react'
import DropdownFieldSearch from '../../../../../ui/DropdownFieldSearch'
import { styled } from '@mui/system'
import { CardHeader, Grid, Typography } from '@mui/material'
import { TextField } from '../../../../../ui'
import { generateUtilityClasses } from '@mui/material'
import { useGetVendorIRSQuery, useGetVendorTypeMutation } from '../../../../../services'
import { useFormState } from 'react-final-form'

const classes = generateUtilityClasses('Grid', ['root', 'row', 'optionalText'])

const StyledGrid = styled(Grid)(({ theme }) => ({
  [`&.${classes.root}`]: {},
  [`& .${classes.row}`]: {
    margin: theme.spacing(1, 0, 2, 0),
  },
  [`& .${classes.optionalText}`]: {
    fontFamily: 'Open Sans, sans-serif',
    fontWeight: 400,
    Size: '0.8750em',
    fontStyle: 'italic',
    lineHeight: '1.1875em',
    marginLeft: theme.spacing(1),
  },
}))

const SettingsForm = () => {
  const { values } = useFormState()
  const { data: vendorIRSData } = useGetVendorIRSQuery({})
  const [getVendorType, { data: vendorTypeData }] = useGetVendorTypeMutation()

  useEffect(() => {
    getVendorType({ currentId: null })
  }, [])

  return (
    <>
      <CardHeader title='Settings' />
      <Grid container spacing={2} px={3} pt={3}>
        <Grid item xs={12} sm={6}>
          <TextField name='ShortCode' label='Short Code' fullWidth />
        </Grid>
        <Grid item xs={12} sm={6}>
          <DropdownFieldSearch
            name='VendorType'
            label='Vendor Type'
            options={vendorTypeData as any}
            optionKey='VendorTypeID'
            optionValue='VendorType'
            required
          />
        </Grid>
        <Grid item xs={12} sm={6}>
          <DropdownFieldSearch
            name='Vendor1099'
            label='Is 1099 Vendor?'
            options={[
              { key: true, value: 'Yes' },
              { key: false, value: 'No' },
            ]}
            fullWidth
          />
        </Grid>
        {values?.Vendor1099 && (
          <Grid item xs={12} sm={6}>
            <DropdownFieldSearch
              name='Type1099'
              label='1099 Type'
              options={vendorIRSData as any}
              optionKey='IRS1099MiscType'
              optionValue='IRS1099MiscType'
              fullWidth
            />
          </Grid>
        )}

        <Grid item xs={12} sm={6}>
          <TextField name='Contact' label='Contact' fullWidth />
        </Grid>
        <Grid item xs={12} sm={6}>
          <TextField name='Website' label='Website' fullWidth />
        </Grid>
        <Grid item xs={12} sm={6}>
          <TextField name='Note' label='Comments' fullWidth />
        </Grid>
        <Grid item xs={12} sm={6}>
          <DropdownFieldSearch
            name='IsActive'
            label='Is Active?'
            options={[
              { key: true, value: 'Yes' },
              { key: false, value: 'No' },
            ]}
            initialValue={true}
          />
        </Grid>
      </Grid>
    </>
  )
}

export default SettingsForm
