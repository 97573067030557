import React from 'react'
import { styled } from '@mui/system'
import { CardHeader, FormControlLabel, Grid, Switch, Typography } from '@mui/material'
import { generateUtilityClasses } from '@mui/material'
import { CheckboxField } from '../../../../../../ui'
import { Field, useFormState } from 'react-final-form'

const classes = generateUtilityClasses('Grid', ['root'])

const StyledGrid = styled(Grid)(() => ({
  [`&.${classes.root}`]: {},
}))

interface Props {}

export const AccountsForm = ({}: Props) => {
  const { values } = useFormState()

  return (
    <>
      <CardHeader title='Accounts' />
      <Grid container spacing={2} padding={3}>
        <Grid item xs={12} sm={4} md={3}>
          <Field name='AccessAccounts'>
            {(props) => (
              <FormControlLabel
                sx={{ width: '100%' }}
                control={
                  <Switch
                    name={props.input.name}
                    checked={props.input.value}
                    onChange={(e) => {
                      props.input.onChange(e.target.checked)
                    }}
                  />
                }
                labelPlacement='end'
                label='Access Accounts'
              />
            )}
          </Field>
        </Grid>
        {values.AccessAccounts && (
          <>
            <Grid item xs={12} sm={4} md={3}>
              <Field name='EditAccounts'>
                {(props) => (
                  <FormControlLabel
                    sx={{ width: '100%' }}
                    control={
                      <Switch
                        name={props.input.name}
                        checked={props.input.value}
                        onChange={(e) => {
                          props.input.onChange(e.target.checked)
                        }}
                      />
                    }
                    labelPlacement='end'
                    label='Edit Accounts'
                  />
                )}
              </Field>
            </Grid>
            <Grid item xs={12} sm={4} md={3}>
              <Field name='AddPayments'>
                {(props) => (
                  <FormControlLabel
                    sx={{ width: '100%' }}
                    control={
                      <Switch
                        name={props.input.name}
                        checked={props.input.value}
                        onChange={(e) => {
                          props.input.onChange(e.target.checked)
                        }}
                      />
                    }
                    labelPlacement='end'
                    label='Add Payments'
                  />
                )}
              </Field>
            </Grid>
            <Grid item xs={12} sm={4} md={3}>
              <Field name='WriteOffPayments'>
                {(props) => (
                  <FormControlLabel
                    sx={{ width: '100%' }}
                    control={
                      <Switch
                        name={props.input.name}
                        checked={props.input.value}
                        onChange={(e) => {
                          props.input.onChange(e.target.checked)
                        }}
                      />
                    }
                    labelPlacement='end'
                    label='Write Off Payments'
                  />
                )}
              </Field>
            </Grid>
            <Grid item xs={12} sm={4} md={3}>
              <Field name='VoidPayments'>
                {(props) => (
                  <FormControlLabel
                    sx={{ width: '100%' }}
                    control={
                      <Switch
                        name={props.input.name}
                        checked={props.input.value}
                        onChange={(e) => {
                          props.input.onChange(e.target.checked)
                        }}
                      />
                    }
                    labelPlacement='end'
                    label='Void Payments'
                  />
                )}
              </Field>
            </Grid>
            <Grid item xs={12} sm={4} md={3}>
              <Field name='ChargebackPayments'>
                {(props) => (
                  <FormControlLabel
                    sx={{ width: '100%' }}
                    control={
                      <Switch
                        name={props.input.name}
                        checked={props.input.value}
                        onChange={(e) => {
                          props.input.onChange(e.target.checked)
                        }}
                      />
                    }
                    labelPlacement='end'
                    label='Chargeback Payments'
                  />
                )}
              </Field>
            </Grid>
            <Grid item xs={12} sm={4} md={3}>
              <Field name='AdjustLateFees'>
                {(props) => (
                  <FormControlLabel
                    sx={{ width: '100%' }}
                    control={
                      <Switch
                        name={props.input.name}
                        checked={props.input.value}
                        onChange={(e) => {
                          props.input.onChange(e.target.checked)
                        }}
                      />
                    }
                    labelPlacement='end'
                    label='Adjust Late Fees'
                  />
                )}
              </Field>
            </Grid>
            <Grid item xs={12} sm={4} md={3}>
              <Field name='WaiveChargebackFees'>
                {(props) => (
                  <FormControlLabel
                    sx={{ width: '100%' }}
                    control={
                      <Switch
                        name={props.input.name}
                        checked={props.input.value}
                        onChange={(e) => {
                          props.input.onChange(e.target.checked)
                        }}
                      />
                    }
                    labelPlacement='end'
                    label='Waive Chargeback Fees'
                  />
                )}
              </Field>
            </Grid>
            <Grid item xs={12} sm={4} md={3}>
              <Field name='AddSideNotes'>
                {(props) => (
                  <FormControlLabel
                    sx={{ width: '100%' }}
                    control={
                      <Switch
                        name={props.input.name}
                        checked={props.input.value}
                        onChange={(e) => {
                          props.input.onChange(e.target.checked)
                        }}
                      />
                    }
                    labelPlacement='end'
                    label='Add Side Notes'
                  />
                )}
              </Field>
            </Grid>
            <Grid item xs={12} sm={4} md={3}>
              <Field name='ReverseSideNotes'>
                {(props) => (
                  <FormControlLabel
                    sx={{ width: '100%' }}
                    control={
                      <Switch
                        name={props.input.name}
                        checked={props.input.value}
                        onChange={(e) => {
                          props.input.onChange(e.target.checked)
                        }}
                      />
                    }
                    labelPlacement='end'
                    label='Reverse Side Notes'
                  />
                )}
              </Field>
            </Grid>
            <Grid item xs={12} sm={4} md={3}>
              <Field name='CancelPTP'>
                {(props) => (
                  <FormControlLabel
                    sx={{ width: '100%' }}
                    control={
                      <Switch
                        name={props.input.name}
                        checked={props.input.value}
                        onChange={(e) => {
                          props.input.onChange(e.target.checked)
                        }}
                      />
                    }
                    labelPlacement='end'
                    label='Cancel PTP'
                  />
                )}
              </Field>
            </Grid>
            <Grid item xs={12} sm={4} md={3}>
              <Field name='EditAccountTracking'>
                {(props) => (
                  <FormControlLabel
                    sx={{ width: '100%' }}
                    control={
                      <Switch
                        name={props.input.name}
                        checked={props.input.value}
                        onChange={(e) => {
                          props.input.onChange(e.target.checked)
                        }}
                      />
                    }
                    labelPlacement='end'
                    label='Edit Account Tracking'
                  />
                )}
              </Field>
            </Grid>
            <Grid item xs={12} sm={4} md={3}>
              <Field name='ProcessChargeOff'>
                {(props) => (
                  <FormControlLabel
                    sx={{ width: '100%' }}
                    control={
                      <Switch
                        name={props.input.name}
                        checked={props.input.value}
                        onChange={(e) => {
                          props.input.onChange(e.target.checked)
                        }}
                      />
                    }
                    labelPlacement='end'
                    label='Process Charge Off'
                  />
                )}
              </Field>
            </Grid>
            <Grid item xs={12} sm={4} md={3}>
              <Field name='ReverseChargeOff'>
                {(props) => (
                  <FormControlLabel
                    sx={{ width: '100%' }}
                    control={
                      <Switch
                        name={props.input.name}
                        checked={props.input.value}
                        onChange={(e) => {
                          props.input.onChange(e.target.checked)
                        }}
                      />
                    }
                    labelPlacement='end'
                    label='Reverse Charge Off'
                  />
                )}
              </Field>
            </Grid>
            <Grid item xs={12} sm={4} md={3}>
              <Field name='ProcessPrincipalOnlyPayment'>
                {(props) => (
                  <FormControlLabel
                    sx={{ width: '100%' }}
                    control={
                      <Switch
                        name={props.input.name}
                        checked={props.input.value}
                        onChange={(e) => {
                          props.input.onChange(e.target.checked)
                        }}
                      />
                    }
                    labelPlacement='end'
                    label='Principal Only Payments'
                  />
                )}
              </Field>
            </Grid>
          </>
        )}
      </Grid>
    </>
  )
}

export default AccountsForm
