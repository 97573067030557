import React from 'react'
import { styled } from '@mui/system'
import { CardHeader, Grid, Typography } from '@mui/material'
import { TextField, DateField } from '../../../../../ui'
import { generateUtilityClasses } from '@mui/material'
import AmountField from '../../../../../ui/AmountField'
import DropdownFieldSearch from '../../../../../ui/DropdownFieldSearch'

const classes = generateUtilityClasses('Grid', ['root', 'row', 'optionalText'])

const StyledGrid = styled(Grid)(({ theme }) => ({
  [`&.${classes.root}`]: {},
  [`& .${classes.row}`]: {
    margin: theme.spacing(1, 0, 2, 0),
  },
  [`& .${classes.optionalText}`]: {
    fontFamily: 'Open Sans, sans-serif',
    fontWeight: 400,
    Size: '0.8750em',
    fontStyle: 'italic',
    lineHeight: '1.1875em',
    marginLeft: theme.spacing(1),
  },
}))

interface Props {}
export const SettingsForm = ({}: Props) => {
  return (
    <>
      <CardHeader title='Settings' />
      <Grid container spacing={2} padding={3}>
        <Grid item xs={12} sm={6}>
          <TextField name='PolicyNumber' label='Policy Number' fullWidth autoComplete='off' />
        </Grid>
        <Grid item xs={12} sm={6}>
          <TextField name='CoverageType' label='Coverage' fullWidth autoComplete='off' />
        </Grid>
        <Grid item xs={12} sm={3}>
          <DateField name='EffectiveDate' label='Effective Date' fullWidth disableFuture />
        </Grid>
        <Grid item xs={12} sm={3}>
          <DateField name='ExpirationDate' label='Expiration Date' fullWidth />
        </Grid>
        <Grid item xs={12} sm={3}>
          <AmountField variant='standard' name='ComprehensiveDeductible' label='Comprehensive Deductible' fullWidth />
        </Grid>
        <Grid item xs={12} sm={3}>
          <AmountField variant='standard' name='CollisionDeductible' label='Collision Deductible' fullWidth />
        </Grid>

        <Grid item xs={12} sm={3}>
          <DropdownFieldSearch
            name='IsCPI'
            label='Is CPI?'
            options={[
              { key: true, value: 'Yes' },
              { key: false, value: 'No' },
            ]}
            initialValue={false}
            optionKey='key'
            optionValue='value'
            autoComplete='off'
          />
        </Grid>
        <Grid item xs={12} sm={9}>
          <TextField multiline name='Note' label='Comments' fullWidth autoComplete='off' />
        </Grid>
      </Grid>
    </>
  )
}

export default SettingsForm
