import React, { useEffect } from 'react'
import FeePopover from '../../../../Deals/DealsAddEdit/subpages/DealStructure/components/FeePopover'
import { Card, CardHeader, FormControlLabel, Grid, IconButton, Switch } from '@mui/material'
import { AmountField, DateField, TextField } from '../../../../../ui'
import Iconify from '../../../../../components/iconify'
import ProductPopover from '../../../../Deals/DealsAddEdit/subpages/DealStructure/components/ProductPopover'
import { Field, useForm, useFormState } from 'react-final-form'
import DropdownFieldSearch from '../../../../../ui/DropdownFieldSearch'
import { useSelector } from 'react-redux'
import { getStates } from '../../../../../redux/slices/userDropdownSlice'
import removeMask from '../../../../../utils/removeMask'

function Contract() {
  const { values } = useFormState()
  const { change } = useForm()
  const states = useSelector(getStates)

  useEffect(() => {
    if (
      removeMask(values.amountFinanced) &&
      removeMask(values.financeCharges) &&
      removeMask(values.paymentAmount) &&
      Number(removeMask(values.paymentAmount)) > 0
    )
      change(
        'term',
        Math.floor(
          (Number(removeMask(values.amountFinanced)) + Number(removeMask(values.financeCharges))) / Number(removeMask(values.paymentAmount)),
        ).toString(),
      )
    else {
      change('term', '0')
    }

    if (
      removeMask(values.amountFinanced) &&
      removeMask(values.financeCharges) &&
      removeMask(values.paymentAmount) &&
      removeMask(values.paymentAmount) &&
      removeMask(values.term)
    ) {
      change(
        'finalPaymentAmount',
        (
          Number(removeMask(values.amountFinanced)) +
          Number(removeMask(values.financeCharges)) -
          Number(removeMask(values.term)) * Number(removeMask(values.paymentAmount))
        )
          .toFixed(2)
          .toString(),
      )
    } else {
      change('finalPaymentAmount', '0.00')
    }
  }, [values])

  return (
    <Card sx={{ height: '100%' }}>
      <CardHeader title='Contract' />
      <Grid container spacing={3} sx={{ p: 3 }}>
        <Grid item xs={12} sm={6}>
          <DateField name='contractDate' label='Contract Date' fullWidth autoComplete='off' />
        </Grid>
        <Grid item xs={12} sm={6}>
          <DateField name='firstDueDate' label='First Due Date' fullWidth autoComplete='off' />
        </Grid>
        <Grid item xs={12} sm={6}>
          <AmountField variant='standard' name='amountFinanced' label='Amount Financed' fullWidth autoComplete='off' />
        </Grid>
        <Grid item xs={12} sm={6}>
          <AmountField variant='standard' name='financeCharges' label='Finance Charges' fullWidth autoComplete='off' />
        </Grid>
        <Grid item xs={12} sm={6}>
          <DropdownFieldSearch
            name='paymentSchedule'
            label='Payment Schedule'
            options={[
              { key: 'W', value: 'Weekly' },
              { key: 'B', value: 'Bi-Weekly' },
              { key: 'S', value: 'Semi-Monthly' },
              { key: 'M', value: 'Monthly' },
            ]}
          />
        </Grid>
        <Grid item xs={12} sm={6}>
          <AmountField variant='standard' name='paymentAmount' label='Payment Amount' fullWidth autoComplete='off' />
        </Grid>
        <Grid item xs={12} sm={6}>
          <TextField disabled name='term' label='Term' fullWidth autoComplete='off' />
        </Grid>
        <Grid item xs={12} sm={6}>
          <AmountField disabled variant='standard' name='finalPaymentAmount' label='Final  Payment Amount' fullWidth autoComplete='off' />
        </Grid>
        <Grid item xs={12} sm={3}>
          <DropdownFieldSearch name='state' label='State' options={states} optionKey='State' optionValue='State' autoComplete='off' />
        </Grid>
        <Grid item xs={12} sm={3}>
          <DropdownFieldSearch
            name='interestMethod'
            label='Interest Method'
            options={[
              { label: 'IB', value: 'IB' },
              { label: 'PC', value: 'PC' },
            ]}
            optionKey='value'
            optionValue='value'
            autoComplete='off'
          />
        </Grid>
        <Grid item xs={12} sm={6}>
          <TextField name='apr' label='APR (%)' fullWidth autoComplete='off' />
        </Grid>
        <Grid item xs={12} sm={6}>
          <Field name='midStream'>
            {(props) => (
              <div>
                <FormControlLabel
                  control={
                    <Switch
                      name={props.input.name}
                      checked={props.input.value}
                      onChange={(e) => {
                        props.input.onChange(e.target.checked)
                      }}
                    />
                  }
                  label='Mid-Stream Loan'
                />
              </div>
            )}
          </Field>
        </Grid>
        {values.midStream && (
          <>
            <Grid item xs={12} sm={6}>
              <DateField name='lastPaidDate' label='Last Paid Date' fullWidth autoComplete='off' />
            </Grid>
            <Grid item xs={12} sm={6}>
              <DateField name='nextDueDate' label='Next Due Date' fullWidth autoComplete='off' />
            </Grid>
            <Grid item xs={12} sm={6}>
              {values?.interestMethod == 'IB' ? (
                <AmountField variant='standard' name='principalBalance' label='Principal Balance' fullWidth autoComplete='off' />
              ) : (
                <AmountField variant='standard' name='balanceRemaining' label='Gross Balance' fullWidth autoComplete='off' />
              )}
            </Grid>
            <Grid item xs={12} sm={6}>
              <AmountField variant='standard' name='interestPaid' label='Interest Paid' fullWidth autoComplete='off' />
            </Grid>
            <Grid item xs={12} sm={6}>
              <AmountField variant='standard' name='paidTowardsAdvance' label='Paid Towards Advance' fullWidth autoComplete='off' />
            </Grid>
          </>
        )}
      </Grid>
    </Card>
  )
}

export default Contract
