import React, { useEffect, useMemo, useState } from 'react'
import {
  Box,
  Button,
  Card,
  CardHeader,
  Dialog,
  DialogContent,
  DialogTitle,
  generateUtilityClasses,
  Grid,
  IconButton,
  MenuItem,
  Stack,
  Typography,
  useTheme,
} from '@mui/material'
import { styled, useMediaQuery } from '@mui/system'
import { Form } from 'react-final-form'
import {
  AccountsDealResult,
  AccountsOverviewResult,
  UspCollectionWorklistGetNextAccountResult,
  UspDealFlagViewResult,
  UspGetDealProfitDataResult,
} from '../../../models'
import { withAccounts } from '../../../hoc/withAccounts'
import EditIcon from '@mui/icons-material/Edit'
import {
  useAddDealsFlagMutation,
  useDeleteDealCommissionMutation,
  useDeleteDealReferralMutation,
  useGetDealAmortizationMutation,
  useLazyGetAccountDealQuery,
  useLazyGetDealCommissionQuery,
  useLazyGetDealProductQuery,
  useLazyGetDealProfitDataQuery,
  useLazyGetDealReferralsQuery,
  useLazyGetDealTradeQuery,
  useWorklistNextAccountMutation,
} from '../../../services'
import { generatePath, useLocation, useNavigate, useParams } from 'react-router-dom'
import { CustomCircular } from '../../../ui'
import BackupTableIcon from '@mui/icons-material/BackupTable'
import { getWorklistAccountID, getWorklistID } from '../../../redux/slices/collectionsSlice'
import { useDispatch, useSelector } from 'react-redux'
import { getUserAccessRights } from '../../../redux/slices/authSlice'
import { enqueueNotification } from '../../../redux/slices/notificationsSlice'
import SubMenu from '../AccountsDeals/components/SubMenu/SubMenu'
import TabStructure from '../AccountsDeals/tabs/TabStructure/TabStructure'
import TabCommissions from '../AccountsDeals/tabs/TabCommissions/TabCommissions'
import TabReferrals from '../AccountsDeals/tabs/TabReferrals/TabReferrals'
import TabAmortization from '../AccountsDeals/tabs/TabAmortization/TabAmortization'
import TabSettings from '../AccountsDeals/tabs/TabSettings/TabSettings'
import TabTrades from '../AccountsDeals/tabs/TabTrades/TabTrades'
import TabProducts from '../AccountsDeals/tabs/TabProducts/TabProducts'
import { appRoute } from '../../../constants'
import BuyerItem from '../../Leads/LeadsList/components/buyer-item'
import Iconify from '../../../components/iconify'
import { formatNumber } from '../../../utils/general'
import { usePopover } from '../../../components/custom-popover'
import CustomPopover from '../../../components/custom-popover/custom-popover'
import CommissionItem from '../../Deals/DealsTask/components/commission-item'
import EditCommission from '../AccountsDeals/components/EditCommission/EditCommission'
import ConfirmDialog from '../../../components/ConfirmDialog'
import DealsCommissionsAddEdit from '../../Deals/DealsCommissions/components/DealsCommissionsAddEdit/DealsCommissionsAddEdit'
import ReferralItem from '../../Deals/DealsTask/components/referral-item'
import { convertDateToDisplay } from '../../../utils/dateUtils'

const classes = generateUtilityClasses('Grid', ['root', 'pageBody', 'printBtn'])

const StyledGrid = styled(Grid)(({ theme }) => ({
  [`&.${classes.root}`]: {
    display: 'flex',
    flexDirection: 'column',
    height: '100vh',
  },
  [`& .${classes.pageBody}`]: {
    padding: theme.spacing(3, 3, 3, 3),
    display: 'flex',
    alignItems: 'flexStart',
    justifyContent: 'justify-start',
  },
  [`& .${classes.printBtn}`]: {
    marginLeft: theme.spacing(2),
  },
}))

interface Props {
  initialTab?: 'structure' | 'trades' | 'products' | 'commissions' | 'referral' | 'amortization' | 'settings'
  accountsData: AccountsOverviewResult
}

const AccountsDeals = ({ initialTab, accountsData }: Props) => {
  const { dealID } = useParams()
  const { state } = useLocation()

  const theme = useTheme()
  const isMobile = useMediaQuery(theme.breakpoints.down('sm'))
  const navigate = useNavigate()
  const dispatch = useDispatch()
  const worklistAccountID = useSelector(getWorklistAccountID)
  const worklistID = useSelector(getWorklistID)
  const userAccess = useSelector(getUserAccessRights)
  const disableEditIcons = userAccess?.CanEditAccount
  const [tabValue, setTabValue] = React.useState(state?.tabValue || initialTab || 'structure')
  const [selectedDeal, setSelectedDeal] = React.useState<any>(null)
  const [openSettingsModal, setOpenSettingsModal] = useState(false)
  const [commissionDeleteModal, setCommissionDeleteModal] = useState(false)
  const [errorsInTabs, setErrorsInTabs] = React.useState<{
    structure: boolean
    trades: boolean
    products: boolean
    commissions: boolean
    referral: boolean
    amortization: boolean
    settings: boolean
  }>({
    structure: false,
    trades: false,
    products: false,
    commissions: false,
    referral: false,
    amortization: false,
    settings: false,
  })
  const [getDealAmortization, { data: dealAmortizationData, isLoading: getDealAmortizationIsLoading }] = useGetDealAmortizationMutation()
  const [getDealReferral, { data: dealReferralData, isLoading: getDealReferralIsLoading }] = useLazyGetDealReferralsQuery()
  const [getDealCommission, { data: dealCommissionData, isLoading: getDealCommissionIsLoading }] = useLazyGetDealCommissionQuery()
  const [getDealProduct, { data: dealProductData, isLoading: getDealProductIsLoading }] = useLazyGetDealProductQuery()
  const [getDealTrade, { data: dealTradeData, isLoading: getDealTradeIsLoading }] = useLazyGetDealTradeQuery()
  const [getDealData, { data, isLoading: getDealDataIsLoading }] = useLazyGetAccountDealQuery()
  const [getDealProfitData, { data: dealProfitData, isLoading: dealProfitIsLoading }] = useLazyGetDealProfitDataQuery()
  const [nextAccount, { data: nextAccountData, isSuccess: nextAccountIsSuccess }] = useWorklistNextAccountMutation()
  const [deleteCommissions, { isSuccess: deleteCommissionsIsSuccess }] = useDeleteDealCommissionMutation()
  const [deleteDealReferal, { isSuccess }] = useDeleteDealReferralMutation()
  const [referralDeleteModal, setReferralDeleteModal] = useState(false)
  const [settingsModal, setSettingsModal] = useState(false)
  const [anchorEl, setAnchorEl] = useState<HTMLButtonElement | EventTarget | HTMLLIElement | null>(null)
  const [selectedReferral, setSelectedReferral] = useState<any>()
  const [selectedCommission, setSelectedCommission] = useState<any>()
  const comissionPopover = usePopover()
  const referralPopover = usePopover()
  const dealPopover = usePopover()

  useEffect(() => {
    getDealData({ dealId: dealID as string })
    getDealProfitData({ dealId: dealID as string })
    getDealTrade({ dealId: dealID as string })
    getDealProduct({ dealId: dealID as string })
    getDealCommission({ dealId: dealID as string })
    getDealReferral({ dealId: dealID as string })
    getDealAmortization({
      dealId: dealID as string,
      uspDealAmortization: {
        isInsertIntoTable: false,
        isIncludePickups: false,
        isDueDatesOnly: false,
        closedByPaymentID: null,
        isExtensionReversal: false,
      },
    })
  }, [])

  const handleNextAccount = () => {
    if (worklistID && worklistAccountID) {
      nextAccount({
        worklistId: worklistID.toString(),
        uspCollectionWorklistGetNextAccount: {
          worklistAccountID: worklistAccountID,
          isNext: true,
        },
      })
    }
  }

  const handlePreviousAccount = () => {
    if (worklistID && worklistAccountID) {
      nextAccount({
        worklistId: worklistID.toString(),
        uspCollectionWorklistGetNextAccount: {
          worklistAccountID: worklistAccountID,
          isNext: false,
        },
      })
    }
  }

  const handleReferralDelete = () => {
    if (selectedReferral) {
      setReferralDeleteModal(false)
      deleteDealReferal({
        referralId: selectedReferral?.ReferralID,
        dealId: dealID as string,
      })
    }
  }

  const handleComissionDelete = () => {
    if (selectedCommission?.CommissionID) {
      deleteCommissions({ dealId: dealID as string, commissionId: selectedCommission?.CommissionID.toString() })
      setCommissionDeleteModal(false)
    }
  }

  const onSuccessCommissionDeal = () => {
    dispatch(enqueueNotification({ message: 'Commission added successfully.' }))
  }

  const onSuccessEditCommissionDeal = () => {
    dispatch(enqueueNotification({ message: 'Commission edited successfully.' }))
  }

  useEffect(() => {
    // after referral or trade edit redirect to previous tab and fetch data
    if (state?.tabValue === 'referral') {
      setTabValue('referral')
      getDealReferral({ dealId: dealID as string })
      navigate('.', { replace: true })
    }
    if (state?.tabValue === 'trades') {
      setTabValue('trades')
      getDealTrade({ dealId: dealID as string })
      navigate('.', { replace: true })
    }
  }, [navigate])

  useEffect(() => {
    if (tabValue === 'structure') {
      getDealData({ dealId: dealID as string })
      getDealProfitData({ dealId: dealID as string })
    }
    if (tabValue === 'settings') {
      getDealData({ dealId: dealID as string })
    }
  }, [tabValue])

  const handleSubmit = () => {}

  const isLoading = useMemo(
    () => getDealDataIsLoading || dealProfitIsLoading || getDealTradeIsLoading,
    [getDealDataIsLoading, dealProfitIsLoading, getDealTradeIsLoading],
  )

  const settingsCardData = useMemo(
    () => [
      { title: 'Status', value: data?.DealStatus },
      { title: 'Advertisement', value: data?.Advertisement },
      { title: 'Mileage', value: data?.DeliveryMileage },
      { title: 'Late Grace Days', value: data?.LateGraceDays },
      { title: 'Late Charge Rate', value: data?.LateChargeRate },
      { title: 'Late Charge Max', value: data?.LateChargeMax },
      { title: 'Late Charge Min', value: data?.LateChargeMin },
      { title: 'Stop Accruing Late Fees', value: data?.IsStopAccruingLateCharge },
      { title: 'Stop Accruing Late Fee As Of Date', value: data?.StopAccruingLateChargeDate },
      { title: 'Chargeback Fee', value: data?.ChargebackFee },
      { title: 'Interest Days Per Year', value: data?.DaysPerYear },
      { title: 'Stop Accruing Interest', value: data?.IsStopAccruingInterest },
      { title: 'Stop Accruing Interest Date', value: convertDateToDisplay(data?.StopAccruingInterestDate) },
    ],
    [data],
  )

  return (
    <>
      <Grid container spacing={3}>
        <Grid item xs={12}>
          <Form onSubmit={handleSubmit}>
            {({ handleSubmit }: any) => (
              <Grid container item onSubmit={handleSubmit} spacing={2}>
                <Grid container item xs={12} md={8} direction={'column'} spacing={2}>
                  <Grid item>
                    <TabStructure
                      data={data as AccountsDealResult}
                      dealProfitData={dealProfitData as UspGetDealProfitDataResult}
                      setErrorsInTabs={setErrorsInTabs}
                    />
                  </Grid>

                  {(dealTradeData as unknown as any[])?.length > 0 && (
                    <Grid item>
                      <Card>
                        <CardHeader
                          title='Trades'
                          // action={
                          //   <Button
                          //     size='small'
                          //     color='primary'
                          //     onClick={() => {
                          //       navigate(
                          //         appRoute.LeadsReferenceAddEdit.replace(':leadID', `${leadID}`)
                          //           .replace(':contactID', `${data?.ContactID1}`)
                          //           .replace(':referenceID', 'add'),
                          //       )
                          //     }}
                          //     startIcon={<Iconify icon='mingcute:add-line' />}
                          //   >
                          //     Flag
                          //   </Button>
                          // }
                        />
                        <Stack spacing={2.5} sx={{ p: 3 }}>
                          {(dealTradeData as unknown as any[])?.map((item) => (
                            <BuyerItem
                              key={item.FlagID}
                              variant='outlined'
                              name={item?.Vehicle}
                              stock={item?.StockNumber}
                              VIN={item?.VIN}
                              ACV={formatNumber(item?.ACV)}
                              Allowance={formatNumber(item?.Allowance)}
                              LienAmount={formatNumber(item?.LienAmount)}
                              Lienholder={formatNumber(item?.Lienholder)}
                              action={
                                <IconButton
                                  onClick={(event: React.MouseEvent<HTMLElement>) => {
                                    console.log(item)
                                    setSelectedDeal(item)
                                    dealPopover.onOpen(event)
                                  }}
                                  sx={{ position: 'absolute', top: 8, right: 8 }}
                                >
                                  <Iconify icon='eva:more-vertical-fill' />
                                </IconButton>
                              }
                              sx={{
                                p: 2.5,
                                borderRadius: 1,
                              }}
                            />
                          ))}
                        </Stack>
                      </Card>
                    </Grid>
                  )}
                  <Grid item>
                    <Card>
                      <CardHeader
                        title='Commissions'
                        action={
                          <Button
                            size='small'
                            color='primary'
                            onClick={(e) => {
                              setSelectedCommission(undefined)
                              setAnchorEl(e.currentTarget)
                            }}
                            startIcon={<Iconify icon='mingcute:add-line' />}
                          >
                            {isMobile ? '' : 'Commission'}
                          </Button>
                        }
                      />
                      <Stack spacing={2.5} sx={{ p: 3 }}>
                        {dealCommissionData?.map((commission) => (
                          <CommissionItem
                            key={commission.CommissionID}
                            variant='outlined'
                            name={commission.Salesperson}
                            AddedBy={commission.Username}
                            Amount={commission.CommissionAmount}
                            action={
                              <IconButton
                                onClick={(event: React.MouseEvent<HTMLElement>) => {
                                  setSelectedCommission(commission)
                                  comissionPopover.onOpen(event)
                                }}
                                sx={{ position: 'absolute', top: 8, right: 8 }}
                              >
                                <Iconify icon='eva:more-vertical-fill' />
                              </IconButton>
                            }
                            sx={{
                              p: 2.5,
                              borderRadius: 1,
                            }}
                          />
                        ))}
                      </Stack>
                    </Card>
                  </Grid>
                  <Grid item>
                    <Card>
                      <CardHeader
                        title='Referrals'
                        action={
                          <Button
                            size='small'
                            color='primary'
                            onClick={(e) => {
                              navigate(generatePath(appRoute.AccountsReferralEdit, { dealID, referral: 'add' }))
                            }}
                            startIcon={<Iconify icon='mingcute:add-line' />}
                          >
                            {isMobile ? '' : 'Referral'}
                          </Button>
                        }
                      />
                      <Stack spacing={2.5} sx={{ p: 3 }}>
                        {dealReferralData?.map((referral) => (
                          <ReferralItem
                            key={referral.ReferralID}
                            variant='outlined'
                            name={referral.Name}
                            AddedBy={referral.AddedBy}
                            Amount={referral.ReferralFee}
                            action={
                              <IconButton
                                onClick={(event: React.MouseEvent<HTMLElement>) => {
                                  setSelectedReferral(referral)
                                  referralPopover.onOpen(event)
                                }}
                                sx={{ position: 'absolute', top: 8, right: 8 }}
                              >
                                <Iconify icon='eva:more-vertical-fill' />
                              </IconButton>
                            }
                            sx={{
                              p: 2.5,
                              borderRadius: 1,
                            }}
                          />
                        ))}
                      </Stack>
                    </Card>
                  </Grid>
                </Grid>

                <Grid container item xs={12} md={4} direction={'column'} spacing={2}>
                  <Grid item>
                    <Card sx={{ height: '100%' }}>
                      <CardHeader
                        title='Deal Settings'
                        action={
                          <IconButton onClick={() => setSettingsModal(true)}>
                            <Iconify icon='solar:pen-bold' />
                          </IconButton>
                        }
                      />
                      <Box sx={{ p: 3 }} display={'flex'} flexDirection={'column'}>
                        {settingsCardData.map(
                          (el) =>
                            el?.value && (
                              <Box display={'flex'} alignItems={'baseline'}>
                                <Typography sx={{ py: 1 }} variant='body2'>
                                  {el?.title} :
                                </Typography>

                                {typeof el?.value === 'string' || 'number' ? (
                                  <Typography sx={{ ml: 1, py: 1 }} variant='body2'>
                                    {el?.value}
                                  </Typography>
                                ) : (
                                  el?.value
                                )}
                              </Box>
                            ),
                        )}
                      </Box>
                    </Card>
                  </Grid>
                </Grid>
              </Grid>
            )}
          </Form>
          <CustomCircular open={isLoading} />
        </Grid>
      </Grid>
      <DealsCommissionsAddEdit
        rowData={selectedCommission}
        anchorEl={anchorEl}
        setAnchorEl={setAnchorEl}
        type={selectedCommission ? 'edit' : 'add'}
        onSuccessCommissionDeal={onSuccessCommissionDeal}
        onSuccessEditCommissionDeal={onSuccessEditCommissionDeal}
      />
      <ConfirmDialog
        open={commissionDeleteModal}
        onOk={handleComissionDelete}
        handleClose={() => setCommissionDeleteModal(false)}
        notification={'Commission will be removed.'}
      />
      <ConfirmDialog
        open={referralDeleteModal}
        onOk={handleReferralDelete}
        handleClose={() => setReferralDeleteModal(false)}
        notification={'Remove referral from deal?'}
      />
      <CustomPopover open={dealPopover.open} onClose={dealPopover.onClose} arrow='top-right' sx={{ width: 140 }}>
        <MenuItem
          onClick={() => {
            if (selectedDeal && selectedDeal?.TradeID)
              navigate(appRoute.AccountsTradeEdit.replace(':dealID', String(dealID)).replace(':tradeID', String(selectedDeal.TradeID)))
          }}
        >
          <Iconify icon='solar:pen-bold' />
          Edit
        </MenuItem>
      </CustomPopover>
      <CustomPopover open={comissionPopover.open} onClose={comissionPopover.onClose} arrow='top-right' sx={{ width: 140 }}>
        <MenuItem
          onClick={(e) => {
            setAnchorEl(e.currentTarget)
            comissionPopover.onClose()
          }}
        >
          <Iconify icon='solar:pen-bold' />
          Edit
        </MenuItem>
        <MenuItem
          onClick={() => {
            setCommissionDeleteModal(true)
            comissionPopover.onClose()
          }}
          sx={{ color: 'error.main' }}
        >
          <Iconify icon='solar:trash-bin-trash-bold' />
          Delete
        </MenuItem>
      </CustomPopover>
      <CustomPopover open={referralPopover.open} onClose={referralPopover.onClose} arrow='top-right' sx={{ width: 140 }}>
        <MenuItem
          onClick={(e) => {
            // navigate(
            //   generatePath(appRoute.DealsReferralAddEdit, {
            //     dealID,
            //     referral: selectedReferral?.ReferralID,
            //   }),
            navigate(generatePath(appRoute.AccountsReferralEdit, { dealID, referral: selectedReferral?.ReferralID }))
          }}
        >
          <Iconify icon='solar:pen-bold' />
          Edit
        </MenuItem>
        <MenuItem
          onClick={() => {
            setReferralDeleteModal(true)
            referralPopover.onClose()
          }}
          sx={{ color: 'error.main' }}
        >
          <Iconify icon='solar:trash-bin-trash-bold' />
          Delete
        </MenuItem>
      </CustomPopover>
      <Dialog open={settingsModal} onClose={() => setSettingsModal(false)}>
        <DialogTitle>Settings</DialogTitle>
        <DialogContent>
          <TabSettings onClose={() => setSettingsModal(false)} data={data as AccountsDealResult} getDealData={getDealData} />
        </DialogContent>
      </Dialog>
    </>
  )
}

export default withAccounts(AccountsDeals)
