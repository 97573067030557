import React, { Dispatch, SetStateAction, useEffect, useState } from 'react'
import {
  Box,
  Grid,
  IconButton,
  MenuItem,
  Paper,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  TableSortLabel,
  Tooltip,
  Typography,
  generateUtilityClasses,
} from '@mui/material'
import { styled } from '@mui/system'
import EditIcon from '@mui/icons-material/Edit'
import DeleteIcon from '@mui/icons-material/Delete'
import { useDispatch } from 'react-redux'
import { visuallyHidden } from '@mui/utils'
import { UspConfigurationInsuranceViewResult } from '../../../../../models'
import { storageGet, storageSet } from '../../../../../utils/storage'
import { enqueueNotification } from '../../../../../redux/slices/notificationsSlice'
import { getComparator, stableSort } from '../../../../../utils/sorting'
import { CustomCircular } from '../../../../../ui'
import ConfirmDialog from '../../../../../components/ConfirmDialog'
import { useDeleteInsuranceMutation } from '../../../../../services'
import { appRoute } from '../../../../../constants'
import { useNavigate } from 'react-router-dom'
import { usePopover } from '../../../../../components/custom-popover'
import Iconify from '../../../../../components/iconify'
import CustomPopover from '../../../../../components/custom-popover/custom-popover'
import Label from '../../../../../components/label/label'

const classes = generateUtilityClasses('Grid', ['root', 'activeItem', 'loading', 'tableHead', 'tableCellName', 'inactiveItem', 'lastColumn'])

const StyledGrid = styled(Grid)(({ theme }) => ({
  [`&.${classes.root}`]: {
    display: 'flex',
    justifyContent: 'end',
    alignItems: 'center',
    margin: theme.spacing(3, 3, 3, 3),
  },
  [`& .${classes.activeItem}`]: {
    padding: theme.spacing(0.5, 0, 0, 0),
    height: '22px',
    fontSize: '0.8em',
    fontWeight: 500,
    color: '#FFFFFF',
    textAlign: 'center',
    backgroundColor: theme.palette.success.main,
    borderRadius: '4px',
  },
  [`& .${classes.inactiveItem}`]: {
    padding: theme.spacing(0.5, 0, 0, 0),
    height: '22px',
    fontSize: '0.8em',
    fontWeight: 500,
    color: '#FFFFFF',
    textAlign: 'center',
    backgroundColor: theme.palette.error.light,
    borderRadius: '4px',
  },
  [`& .${classes.lastColumn}`]: {
    fontWeight: 600,
    background: theme.palette?.background?.main,
  },
  [`& .${classes.tableHead}`]: {
    fontWeight: 600,
    background: theme.palette?.background?.main,
  },
  [`& .${classes.tableCellName}`]: {
    cursor: 'pointer',
    color: theme.palette.primary.main,
  },
  [`& .${classes.loading}`]: {
    verticalAlign: 'top',
  },
}))

interface Props {
  data: UspConfigurationInsuranceViewResult[]
  loading: boolean
  setData: Dispatch<SetStateAction<UspConfigurationInsuranceViewResult[]>>
}
const InsuranceTable = ({ data, loading, setData }: Props) => {
  const [deleteInsurance, { isSuccess: deleteIsSuccess }] = useDeleteInsuranceMutation()
  const initialOrder = (storageGet('insurance_order') || 'asc') as 'asc' | 'desc'
  const initialOrderBy = (storageGet('insurance_order_by') || 'Name') as keyof UspConfigurationInsuranceViewResult
  const [order, setOrder] = useState<'asc' | 'desc'>(initialOrder)
  const [orderBy, setOrderBy] = useState<keyof UspConfigurationInsuranceViewResult>(initialOrderBy)
  const [deleteConfirmDialog, setDeleteConfirmDialog] = useState<{ insuranceId: string } | undefined>(undefined)
  const dispatch = useDispatch()
  const navigate = useNavigate()
  const [selected, setSelected] = useState<any>(null)
  const popover = usePopover()

  const onRequestSort = (event: React.MouseEvent<unknown>, property: keyof UspConfigurationInsuranceViewResult) => {
    const isAsc = orderBy === property && order === 'asc'
    setOrder(isAsc ? 'desc' : 'asc')
    setOrderBy(property)
    storageSet('insurance_order', isAsc ? 'desc' : 'asc')
    storageSet('insurance_order_by', property)
  }

  const createSortHandler = (property: keyof UspConfigurationInsuranceViewResult) => (event: React.MouseEvent<unknown>) => {
    onRequestSort(event, property)
  }

  const handleDelete = async (): Promise<void> => {
    if (deleteConfirmDialog?.insuranceId) {
      await deleteInsurance(deleteConfirmDialog)
    }
    setDeleteConfirmDialog(undefined)
  }

  useEffect(() => {
    if (deleteConfirmDialog?.insuranceId) {
      if (deleteIsSuccess) {
        let updatedData: UspConfigurationInsuranceViewResult[]
        updatedData = data.map((item) => (item.ID?.toString() === deleteConfirmDialog.insuranceId ? { ...item, IsActive: false } : item))
        setData(updatedData)
        dispatch(enqueueNotification({ message: 'Success' }))
      }
    }
  }, [deleteIsSuccess])

  return (
    <>
      <TableContainer sx={{ overflow: 'auto', maxHeight: 'calc(100vh - 118px)' }}>
        <Table sx={{ minWidth: 650 }} aria-label='simple table' stickyHeader>
          <TableHead>
            <TableRow>
              <TableCell className={classes.tableHead} sortDirection={orderBy === 'Name' ? order : false}>
                <TableSortLabel active={orderBy === 'Name'} direction={orderBy === 'Name' ? order : 'asc'} onClick={createSortHandler('Name')}>
                  Name
                  {orderBy === 'Name' ? (
                    <Box component='span' sx={visuallyHidden}>
                      {order === 'desc' ? 'sorted descending' : 'sorted ascending'}
                    </Box>
                  ) : null}
                </TableSortLabel>
              </TableCell>
              <TableCell className={classes.tableHead} sortDirection={orderBy === 'InsuranceAgent' ? order : false}>
                <TableSortLabel
                  active={orderBy === 'InsuranceAgent'}
                  direction={orderBy === 'InsuranceAgent' ? order : 'asc'}
                  onClick={createSortHandler('InsuranceAgent')}
                >
                  Agent
                  {orderBy === 'InsuranceAgent' ? (
                    <Box component='span' sx={visuallyHidden}>
                      {order === 'desc' ? 'sorted descending' : 'sorted ascending'}
                    </Box>
                  ) : null}
                </TableSortLabel>
              </TableCell>

              <TableCell className={classes.lastColumn} sortDirection={orderBy === 'PhoneNumber' ? order : false}>
                <TableSortLabel
                  active={orderBy === 'PhoneNumber'}
                  direction={orderBy === 'PhoneNumber' ? order : 'asc'}
                  onClick={createSortHandler('PhoneNumber')}
                >
                  Phone Number
                  {orderBy === 'PhoneNumber' ? (
                    <Box component='span' sx={visuallyHidden}>
                      {order === 'desc' ? 'sorted descending' : 'sorted ascending'}
                    </Box>
                  ) : null}
                </TableSortLabel>
              </TableCell>

              <TableCell className={classes.lastColumn} sortDirection={orderBy === 'IsCPI' ? order : false}>
                <TableSortLabel active={orderBy === 'IsCPI'} direction={orderBy === 'IsCPI' ? order : 'asc'} onClick={createSortHandler('IsCPI')}>
                  CPI
                  {orderBy === 'IsCPI' ? (
                    <Box component='span' sx={visuallyHidden}>
                      {order === 'desc' ? 'sorted descending' : 'sorted ascending'}
                    </Box>
                  ) : null}
                </TableSortLabel>
              </TableCell>

              <TableCell className={classes.lastColumn} sortDirection={orderBy === 'IsActive' ? order : false}>
                <TableSortLabel
                  active={orderBy === 'IsActive'}
                  direction={orderBy === 'IsActive' ? order : 'asc'}
                  onClick={createSortHandler('IsActive')}
                >
                  Status
                  {orderBy === 'IsActive' ? (
                    <Box component='span' sx={visuallyHidden}>
                      {order === 'desc' ? 'sorted descending' : 'sorted ascending'}
                    </Box>
                  ) : null}
                </TableSortLabel>
              </TableCell>

              <TableCell className={classes.lastColumn} align={'right'}>
                Action
              </TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {stableSort(getComparator(order, orderBy), data as any)?.map((item) => (
              <TableRow key={item.ID} sx={{ '&:last-child td, &:last-child th': { border: 0 } }}>
                <TableCell
                  className={classes.tableCellName}
                  onClick={() => {
                    navigate(appRoute.SettingsDealsInsuranceAddEdit.replace(':insuranceId', item?.ID?.toString() as string))
                  }}
                >
                  {item.Name}
                </TableCell>
                <TableCell>{item?.InsuranceAgent}</TableCell>
                <TableCell>{item?.PhoneNumber}</TableCell>
                <TableCell>{item?.IsCPI ? 'Yes' : 'No'}</TableCell>
                <TableCell>
                  <Label color={item.IsActive ? 'success' : 'error'}>{item.IsActive ? 'Active' : 'Inactive'}</Label>
                </TableCell>
                <TableCell align={'right'}>
                  <IconButton
                    onClick={(event: React.MouseEvent<HTMLElement>) => {
                      setSelected(item)
                      popover.onOpen(event)
                    }}
                  >
                    <Iconify icon='eva:more-vertical-fill' />
                  </IconButton>
                </TableCell>
              </TableRow>
            ))}
            {data?.length === 0 && (
              <TableRow>
                <TableCell colSpan={10} align='center'>
                  No results
                </TableCell>
              </TableRow>
            )}
          </TableBody>
        </Table>
      </TableContainer>
      <CustomCircular open={loading} />
      <ConfirmDialog
        open={!!deleteConfirmDialog}
        onOk={handleDelete}
        handleClose={() => setDeleteConfirmDialog(undefined)}
        notification={'Are you sure you wish to delete?'}
      />

      <CustomPopover open={popover.open} onClose={popover.onClose} arrow='top-right' sx={{ width: 140 }}>
        <MenuItem
          onClick={(e) => {
            navigate(appRoute.SettingsDealsInsuranceAddEdit.replace(':insuranceId', selected?.ID?.toString() as string))
            popover.onClose()
          }}
        >
          <Iconify icon='solar:pen-bold' />
          Edit
        </MenuItem>
        <MenuItem
          disabled={selected?.IsActive ? false : true}
          onClick={() => {
            setDeleteConfirmDialog({ insuranceId: selected.ID?.toString() as string })
            popover.onClose()
          }}
          sx={{ color: 'error.main' }}
        >
          <Iconify icon='solar:trash-bin-trash-bold' />
          Delete
        </MenuItem>
      </CustomPopover>
    </>
  )
}

export default InsuranceTable
