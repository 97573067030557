import React from 'react'
import { styled } from '@mui/system'
import { CardHeader, Grid, Typography } from '@mui/material'
import { FEINTextField, DateField, SSNTextField, TextField } from '../../../../../ui'
import { generateUtilityClasses } from '@mui/material'
import { useFormState } from 'react-final-form'
import { useSelector } from 'react-redux'
import { getStates } from '../../../../../redux/slices/userDropdownSlice'
import DropdownFieldSearch from '../../../../../ui/DropdownFieldSearch'

const classes = generateUtilityClasses('Grid', ['root', 'row', 'optionalText'])

const StyledGrid = styled(Grid)(({ theme }) => ({
  [`&.${classes.root}`]: {},
  [`& .${classes.row}`]: {
    margin: theme.spacing(1, 0, 2, 0),
  },
  [`& .${classes.optionalText}`]: {
    fontFamily: 'Open Sans, sans-serif',
    fontWeight: 400,
    Size: '0.8750em',
    fontStyle: 'italic',
    lineHeight: '1.1875em',
    marginLeft: theme.spacing(1),
  },
}))

interface Props {
  onDropdownFormChange: () => void
  disableFields: boolean
  searchConractData: any
  setValue: any
}

export const IdentityForm = ({ onDropdownFormChange, disableFields, searchConractData, setValue }: Props) => {
  const { values } = useFormState()
  const states = useSelector(getStates)

  return (
    <>
      <CardHeader title='Identity (optional)' />
      <Grid container spacing={2} padding={3}>
        {values?.ApplicantAccountType === 'individual' ? (
          <>
            <Grid item xs={12} sm={6}>
              <DateField disabled={disableFields} name='ApplicantDateOfBirth' label='Date Of Birth' disableFuture fullWidth />
            </Grid>
            <Grid item xs={12} sm={6}>
              <SSNTextField data={searchConractData} setValue={setValue} disabled={disableFields} name='ApplicantTaxIDNumber' label='SSN' fullWidth />
            </Grid>
          </>
        ) : (
          <Grid item xs={12} sm={6}>
            <FEINTextField data={searchConractData} setValue={setValue} disabled={disableFields} name='ApplicantTaxIDNumber' label='FEIN' fullWidth />
          </Grid>
        )}
        <Grid item xs={12} sm={4}>
          {/* TODO No Backend value*/}
          <TextField disabled={disableFields} name='ApplicantLicenseNumber' label='License Number' fullWidth autoComplete='off' /> {/*dlState*/}
        </Grid>
        <Grid item xs={12} sm={2}>
          <DropdownFieldSearch // {/*dlState*/}
            disabled={disableFields}
            name='ApplicantLicenseState'
            label='State'
            options={states}
            optionKey='State'
            optionValue='State'
            autoComplete='off'
            onChange={() => {
              onDropdownFormChange()
            }}
          />
        </Grid>
        <Grid item xs={12} sm={3}>
          {/* TODO No Backend value*/}
          <DateField
            disabled={disableFields}
            onDropdownFormChange={onDropdownFormChange}
            name='ApplicantLicenseIssued'
            label='License Issued'
            disableFuture
            fullWidth
          />
        </Grid>
        <Grid item xs={12} sm={3}>
          {/* TODO No Backend value*/}
          <DateField
            disabled={disableFields}
            onDropdownFormChange={onDropdownFormChange}
            name='ApplicantLicenseExpiration'
            label='License Expiration'
            fullWidth
          />
        </Grid>
      </Grid>
    </>
  )
}

export default IdentityForm
