export const transformToRequestValues = (values?: any) => ({
  userRole: values?.RoleName,
  isActive: values?.IsActive || false,
  canAccessConfigurationSettings: values?.AccessConfigurationSettings || false,
  canAccessDealers: values?.AccessConfigurationSettings ? values?.AccessDealers : false,
  canAccessLenders: values?.AccessConfigurationSettings ? values?.AccessLenders : false,
  canAccessVendors: values?.AccessConfigurationSettings ? values?.AccessVendors : false,
  canAccessUsers: values?.AccessConfigurationSettings ? values?.AccessUsers : false,

  canAccessTools: values?.AccessTools || false,
  canViewActiveLogins: values?.ViewActiveLogins || false,
  canLogOffUsers: values?.LogOffUsers || false,
  canAccessCar: values?.AccessInventory || false,
  canAddCar: values?.AccessInventory ? values?.AddInventory : false,
  canDeleteCar: values?.AccessInventory ? values?.DeleteInventory : false,
  canEditCar: values?.AccessInventory ? values?.EditInventory : false,
  canViewCarCost: values?.AccessInventory ? values?.ViewInventoryCost : false,
  canViewCarPurchasePrice: values?.AccessInventory ? values?.ViewPurchasePrice : false,
  canAccessCreditApp: values?.AccessCreditApps || false,
  canAddCreditApp: values?.AccessCreditApps ? values?.AddCreditApps : false,
  canDeleteCreditApp: values?.AccessCreditApps ? values?.DeleteCreditApps : false,
  canEditCreditApp: values?.AccessCreditApps ? values?.EditCreditApps : false,
  canPullCreditReport: values?.AccessCreditApps ? values?.PullCredit : false,
  canAccessDeal: values?.AccessDeals || false,
  canAddDeal: values?.AccessDeals ? values?.AddDeals : false,
  canDeleteDeal: values?.AccessDeals ? values?.DeleteDeals : false,
  canEditDeal: values?.AccessDeals ? values?.EditDeals : false,
  canFinalizeDeal: values?.AccessDeals ? values?.FinalizeDeals : false,
  canUnwindDeal: values?.AccessDeals ? values?.UnwindDeals : false,
  canAccessAccount: values?.AccessAccounts || false,
  canEditAccount: values?.AccessAccounts ? values?.EditAccounts : false,
  canAddPayment: values?.AccessAccounts ? values?.AddPayments : false,
  canWriteOffPayment: values?.AccessAccounts ? values?.WriteOffPayments : false,
  canVoidPayment: values?.AccessAccounts ? values?.VoidPayments : false,
  canChargebackPayment: values?.AccessAccounts ? values?.ChargebackPayments : false,
  canAdjustLateFee: values?.AccessAccounts ? values?.AdjustLateFees : false,
  canWaiveChargebackFee: values?.AccessAccounts ? values?.WaiveChargebackFees : false,
  canAddSideNote: values?.AccessAccounts ? values?.AddSideNotes : false,
  canReverseSideNote: values?.AccessAccounts ? values?.ReverseSideNotes : false,
  canCancelPTP: values?.AccessAccounts ? values?.CancelPTP : false,
  canEditAccountTracking: values?.AccessAccounts ? values?.EditAccountTracking : false,
  canProcessChargeOff: values?.AccessAccounts ? values?.ProcessChargeOff : false,
  canReverseChargeOff: values?.AccessAccounts ? values?.ReverseChargeOff : false,
  canProcessPrincipalOnlyPayment: values?.AccessAccounts ? values?.ProcessPrincipalOnlyPayment : false,
  canAccessAccounting: values?.AccessAccounting || false,
  canAddBankDeposit: values?.AccessAccounting ? values?.AddBankDeposits : false,
  canAddJournalEntry: values?.AccessAccounting ? values?.AddJournalEntry : false,
  canAddLedgerOnlyJournalEntry: values?.AccessAccounting ? values?.AddLedgerOnlyJournalEntry : false,
  canAddBill: values?.AccessAccounting ? values?.AddBills : false,
  canDeleteBill: values?.AccessAccounting ? values?.DeleteBills : false,
  isDefaultBillAsApprovedToPay: values?.AccessAccounting ? values?.NewBillsareApprovedToPay : false,
  canApproveBillToPay: values?.AccessAccounting ? values?.CanApproveBillsforPayment : false,
  canIssueChecks: values?.AccessAccounting ? values?.IssueChecks : false,
  canPayBills: values?.AccessAccounting ? values?.PayBills : false,
  canPayUnapprovedBills: values?.AccessAccounting ? values?.PayUnapprovedBills : false,
  canVoidChecks: values?.AccessAccounting ? values?.VoidChecks : false,
  canAccessChartOfAccounts: values?.AccessAccounting ? values?.AccessChartofAccounts : false,
  canChooseRestrictedChartOfAccounts: values?.AccessAccounting ? values?.ChooseRestrictedChartofAccounts : false,
  canAccessReconciliations: values?.AccessAccounting ? values?.AccessReconciliations : false,
  canAccessFinancials: values?.AccessAccounting ? values?.AccessFinancials : false,
  canClosePeriod: values?.AccessAccounting ? values?.ClosePeriods : false,
  canAccessInsights: values?.AccessInsights || false,
  canAccessInventoryInsights: values?.AccessInsights ? values?.InventoryInsights : false,
  canAccessSalesInsights: values?.AccessInsights ? values?.SalesInsights : false,
  canAccessCollectionsInsights: values?.AccessInsights ? values?.CollectionsInsights : false,
  canAccessAccountInsights: values?.AccessInsights ? values?.AccountInsights : false,
  canAccessPaymentInsights: values?.AccessInsights ? values?.PaymentInsights : false,
  canAccessManagementInsights: values?.AccessInsights ? values?.ManagementInsights : false,
  canAccessAccountingInsights: values?.AccessInsights ? values?.AccountingInsights : false,
  canAccessCollections: values?.AccessCollections || false,
  canDeleteAccount: values?.EditConfigurationSettings || false,
  canBuildWorklist: values?.BuildWorklist || false,
  canClearFlashMessage: values?.ClearFlashMessage || false,
  canAccessPayment: values?.AccessPayments || false,
  canReversePayment: values?.ReversePayments || false,
  canDeleteSideNote: values?.DeleteSideNotes || false,
  canAccessAccountStatusSettings: values?.AccessAccounts || false,
  canEditAccountStatusSettings: values?.EditAccounts || false,
  isSalesperson: values?.IncludeinSalespersonDropdowns || false,
  isCollector: values?.IncludeinCollectorDropdowns || false,
  isFiManager: values?.IncludeinFIManagerDropdowns || false,
  isManager: values?.IncludeinManagerDropdowns || false,
})

export const transformToFormValues = (data?: any) => {
  return {
    RoleName: data?.UserRole,
    IsActive: data?.IsActive,
    AccessConfigurationSettings: data?.CanAccessConfigurationSettings,
    AccessDealers: data?.CanAccessDealers,
    AccessLenders: data?.CanAccessLenders,
    AccessVendors: data?.CanAccessVendors,
    AccessUsers: data?.CanAccessUsers,
    ViewActiveLogins: data?.CanViewActiveLogins,
    LogOffUsers: data?.CanLogOffUsers,
    AccessInventory: data?.CanAccessCar,
    AddInventory: data?.CanAddCar,
    DeleteInventory: data?.CanDeleteCar,
    EditInventory: data?.CanEditCar,
    ViewInventoryCost: data?.CanViewCarCost,
    ViewPurchasePrice: data?.CanViewCarPurchasePrice,
    AccessCreditApps: data?.CanAccessCreditApp,
    AddCreditApps: data?.CanAddCreditApp,
    DeleteCreditApps: data?.CanDeleteCreditApp,
    EditCreditApps: data?.CanEditCreditApp,
    PullCredit: data?.CanPullCreditReport,
    AccessDeals: data?.CanAccessDeal,
    AddDeals: data?.CanAddDeal,
    DeleteDeals: data?.CanDeleteDeal,
    EditDeals: data?.CanEditDeal,
    FinalizeDeals: data?.CanFinalizeDeal,
    UnwindDeals: data?.CanUnwindDeal,
    AccessAccounts: data?.CanAccessAccount,
    EditAccounts: data?.CanEditAccount,
    AddPayments: data?.CanAddPayment,
    WriteOffPayments: data?.CanWriteOffPayment,
    VoidPayments: data?.CanVoidPayment,
    ChargebackPayments: data?.CanChargebackPayment,
    AdjustLateFees: data?.CanAdjustLateFee,
    WaiveChargebackFees: data?.CanWaiveChargebackFee,
    AddSideNotes: data?.CanAddSideNote,
    ReverseSideNotes: data?.CanReverseSideNote,
    CancelPTP: data?.CanCancelPTP,
    EditAccountTracking: data?.CanEditAccountTracking,
    ProcessChargeOff: data?.CanProcessChargeOff,
    ReverseChargeOff: data?.CanReverseChargeOff,
    AccessCollections: data?.CanAccessCollections,
    AccessAccounting: data?.CanAccessAccounting,
    AddBankDeposits: data?.CanAddBankDeposit,
    AddJournalEntry: data?.CanAddJournalEntry,
    AddLedgerOnlyJournalEntry: data?.CanAddLedgerOnlyJournalEntry,
    AddBills: data?.CanAddBill,
    DeleteBills: data?.CanDeleteBill,
    NewBillsareApprovedToPay: data?.IsDefaultBillAsApprovedToPay,
    CanApproveBillsforPayment: data?.CanApproveBillToPay,
    IssueChecks: data?.CanIssueChecks,
    PayBills: data?.CanPayBills,
    PayUnapprovedBills: data?.CanPayUnapprovedBills,
    VoidChecks: data?.CanVoidChecks,
    AccessChartofAccounts: data?.CanAccessChartOfAccounts,
    ChooseRestrictedChartofAccounts: data?.CanChooseRestrictedChartOfAccounts,
    AccessReconciliations: data?.CanAccessReconciliations,
    AccessFinancials: data?.CanAccessFinancials,
    ClosePeriods: data?.CanClosePeriod,
    AccessInsights: data?.CanAccessInsights,
    InventoryInsights: data?.CanAccessInventoryInsights,
    SalesInsights: data?.CanAccessSalesInsights,
    CollectionsInsights: data?.CanAccessCollectionsInsights,
    AccountInsights: data?.CanAccessAccountInsights,
    PaymentInsights: data?.CanAccessPaymentInsights,
    ManagementInsights: data?.CanAccessManagementInsights,
    AccountingInsights: data?.CanAccessAccountingInsights,
    IncludeinSalespersonDropdowns: data?.IsSalesperson,
    IncludeinFIManagerDropdowns: data?.IsFiManager,
    IncludeinCollectorDropdowns: data?.IsCollector,
    IncludeinManagerDropdowns: data?.IsManager,
    AccessTools: data?.CanAccessTools,
  }
}
