import React from 'react'
import { styled } from '@mui/system'
import { Grid, Typography } from '@mui/material'
import { generateUtilityClasses } from '@mui/material'
import { TextField } from '../../../../../../ui'
import DropdownFieldSearch from '../../../../../../ui/DropdownFieldSearch'
import { AddVendorListsApiResponse, UserPermissionsDropdownResult } from '../../../../../../models'
import { useFormState } from 'react-final-form'

const classes = generateUtilityClasses('Grid', ['root', 'row'])

const StyledGrid = styled(Grid)(({ theme }) => ({
  [`&.${classes.root}`]: {},
  [`& .${classes.row}`]: {
    margin: theme.spacing(1, 0, 2, 0),
  },
}))

interface Props {
  vendorListData: AddVendorListsApiResponse
  userPermissionsDropdownData: UserPermissionsDropdownResult
  onDropdownFormChange: () => void
}

export const SettingsTwoForm = ({ vendorListData, userPermissionsDropdownData, onDropdownFormChange }: Props) => {
  const { values } = useFormState()
  const salesBranchData = userPermissionsDropdownData?.PermissionsList?.Sales?.Branches
  return (
    <Grid container spacing={2} px={3} pt={3}>
      <Grid item xs={12} sm={6}>
        <TextField name='AccountNumber' label='Account Number' fullWidth />
      </Grid>
      <Grid item xs={12} sm={6}>
        <DropdownFieldSearch
          name='Vendor'
          label='Vendor'
          options={vendorListData as any}
          optionKey='ID'
          optionValue='Name'
          autoComplete='off'
          onChange={() => {
            onDropdownFormChange()
          }}
        />
      </Grid>
      <Grid item xs={12} sm={6}>
        <DropdownFieldSearch
          name='IsRelated'
          label='Is Related Dealer?'
          options={[
            { key: true, value: 'Yes' },
            { key: false, value: 'No' },
          ]}
          onChange={() => {
            onDropdownFormChange()
          }}
        />
      </Grid>
      {values?.IsRelated && (
        <Grid item xs={12} sm={6}>
          <DropdownFieldSearch
            name='SalesBranch'
            label='Sales Branch'
            options={salesBranchData}
            optionKey='BranchID'
            optionValue='BranchCode'
            onChange={() => {
              onDropdownFormChange()
            }}
          />
        </Grid>
      )}
    </Grid>
  )
}

export default SettingsTwoForm
