import React, { useEffect, useState, useMemo, Dispatch, SetStateAction } from 'react'
import validateForm from '../../../utils/validateForm'
import TabPanel from '@mui/lab/TabPanel'
import TabContext from '@mui/lab/TabContext'
import Grid from '@mui/material/Grid'
import Button from '@mui/material/Button'
import { addLeadsValidation } from '../../../validation'
import { styled } from '@mui/material/styles'
import { CustomCircular } from '../../../ui'
import { Form } from 'react-final-form'
import { Header } from './components'
import { withPermissionsDropdown } from '../../../hoc'
import { Buyer, Employment } from './tabs'
import { Box, Card, Container, Tab, Tabs, generateUtilityClasses } from '@mui/material'
import { useNavigate, useParams } from 'react-router-dom'
import { appRoute } from '../../../constants'
import { useAddContactFullMutation, useEditContactFullMutation, useGetContactMutation, useLazyGetTaskDealQuery } from '../../../services'
import { UspContactFullAddEdit, UspGetDataContact } from '../../../models'
import { transformToFormValues, transformToRequestValues } from './transform'
import { enqueueNotification } from '../../../redux/slices/notificationsSlice'
import { useDispatch } from 'react-redux'
import CustomBreadcrumbs from '../../../components/custom-breadcrumbs/custom-breadcrumbs'
import Iconify from '../../../components/iconify'

const classes = generateUtilityClasses('form', ['root', 'pageBody', 'fieldsWrap', 'formItem', 'formItemFullHeight', 'buttonWrap', 'saveBtn'])

const StyledForm = styled('form')(({ theme }) => ({
  [`&.${classes.root}`]: {
    display: 'flex',
    flexDirection: 'column',
    minHeight: '100vh',
  },
  [`& .${classes.pageBody}`]: {
    padding: theme.spacing(2),
  },
  [`& .${classes.formItemFullHeight}`]: {
    minHeight: '712px',
    padding: theme.spacing(2, 3, 1, 3),
    borderRadius: 0,
  },
  [`& .${classes.formItem}`]: {
    minHeight: '340px',
    padding: theme.spacing(2, 3, 1, 3),
    margin: theme.spacing(0),
    borderRadius: 0,
  },
  [`& .${classes.buttonWrap}`]: {
    display: 'flex',
    justifyContent: 'flex-start',
    flexDirection: 'row-reverse',
    padding: theme.spacing(2, 2, 2, 2),
    marginTop: 'auto',
  },
  [`& .${classes.saveBtn}`]: {
    marginLeft: theme.spacing(2),
  },
}))

interface Props {
  initialTab?: 'applicant' | 'employment'
  contactID?: any
  setShowAddBuyer: Dispatch<SetStateAction<any>>
  setContactID: Dispatch<SetStateAction<any>>
}

export const LoanAddEditBuyer = ({ initialTab, contactID = 'add', setShowAddBuyer, setContactID }: Props) => {
  const [initFormValues, setInitFormValues] = useState<any>({
    ApplicantPhoneType: 'Cell',
    ApplicantAccountType: 'individual',
    ApplicantSecondaryPhoneType: 'Home',
    EmploymentCurrentPhoneType: 'Biz',
    EmploymentPreviousPhoneType: 'Biz',
    HistoryHasHadBankruptcy: false,
    HistoryHasHadRepossession: false,
    EmploymentPreviousIsSalaried: false,
    EmploymentCurrentIsSalaried: false,
  })
  const [tabValue, setTabValue] = React.useState(initialTab || 'applicant')
  const [errorsInTabs, setErrorsInTabs] = React.useState<{ applicant: boolean; employment: boolean }>({
    applicant: false,
    employment: false,
  })

  const { leadID, dealID, contactType } = useParams()
  const dispatch = useDispatch()
  const navigate = useNavigate()
  const [getTaskDeal, { data: taskDeal }] = useLazyGetTaskDealQuery()

  const handleTabChange = (event: React.SyntheticEvent, newValue: 'applicant' | 'employment') => {
    setTabValue(newValue)
  }
  const [addContactFull, { data: addContactFullData, isSuccess: addContactFullIsSuccess, isLoading: addContactFullIsLoading }] =
    useAddContactFullMutation()
  const [editContactFull, { data: editContactFullData, isSuccess: editContactFullIsSuccess, isLoading: editContactFullIsLoading }] =
    useEditContactFullMutation()
  const [getContact, { data: searchContactData, isSuccess: getContactIsSuccess, isLoading: getContactIsLoading }] = useGetContactMutation()
  const TABS = [
    {
      value: 'applicant',
      label: 'Applicant',
      icon: <Iconify icon='solar:user-id-bold' width={24} />,
    },
    {
      value: 'employment',
      label: 'Employment',
      icon: <Iconify icon='solar:bell-bing-bold' width={24} />,
    },
  ]
  const handleSubmit = (values: any) => {
    if (contactID === 'add') {
      addContactFull({
        uspContactFullAddEdit: transformToRequestValues(values, leadID, Number(dealID), contactType, true) as UspContactFullAddEdit,
      })
    } else {
      editContactFull({
        contactId: contactID as string,
        uspContactFullAddEdit: transformToRequestValues(values, leadID, Number(dealID), contactType, true) as UspContactFullAddEdit,
      })
    }
  }

  useEffect(() => {
    if (contactID !== 'add') {
      getContact({ contactId: contactID as string })
    }
  }, [contactID])

  useEffect(() => {
    if (getContactIsSuccess) {
      setInitFormValues(transformToFormValues(searchContactData))
    }
  }, [getContactIsSuccess])

  useEffect(() => {
    if (addContactFullIsSuccess && addContactFullData) {
      dispatch(enqueueNotification({ message: 'Added Successfully' }))
      setContactID(addContactFullData.FullResponse.ContactID)
      setShowAddBuyer(false)

      //   navigate(appRoute.DealsTask.replace(':dealID', dealID as string))
    }
  }, [addContactFullIsSuccess])

  useEffect(() => {
    if (editContactFullIsSuccess && editContactFullData) {
      getContact({ contactId: contactID as string })
      dispatch(enqueueNotification({ message: 'Updated Successfully' }))
      setContactID(editContactFullData.FullResponse.ContactID)
      setShowAddBuyer(false)

      //   navigate(appRoute.DealsTask.replace(':dealID', dealID as string))
    }
  }, [editContactFullIsSuccess])

  const isLoading = useMemo(
    () => addContactFullIsLoading || getContactIsLoading || editContactFullIsLoading,
    [addContactFullIsLoading, getContactIsLoading, editContactFullIsLoading],
  )

  return (
    <Container>
      <Grid container>
        <Form onSubmit={handleSubmit} initialValues={initFormValues} validate={validateForm(addLeadsValidation)}>
          {({ handleSubmit, form }: any) => (
            <form onSubmit={handleSubmit}>
              {/* <Header
                  handleTabChange={handleTabChange}
                  tabValue={tabValue}
                  errorsInTabs={errorsInTabs}
                  taskDeal={taskDeal}
                  headerData={searchContactData as unknown as UspGetDataContact}
                /> */}
              <Box display={'flex'}>
                <CustomBreadcrumbs
                  heading='Deal'
                  links={[
                    { name: 'List', href: '/deals' },
                    {
                      name: searchContactData
                        ? `${(searchContactData as unknown as UspGetDataContact)?.DisplayName} | Cell: ${
                            (searchContactData as unknown as UspGetDataContact)?.ContactPhoneData[0]?.PhoneNumber
                          } | ${
                            (searchContactData as unknown as UspGetDataContact)?.Email
                              ? (searchContactData as unknown as UspGetDataContact)?.Email + ' |'
                              : ''
                          } `
                        : '',
                    },
                  ]}
                  sx={{
                    mb: { xs: 3, md: 5 },
                  }}
                />
                {/* <Label color={creditAppData?.LeadStatusColor as LabelColor} sx={{ ml: 2, alignSelf: 'center' }}>
                    {creditAppData?.LeadStatus}
                  </Label> */}
              </Box>
              <Grid item xs={12}>
                <Tabs
                  sx={{
                    mb: { xs: 3, md: 5 },
                  }}
                  value={tabValue}
                  onChange={(e, newValue) => {
                    handleTabChange(e, newValue)
                  }}
                >
                  {TABS.map((tab) => (
                    <Tab key={tab.value} label={tab.label} icon={tab.icon} value={tab.value} />
                  ))}
                </Tabs>
              </Grid>
              <Grid item xs={12} md={10}>
                <Box sx={{ overflow: 'visible', display: 'flex', flexDirection: 'column', alignItems: 'flex-end', height: '100%' }}>
                  {tabValue == 'applicant' && (
                    <Card>
                      <Buyer setErrorsInTabs={setErrorsInTabs} />
                    </Card>
                  )}
                  {tabValue == 'employment' && <Employment setErrorsInTabs={setErrorsInTabs} />}
                  <Box
                    sx={{
                      display: 'flex',
                      flexDirection: 'row',
                      gap: 2,
                      position: 'sticky',
                      bottom: 16,
                      right: 20,
                      p: 3,
                      width: 'fit-content',
                    }}
                  >
                    <Button variant='contained' color='error' onClick={() => setShowAddBuyer(null)}>
                      Cancel
                    </Button>
                    <Button type='submit' variant='contained'>
                      Save & Close
                    </Button>
                  </Box>
                </Box>
              </Grid>
            </form>
          )}
        </Form>
        <CustomCircular open={isLoading} />
      </Grid>
    </Container>
  )
}

export default LoanAddEditBuyer
