import React from 'react'
import { generateUtilityClasses, Grid, Typography } from '@mui/material'
import DropdownFieldSearch from '../../../../../ui/DropdownFieldSearch'
import DateField from '../../../../../ui/DateField'
import { styled } from '@mui/system'
import { TextField } from '../../../../../ui'
import AmountField from '../../../../../ui/AmountField'
import { useSelector } from 'react-redux'
import { selectDropdownByNameActiveOnly } from '../../../../../services'

const classes = generateUtilityClasses('Grid', ['root', 'row', 'optionalText'])

const StyledGrid = styled(Grid)(({ theme }) => ({
  [`&.${classes.root}`]: {},
  [`& .${classes.row}`]: {
    margin: theme.spacing(1, 0, 2, 0),
  },
}))
interface Props {
  onDropdownFormChange: () => void
  disableFields: boolean
}
const RepoForm = ({ onDropdownFormChange, disableFields }: Props) => {
  const vendor = useSelector((state) => selectDropdownByNameActiveOnly(state, 'Vendor'))
  const repoType = useSelector((state) => selectDropdownByNameActiveOnly(state, 'RepoType'))
  const users = useSelector((state) => selectDropdownByNameActiveOnly(state, 'UserAll'))

  return (
    <Grid container item xs={12} spacing={2} p={3}>
      <Grid item xs={12} sm={6}>
        <DropdownFieldSearch
          disabled={disableFields}
          name='IsOutForRepo'
          label='Is Out For Repo?'
          options={[
            { key: true, value: 'Yes' },
            { key: false, value: 'No' },
          ]}
          autoComplete='off'
          onChange={() => {
            onDropdownFormChange()
          }}
        />
      </Grid>
      <Grid item xs={12} sm={6}>
        <DateField disabled={disableFields} onDropdownFormChange={onDropdownFormChange} name='OutForRepoDate' label='Out For Repo Date' fullWidth />
      </Grid>
      {vendor && (
        <Grid item xs={12} sm={6}>
          <DropdownFieldSearch
            disabled={disableFields}
            name='RepoVendor'
            label='Repo Vendor'
            options={vendor}
            optionKey='ID'
            optionValue='Name'
            autoComplete='off'
            onChange={() => {
              onDropdownFormChange()
            }}
          />
        </Grid>
      )}

      <Grid item xs={12} sm={6}>
        <TextField disabled={disableFields} name='BillNumber' label='Bill Number' fullWidth />
      </Grid>
      <Grid item xs={12} sm={6}>
        <AmountField variant='standard' disabled={disableFields} name='RepossessionCost' label='Repossession Cost' fullWidth />
      </Grid>
      {repoType && (
        <Grid item xs={12} sm={6}>
          <DropdownFieldSearch
            disabled={disableFields}
            name='RepoType'
            label='Repo Type'
            options={repoType}
            optionKey='ID'
            optionValue='RepoType'
            autoComplete='off'
            onChange={() => {
              onDropdownFormChange()
            }}
          />
        </Grid>
      )}

      {users && (
        <Grid item xs={12} sm={6}>
          <DropdownFieldSearch
            disabled={disableFields}
            name='RepoOrderedBy'
            label='Repo Ordered By'
            options={users}
            optionKey='UserID'
            optionValue='UserCode'
            autoComplete='off'
            onChange={() => {
              onDropdownFormChange()
            }}
          />
        </Grid>
      )}
    </Grid>
  )
}

export default RepoForm
