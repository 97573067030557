import Typography from '@mui/material/Typography'
import Paper, { PaperProps } from '@mui/material/Paper'
import Stack, { StackProps } from '@mui/material/Stack'
import Label, { LabelColor } from '../../../../components/label'
import { fDate } from '../../../../utils/format-time'
import { convertDateTimeToDisplay, convertDateToDisplay } from '../../../../utils/dateUtils'
import { Button, IconButton, MenuItem, Popover, Tooltip, useTheme } from '@mui/material'
import { ReactComponent as PinIcon } from '../../../../assets/icons/pin_icon.svg'
import { useFollowupCompleteMutation, useNotePinMutation } from '../../../../services'
import { useState } from 'react'
import Iconify from '../../../../components/iconify'
import { GridMoreVertIcon } from '@mui/x-data-grid'
import { formatNumber } from '../../../../utils/general'

// ----------------------------------------------------------------------
type Props = PaperProps &
  StackProps & {
    NoteID: string | number
    name?: string
    note?: string
    phoneNumber?: string
    SentToEmails?: string
    SentByEmail?: string
    Outcome?: string
    IsPTP?: boolean
    IsPtpSatisfied?: boolean
    IsFollowUpCompleted?: boolean
    IsInbound?: boolean
    NoteType?: string
    Subject?: string
    date?: string
    user?: string
    IsFollowUp?: boolean
    PtpDueDate?: string | null
    PtpAmount?: string
    FollowUpAssignedTo?: string
    FollowUpID?: string
    FollowUpDueDateTime?: string
    IsPinned?: boolean
    action?: React.ReactNode
    setRefresh: React.Dispatch<React.SetStateAction<boolean>>
  }

type StatusMapping = {
  Completed: LabelColor
}

export default function NotesItem({
  NoteID,
  Subject,
  IsPTP,
  IsPtpSatisfied,
  IsFollowUpCompleted,
  IsFollowUp,
  PtpDueDate,
  PtpAmount,
  FollowUpAssignedTo,
  FollowUpDueDateTime,
  NoteType,
  FollowUpID,
  name,
  IsInbound,
  note,
  SentToEmails,
  SentByEmail,
  phoneNumber,
  Outcome,
  date,
  user,
  IsPinned,
  sx,
  action,
  setRefresh,
  ...other
}: Props) {
  const [notePin] = useNotePinMutation()
  const [followUpComplete, { data, isSuccess }] = useFollowupCompleteMutation()
  const [isPinned, setIsPinned] = useState<boolean | undefined>(IsPinned)

  const [emailAnchorEl, setEmailAnchorEl] = useState<HTMLButtonElement | null>(null)
  const [anchorElPin, setAnchorElPin] = useState<HTMLButtonElement | null>(null)
  const open = Boolean(emailAnchorEl)
  const id = open ? 'simple-popover' : undefined
  const emailCount = SentToEmails ? SentToEmails?.split(';')?.length : 0
  const idPin = open ? 'pin-popover' : undefined
  const openPin = Boolean(anchorElPin)

  const theme = useTheme()
  const handleNoteUnPin = () => {
    notePin({
      noteId: NoteID,
      notePin: {
        isPinNote: false,
      },
    }).then(() => {
      setIsPinned(false)
      setRefresh((prevValue) => !prevValue)
    })
  }

  const handleNotePin = () => {
    notePin({
      noteId: NoteID,
      notePin: {
        isPinNote: true,
      },
    }).then(() => {
      setIsPinned(true)
      setRefresh((prevValue) => !prevValue)
      handleCloseMorePopover()
    })
  }

  const handleMarkComplete = () => {
    if (FollowUpID)
      followUpComplete({
        follow_up_id: FollowUpID,
      }).then(() => {
        setRefresh((prevValue) => !prevValue)
        handleCloseMorePopover()
      })
  }

  const handleEmailPopoverClick = (event: React.MouseEvent<HTMLButtonElement>) => {
    setEmailAnchorEl(event.currentTarget)
  }

  const handleEmailPopoverClose = () => {
    setEmailAnchorEl(null)
  }
  const handleOpenMorePopover = (event: React.MouseEvent<HTMLButtonElement>) => {
    setAnchorElPin(event.currentTarget)
  }

  const handleCloseMorePopover = () => {
    setAnchorElPin(null)
  }

  return (
    <Stack
      component={Paper}
      spacing={2}
      alignItems={{ md: 'flex-start' }}
      // direction={{ xs: 'column', md: 'row' }}
      direction={'row'}
      sx={{
        position: 'relative',
        ...sx,
      }}
      {...other}
    >
      <Stack flexGrow={1} spacing={1} direction={'column'}>
        <Stack direction='row' alignItems='center' spacing={1} justifyContent={'space-between'}>
          <Stack direction='row' alignItems='center' spacing={1} justifyContent={'flex-start'}>
            <Typography variant='subtitle2'>
              {NoteType?.toLowerCase() === 'call' || NoteType?.toLowerCase() === 'text' || NoteType?.toLowerCase() === 'email'
                ? IsInbound
                  ? 'Inbound'
                  : 'Outbound'
                : ''}{' '}
              {NoteType}
            </Typography>
            <Typography variant='body2' sx={{ color: 'text.secondary' }}>
              {convertDateTimeToDisplay(date)} by {user}
            </Typography>
          </Stack>

          {isPinned && (
            <Tooltip title='Unpin'>
              <IconButton onClick={() => (isPinned ? handleNoteUnPin() : handleNotePin())}>
                <Iconify icon='fluent:pin-32-filled' color={theme.palette.primary.main} />
              </IconButton>
            </Tooltip>
          )}
          {(!isPinned || (FollowUpID !== null && IsFollowUpCompleted == false)) && (
            <IconButton onClick={(e) => handleOpenMorePopover(e)} aria-describedby={idPin}>
              <GridMoreVertIcon />
            </IconButton>
          )}
        </Stack>

        <Typography variant='body2' sx={{ color: 'text.secondary' }}>
          {note}
        </Typography>
        {IsPTP && IsPtpSatisfied && (
          <Typography variant='body2' sx={{ color: 'text.secondary' }}>
            {IsPtpSatisfied && 'Satisfied'}
          </Typography>
        )}

        {phoneNumber && (
          <Typography variant='body2' sx={{ color: 'text.secondary' }}>
            Cell: {phoneNumber}
          </Typography>
        )}

        {NoteType?.toLowerCase() === 'call' && Outcome && (
          <>
            <Typography variant='body2' sx={{ color: 'text.secondary' }}>
              Outcome: {Outcome}
            </Typography>
          </>
        )}

        {IsPTP && (
          <>
            <Typography variant='body2' sx={{ color: 'text.secondary' }}>
              Due: {convertDateToDisplay(PtpDueDate)}
            </Typography>
            <Typography variant='body2' sx={{ color: 'text.secondary' }}>
              Due Amount: {formatNumber(PtpAmount)}
            </Typography>
          </>
        )}
        {IsFollowUp && (
          <>
            <Typography variant='body2' sx={{ color: 'text.secondary' }}>
              Due: {convertDateTimeToDisplay(FollowUpDueDateTime)}
            </Typography>
          </>
        )}
        {FollowUpAssignedTo && (
          <>
            <Typography variant='body2' sx={{ color: 'text.secondary' }}>
              Assigned To: {FollowUpAssignedTo}
            </Typography>
          </>
        )}
        {NoteType?.toLowerCase() === 'email' && (
          <>
            <Typography variant='body2' sx={{ color: 'text.secondary' }}>
              From: {SentByEmail}
            </Typography>
            <Popover
              id={id}
              open={open}
              anchorEl={emailAnchorEl}
              onClose={handleEmailPopoverClose}
              anchorOrigin={{
                vertical: 'bottom',
                horizontal: 'center',
              }}
              transformOrigin={{
                vertical: 'top',
                horizontal: 'center',
              }}
            >
              <Typography sx={{ p: 2 }}>{SentToEmails ? SentToEmails.split(';').join(', ') : ''}</Typography>
            </Popover>

            <Typography>
              <Typography>
                To: {SentToEmails ? SentToEmails.split(';')[0] : ''}{' '}
                {emailCount > 2 ? (
                  <span onClick={handleEmailPopoverClick} style={{ cursor: 'pointer' }}>
                    +{emailCount - 2} More
                  </span>
                ) : (
                  ''
                )}
              </Typography>
            </Typography>

            <Typography>Subject: {Subject}</Typography>
            <br />
          </>
        )}
        {IsFollowUp ? (
          IsFollowUpCompleted ? (
            <Stack direction={'row'} spacing={1} alignItems={'center'}>
              <Iconify icon={'fluent-mdl2:completed'} color={'green'} />
              <Typography variant='body2' sx={{ color: 'text.secondary' }}>
                {IsFollowUpCompleted && 'Follow-up completed'}
              </Typography>
            </Stack>
          ) : (
            <Stack direction={'row'} spacing={1} alignItems={'center'}>
              <Iconify icon={'fluent-mdl2:completed'} />
              <Typography variant='body2' sx={{ color: 'text.secondary' }}>
                {'Follow-up incomplete'}
              </Typography>
            </Stack>
          )
        ) : (
          ''
        )}
      </Stack>

      <Popover
        id={idPin}
        open={openPin}
        anchorEl={anchorElPin}
        onClose={handleCloseMorePopover}
        anchorOrigin={{
          vertical: 'bottom',
          horizontal: 'left',
        }}
      >
        <Stack>
          {!isPinned && (
            <MenuItem onClick={() => handleNotePin()} sx={{ textTransform: 'capitalize', justifyContent: 'flex-start' }}>
              Pin Note
            </MenuItem>
          )}
          {FollowUpID !== null && IsFollowUpCompleted == false && (
            <MenuItem onClick={() => handleMarkComplete()} sx={{ textTransform: 'capitalize', justifyContent: 'flex-start' }}>
              Mark as completed
            </MenuItem>
          )}
        </Stack>
      </Popover>

      {action && action}
    </Stack>
  )
}
