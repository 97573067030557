import React, { useEffect } from 'react'
import { styled } from '@mui/system'
import { Grid } from '@mui/material'
import { useDispatch, useSelector } from 'react-redux'
import { generateUtilityClasses } from '@mui/material'
import { isUserDropdownEmpty, setUserDropdown } from '../../../../../redux/slices/userDropdownSlice'
import { selectDropdownByNameActiveOnly, useGetUserPermissionsDropdownMutation } from '../../../../../services'
import DropdownFieldSearch from '../../../../../ui/DropdownFieldSearch'

const classes = generateUtilityClasses('Grid', ['root', 'row', 'optionalText'])

const StyledGrid = styled(Grid)(({ theme }) => ({
  [`&.${classes.root}`]: {},
  [`& .${classes.row}`]: {
    margin: theme.spacing(1, 0, 2, 0),
  },
  [`& .${classes.optionalText}`]: {
    fontFamily: 'Open Sans, sans-serif',
    fontWeight: 400,
    Size: '0.8750em',
    fontStyle: 'italic',
    lineHeight: '1.1875em',
    marginLeft: theme.spacing(1),
  },
}))

interface Props {}

export const ThirdForm = ({}: Props) => {
  const dispatch = useDispatch()
  const isDropdownEmpty = useSelector(isUserDropdownEmpty)
  const [userPermissionsDropdown, { data: userPermissionsDropdownData, isSuccess: userPermissionsDropdownIsSuccess }] =
    useGetUserPermissionsDropdownMutation()
  const branchID = localStorage.getItem('branch')
  const branch = useSelector((state) => selectDropdownByNameActiveOnly(state, 'Branch'))
  const carStatus = useSelector((state) => selectDropdownByNameActiveOnly(state, 'CarStatus'))

  useEffect(() => {
    if (userPermissionsDropdownIsSuccess) {
      dispatch(setUserDropdown(userPermissionsDropdownData))
    }
  }, [userPermissionsDropdownIsSuccess])

  useEffect(() => {
    if (isDropdownEmpty) {
      userPermissionsDropdown({ userPermissionsDropdown: {} })
    }
  }, [])

  return (
    <Grid container spacing={2} p={3}>
      {branch && branch?.length > 1 && (
        <Grid item xs={12} sm={6}>
          <DropdownFieldSearch
            labelId='BranchID'
            id='Branch'
            name='branch'
            fullWidth
            optionKey={'BranchID'}
            optionValue={'Branch'}
            label={'Branch'}
            options={branch}
            required
            initialValue={Number(branchID)}
          />
        </Grid>
      )}
      <Grid item xs={12} sm={6}>
        <DropdownFieldSearch labelId='LotID' id='Lot' name='Lot' fullWidth optionKey={'ID'} optionValue={'Lot'} label='Lot (Optional)' options={[]} />
      </Grid>
      {carStatus && (
        <Grid item xs={12} sm={6}>
          <DropdownFieldSearch
            labelId='StatusID'
            id='Status'
            name='StatusID'
            fullWidth
            optionKey={'ID'}
            optionValue={'Status'}
            label='Status (Optional)'
            options={carStatus}
          />
        </Grid>
      )}
    </Grid>
  )
}

export default ThirdForm
