import React, { useEffect, useMemo, useState } from 'react'
import { useNavigate, useParams } from 'react-router-dom'
import { styled } from '@mui/material/styles'
import Box from '@mui/material/Box'
import Grid from '@mui/material/Grid'
import Paper from '@mui/material/Paper'
import Button from '@mui/material/Button'
import { CustomCircular, SplitButton } from '../../../ui'
import { useLazyCarsVinReadQuery, useAddCarMutation, selectDropdownByNameActiveOnly } from '../../../services'
import { Form } from 'react-final-form'
import { Header } from './components/Header'
import { FirstForm } from './components/FirstForm'
import { SecondForm } from './components/SecondForm'
import { ThirdForm } from './components/ThirdForm'

import { Card, CardHeader, Container, generateUtilityClasses } from '@mui/material'
import validateForm from '../../../utils/validateForm'
import { addInventoryValidation } from '../../../validation'
import { appRoute } from '../../../constants'
import removeMask from '../../../utils/removeMask'
import { getCurrentDate } from '../../../utils/general'
import { enqueueNotification } from '../../../redux/slices/notificationsSlice'
import { useDispatch, useSelector } from 'react-redux'
import { getAddInventoryType, setAddInventoryType } from '../../../redux/slices/inventorySlice'
import { getUserAccessRights } from '../../../redux/slices/authSlice'
import CustomBreadcrumbs from '../../../components/custom-breadcrumbs/custom-breadcrumbs'

const classes = generateUtilityClasses('form', ['root', 'fieldsWrap', 'formItem', 'buttonWrap', 'saveBtn'])

const StyledForm = styled('form')(({ theme }) => ({
  [`&.${classes.root}`]: {
    display: 'flex',
    flexDirection: 'column',
  },
  [`& .${classes.fieldsWrap}`]: {
    padding: theme.spacing(2),
  },
  [`& .${classes.formItem}`]: {
    padding: theme.spacing(2, 3, 3, 3),
    margin: theme.spacing(0),
    borderRadius: 0,
  },
  [`& .${classes.buttonWrap}`]: {
    display: 'flex',
    gap: '20px',
    justifyContent: 'flex-start',
    flexDirection: 'row-reverse',
    padding: theme.spacing(2, 2, 2, 0),
    marginTop: 'auto',
  },
  [`& .${classes.saveBtn}`]: {
    marginLeft: theme.spacing(2),
  },
}))

interface Props {}

export const InventoryAdd = ({}: Props) => {
  const { vin } = useParams()
  const navigate = useNavigate()
  const dispatch = useDispatch()
  const userAccess = useSelector(getUserAccessRights)
  const [initFormValues, setInitFormValues] = useState<any>({})
  const [currentVin, setCurrentVin] = useState(vin)
  const branchID = localStorage.getItem('branch')
  const inventoryAddType = useSelector(getAddInventoryType)
  const [optionIndex, setOptionIndex] = useState(0)

  const [carsVin, { data: carsVinData, isSuccess: carsVinIsSuccess, isLoading: carsVinIsLoading }] = useLazyCarsVinReadQuery()
  const [addCar, { data: addCarData, isSuccess: addCarIsSuccess, isLoading: addCarIsLoading }] = useAddCarMutation()

  const carBodyType = useSelector((state) => selectDropdownByNameActiveOnly(state, 'CarBodyType'))

  const refetchDataWithVIN = (vin: string) => {
    if (vin) {
      carsVin({ vin: vin })
    }
  }

  useEffect(() => {
    if (vin) {
      refetchDataWithVIN(vin)
    }
  }, [vin])

  useEffect(() => {
    if (carsVinData) {
      const bodyType = carBodyType?.find(({ BodyType }) => BodyType === carsVinData?.BodyType)?.ID
      setInitFormValues({
        branch: Number(branchID),
        modelYear: carsVinData?.ModelYear,
        make: carsVinData?.Make,
        vin: currentVin || vin,
        // vin: vin,
        model: carsVinData?.Model,
        modelTrim: carsVinData?.ModelTrim,
        bodyType: bodyType,
        engine: carsVinData?.Engine,
        fuelType: carsVinData?.FuelType,
        doors: carsVinData?.Doors,
        drivetrain: carsVinData?.Drivetrain,
        cylinders: carsVinData?.Cylinders,
        bodyTypeID: carsVinData?.BodyTypeID,
        fuelTypeID: carsVinData?.FuelTypeID,
        driveTrainID: carsVinData?.DriveTrainID,
        vendor: null,
      })
    }
  }, [carsVinIsSuccess, carsVinData])

  const handleSubmit = (value: any) => {
    addCar({
      uspCarAddEdit: {
        stockDate: getCurrentDate(),
        branchID: value.branch,
        stockNumber: value?.stockNumber?.trim(),
        vin: value?.vin?.trim(),
        modelYear: value?.modelYear?.trim(),
        make: value?.make?.trim(),
        model: value?.model?.trim(),
        bodyType: value?.bodyType,
        bodyTypeID: value?.bodyTypeID,
        modelTrim: value?.modelTrim?.trim(),
        mileage: value?.mileage,
        fuelTypeID: carsVinData?.FuelTypeID,
        driveTrainID: carsVinData?.DriveTrainID,
        cylinders: carsVinData?.Cylinders,
        engine: carsVinData?.Engine,
        carStatusID: value?.StatusID,
        lotID: value?.Lot,
        exteriorColor: value?.color,
        addCarPurchasePrice: removeMask(value?.purchasePrice) as string,
        addCarPurchasedFromVendorID: value?.vendor,
        isAddCarPurchasedWithCheck: value?.purchaseWith === 'check',
        addCarPurchasedWithCheckNumber: value?.checkNumber,
        addCarIsFloorplan: value?.purchaseWith === 'floorplan',
        odometerTypeID: value?.odometer,
      } as any,
    })
    setOptionIndex(0)
  }

  const isLoading = useMemo(() => carsVinIsLoading || addCarIsLoading, [carsVinIsLoading, addCarIsLoading])

  useEffect(() => {
    if (addCarIsSuccess) {
      if (inventoryAddType === 'Save & Go To Car' && addCarData) {
        navigate(appRoute.InventoryEdit.replace(':carID', addCarData?.ID?.toString() ?? ''))
      }
      if (inventoryAddType === 'Save & Add New') {
        navigate(appRoute.InventoryAdd)
      }
      if (inventoryAddType === 'Save & Close') {
        navigate(appRoute.Inventory)
      }
      dispatch(enqueueNotification({ message: 'The Car successfully added to inventory' }))
    }
  }, [addCarIsSuccess])

  useEffect(() => {
    if (!userAccess.CanAddCar) {
      dispatch(
        enqueueNotification({
          message:
            'Access Denied: Sorry, your current access rights do not permit you to enter this section. If you believe you should have access, please contact your system administrator or help desk for assistance.',
          options: { variant: 'error' },
        }),
      )
      navigate(appRoute.Home)
    }
  }, [userAccess, appRoute])

  const types: Array<'button' | 'submit' | 'reset' | undefined> = ['button', 'button', 'button']
  const splitOptions = ['Save & Go To Car', 'Save & Add New', 'Save & Close']

  return (
    <Container>
      <Grid container>
        <Form onSubmit={handleSubmit} initialValues={{ ...initFormValues, odometer: 1 }} validate={validateForm(addInventoryValidation)}>
          {({ handleSubmit, valid, form }: any) => {
            const splitFunctions = [
              () => {
                dispatch(setAddInventoryType('Save & Go To Car'))
                handleSubmit()
              },
              () => {
                dispatch(setAddInventoryType('Save & Add New'))
                handleSubmit()
                setCurrentVin(undefined)
                if (addCarIsSuccess) {
                  setInitFormValues({})
                  form.reset()
                }
              },
              () => {
                dispatch(setAddInventoryType('Save & Close'))
                handleSubmit()
              },
            ]

            return (
              <form onSubmit={handleSubmit}>
                <CustomBreadcrumbs
                  heading='Inventory'
                  links={[{ name: 'List', href: './inventory' }]}
                  sx={{
                    mb: { xs: 2, md: 4 },
                  }}
                />
                {/* <Header /> */}
                <Grid container xs={12} md={10}>
                  <Box
                    sx={{
                      position: 'relative',
                      overflow: 'visible',
                      display: 'flex',
                      flexDirection: 'column',
                      alignItems: 'flex-end',
                      height: '100%',
                    }}
                  >
                    <Card>
                      <Grid item xs={12}>
                        <CardHeader title='Specs' />
                        <FirstForm onVINChange={refetchDataWithVIN} setCurrentVin={setCurrentVin} />
                      </Grid>
                      <Grid item xs={12}>
                        <CardHeader title='Purchase' />

                        <SecondForm />
                      </Grid>
                      <Grid item xs={12}>
                        <CardHeader title='Assignment' />

                        <ThirdForm />
                      </Grid>
                    </Card>

                    <Box
                      sx={{
                        display: 'flex',
                        flexDirection: 'row',
                        gap: 2,
                        position: { xs: 'relative', sm: 'sticky' },
                        bottom: { xs: 0, sm: 16 },

                        right: { xs: 0, sm: 20 },
                        p: 3,
                        width: 'fit-content',
                      }}
                    >
                      <Button variant='contained' color='error' onClick={() => navigate(appRoute.Inventory)}>
                        Close
                      </Button>
                      <SplitButton optionIndex={optionIndex} disabled={!valid} options={splitOptions} functions={splitFunctions} types={types} />
                    </Box>
                  </Box>
                </Grid>
              </form>
            )
          }}
        </Form>
        <CustomCircular open={isLoading} />
      </Grid>
    </Container>
  )
}

export default InventoryAdd
