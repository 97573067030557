import React from 'react'
import { generateUtilityClasses, Grid } from '@mui/material'
import { styled } from '@mui/system'
import { TextField } from '../../../../../ui'
import Typography from '@mui/material/Typography'

const classes = generateUtilityClasses('Grid', ['root', 'row', 'typographyWrap'])

const StyledGrid = styled(Grid)(({ theme }) => ({
  [`&.${classes.root}`]: {
    width: '100%',
    display: 'flex',
    boxShadow: '4px 0px 16px rgba(16, 30, 115, 0.08)',
    padding: theme.spacing(2, 0, 2, 0),
    backgroundColor: theme.palette.background.main,
    justifyContent: 'space-between',
  },
  [`& .${classes.row}`]: {
    margin: theme.spacing(2, 0, 0, 0),
    width: '100%',
  },
  [`& .${classes.typographyWrap}`]: {
    width: '100%',
  },
}))
interface Props {
  disableFields: boolean
}
const CommentsForm = ({ disableFields }: Props) => {
  return (
    <Grid container item xs={12} spacing={2} p={3}>
      <Grid item xs={12} sm={6}>
        <TextField disabled={disableFields} name='Note' label='Title Note' multiline fullWidth autoComplete='off' />
      </Grid>
    </Grid>
  )
}

export default CommentsForm
