import { yupResolver } from '@hookform/resolvers/yup'
import { Button, Dialog, DialogActions, DialogContent, DialogTitle, Stack, Typography } from '@mui/material'
import React, { useCallback, useEffect } from 'react'
import { useForm } from 'react-hook-form'
import * as Yup from 'yup'
import { RHFUpload } from '../../../components/hook-form/rhf-upload'
import FormProvider from '../../../components/hook-form/form-provider'
import { useUploadCarImageMutation } from '../../../services'
import { LoadingButton } from '@mui/lab'
import { enqueueNotification } from '../../../redux/slices/notificationsSlice'
import { useDispatch } from 'react-redux'

function UploadImageModal({ open, onClose, carId, refresh }: { carId: string; refresh: any; open: boolean; onClose: () => void }) {
  const [uploadCarImages, { isLoading: uploadCarImagesIsLoading, isSuccess: uploadCarImagesIsSuccess }] = useUploadCarImageMutation()
  const dispatch = useDispatch()
  const uploadImage = Yup.object().shape({
    images: Yup.array().min(1, 'Images are required'),
  })

  const methods = useForm({
    resolver: yupResolver(uploadImage),
  })

  const {
    watch,
    reset,
    control,
    setValue,
    handleSubmit,
    formState: { isSubmitting },
  } = methods

  const onSubmit = handleSubmit(async (data) => {
    try {
      const files: File[] = data.images
      if (files) {
        const formattedFiles = Array.from(files).map((file) => ({
          name: file.name,
          type: file.type,
          file: file,
        }))
        uploadCarImages({ carId: carId?.toString() as string, Files: formattedFiles })
      }

      console.info('DATA', data)
    } catch (error) {
      console.error(error)
    }
  })
  const values = watch()

  useEffect(() => {
    if (uploadCarImagesIsSuccess) {
      refresh()
      dispatch(enqueueNotification({ message: 'Success' }))
      reset({
        images: [],
      })
      onClose()
    }
  }, [uploadCarImagesIsSuccess, uploadCarImagesIsLoading])

  const handleDrop = useCallback(
    (acceptedFiles: File[]) => {
      const files = values.images || []
      const newFiles = acceptedFiles.map((file) =>
        Object.assign(file, {
          preview: URL.createObjectURL(file),
        }),
      )

      setValue('images', [...files, ...newFiles], { shouldValidate: true })
    },
    [setValue, values.images],
  )

  const handleRemoveFile = useCallback(
    (inputFile: File | string) => {
      const filtered = values.images && values.images?.filter((file: any) => file !== inputFile)
      setValue('images', filtered)
    },
    [setValue, values.images],
  )

  const handleRemoveAllFiles = useCallback(() => {
    setValue('images', [])
  }, [setValue])

  return (
    <Dialog open={open} onClose={onClose}>
      <FormProvider methods={methods} onSubmit={onSubmit}>
        <DialogTitle>Upload Images</DialogTitle>
        <DialogContent>
          <Stack>
            <RHFUpload
              multiple
              thumbnail
              name='images'
              maxSize={3145728}
              onDrop={handleDrop}
              onRemove={handleRemoveFile}
              onRemoveAll={handleRemoveAllFiles}
            />
          </Stack>
        </DialogContent>
        <DialogActions>
          <Button
            color='error'
            variant='contained'
            onClick={() => {
              onClose()
            }}
          >
            Cancel
          </Button>
          <LoadingButton type='submit' variant='contained' loading={uploadCarImagesIsLoading}>
            Submit
          </LoadingButton>
        </DialogActions>
      </FormProvider>
    </Dialog>
  )
}

export default UploadImageModal
