import React, { useState } from 'react'
import {
  Accordion,
  AccordionDetails,
  AccordionSummary,
  Box,
  Grid,
  IconButton,
  ListItemText,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Typography,
  generateUtilityClasses,
} from '@mui/material'
import autoTable from 'jspdf-autotable'
import { ExpandMore as ExpandMoreIcon } from '@mui/icons-material'
import { styled } from '@mui/system'
import { convertDateToDisplay } from '../../../../../utils/dateUtils'
import _ from 'lodash'
import Scrollbar from '../../../../../components/scrollbar'
import { TableEmptyRows, TableHeadCustom, TableNoData, emptyRows, useTable } from '../../../../../components/table'
import OrderTableRow from './income-row'
import Iconify from '../../../../../components/iconify'
import html2canvas from 'html2canvas'
import jsPDF from 'jspdf'
const classes = generateUtilityClasses('div', ['root', 'headerContainer'])

const StyleWrapper = styled('div')(() => ({
  [`&.${classes.root}`]: {},
  [`& .${classes.headerContainer}`]: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    flexDirection: 'column',
    width: '100%',
    margin: '20px 0',
    gap: '10px',
  },
}))

type AccountData = {
  Expanded?: boolean
  AccountNumber: number
  BranchID: number
  AccountName: string
  AccountType: string
  AccountSubType: string
  ClosingBalance: string
  Activity: string
  Balance: string
  AccountOrder: number
  SubTypeOrder: number
  ReportDate: string
  StartDate: string
  LastTransactionNumber: number
  FinancialStatement: string
}

interface IncomeStatementProps {
  data: AccountData[]
}

const IncomeStatement: React.FC<IncomeStatementProps> = ({ data }) => {
  const [expandedAccordion, setExpandedAccordion] = useState<any[] | undefined>([])
  const [expandedSubAccordion, setExpandedSubAccordion] = useState<string | null>(null)
  // const handleAccordionChange = (panel: string) => (_event: React.ChangeEvent<{}>, isExpanded: boolean) => {
  //   setExpandedAccordion(isExpanded ? panel : null)
  // }
  const table = useTable()
  const denseHeight = table.dense ? 56 : 56 + 20

  const TABLE_HEAD = [
    { id: 'Branch', label: 'Branch' },
    { id: 'StartingBalance', label: 'Starting Balance' },
    { id: 'Period', label: 'Period' },
    { id: 'EndingBalance', label: 'Ending Balance' },
  ]
  const handleSubAccordionChange = (panel: string) => (_event: React.ChangeEvent<{}>, isExpanded: boolean) => {
    setExpandedSubAccordion(isExpanded ? panel : null)
  }

  const groupData = (data: AccountData[]) => {
    const groupedData: Record<string, Record<string, AccountData[]>> = {}

    data?.forEach((item) => {
      if (!groupedData[item.AccountType]) {
        groupedData[item.AccountType] = {}
      }
      if (!groupedData[item.AccountType][item.AccountSubType]) {
        groupedData[item.AccountType][item.AccountSubType] = []
      }
      groupedData[item.AccountType][item.AccountSubType].push(item)
    })
    return groupedData
  }

  console.log(groupData(data))

  const calculateSubTypeTotal = (accounts: AccountData[]) => {
    let totalStartingBalance = 0
    let totalActivity = 0
    let totalBalance = 0

    accounts?.forEach((account) => {
      totalStartingBalance += parseFloat(account.ClosingBalance)
      totalActivity += parseFloat(account.Activity)
      totalBalance += parseFloat(account.Balance)
    })

    return {
      totalStartingBalance: totalStartingBalance.toFixed(2),
      totalActivity: totalActivity.toFixed(2),
      totalBalance: totalBalance.toFixed(2),
    }
  }

  const calculateAccountTypeTotal = (subTypes: Record<string, AccountData[]>) => {
    let startingBalance = 0
    let totalActivity = 0
    let totalBalance = 0

    Object.values(subTypes)?.forEach((accounts) => {
      accounts?.forEach((account) => {
        startingBalance += parseFloat(account.ClosingBalance)
        totalActivity += parseFloat(account.Activity)
        totalBalance += parseFloat(account.Balance)
      })
    })

    return {
      startingBalance: startingBalance.toFixed(2),
      totalActivity: totalActivity.toFixed(2),
      totalBalance: totalBalance.toFixed(2),
    }
  }

  const calculateGrossProfit = (groupedData: Record<string, Record<string, AccountData[]>>) => {
    const startingRevenues = calculateAccountTypeTotal(groupedData['Revenues'] || {})
    const startingCostOfSales = calculateAccountTypeTotal(groupedData['Cost of Sales'] || {})
    const revenues = calculateAccountTypeTotal(groupedData['Revenues'] || {})
    const costOfSales = calculateAccountTypeTotal(groupedData['Cost of Sales'] || {})

    const startingBalance = parseFloat(startingRevenues.startingBalance) - parseFloat(startingCostOfSales.startingBalance)
    const grossProfitActivity = parseFloat(revenues.totalActivity) - parseFloat(costOfSales.totalActivity)
    const grossProfitBalance = parseFloat(revenues.totalBalance) - parseFloat(costOfSales.totalBalance)

    return {
      startingBalance: startingBalance.toFixed(2),
      grossProfitActivity: grossProfitActivity.toFixed(2),
      grossProfitBalance: grossProfitBalance.toFixed(2),
    }
  }

  const calculateNetProfit = (
    groupedData: Record<string, Record<string, AccountData[]>>,
    grossProfit: {
      grossProfitActivity: string
      grossProfitBalance: string
      startingBalance: string
    },
  ) => {
    const operatingExpenses = calculateAccountTypeTotal(groupedData['Operating Expenses'] || {})
    const otherIncome = calculateAccountTypeTotal(groupedData['Other Income'] || {})
    const otherExpenses = calculateAccountTypeTotal(groupedData['Other Expenses'] || {})
    const netProfitStartingBalance =
      parseFloat(grossProfit.startingBalance) -
      parseFloat(operatingExpenses.startingBalance) +
      parseFloat(otherIncome.startingBalance) +
      parseFloat(otherExpenses.startingBalance)

    const netProfitActivity =
      parseFloat(grossProfit.grossProfitActivity) -
      parseFloat(operatingExpenses.totalActivity) +
      parseFloat(otherIncome.totalActivity) -
      parseFloat(otherExpenses.totalActivity)
    const netProfitBalance =
      parseFloat(grossProfit.grossProfitBalance) -
      parseFloat(operatingExpenses.totalBalance) +
      parseFloat(otherIncome.totalBalance) -
      parseFloat(otherExpenses.totalBalance)

    return {
      netProfitStartingBalance: netProfitStartingBalance.toFixed(2),
      netProfitActivity: netProfitActivity.toFixed(2),
      netProfitBalance: netProfitBalance.toFixed(2),
    }
  }

  const groupedData = groupData(data)
  const grossProfit = calculateGrossProfit(groupedData)
  const netProfit = calculateNetProfit(groupedData, grossProfit)
  const notFound = !Object.entries(groupedData)?.length

  const formatNumber = (num: number | string) => {
    return new Intl.NumberFormat('en-US', {
      minimumFractionDigits: 2,
      maximumFractionDigits: 2,
    }).format(Number(num))
  }

  return (
    <div>
      <IconButton
        sx={{ position: 'absolute', right: 20, top: 20 }}
        onClick={() => {
          const unit = 'pt'
          const size = 'A4' // Use A1, A2, A3 or A4
          const orientation = 'portrait' // portrait or landscape

          const marginLeft = 40
          const doc = new jsPDF(orientation, unit, size)

          if (_.first(data)?.FinancialStatement === 'Income Statement') {
            doc.setFontSize(15)
            doc.text(_.first(data)?.FinancialStatement ?? '', doc.internal.pageSize.width / 2, 20, { align: 'center' })
            doc.setFontSize(10)

            doc.text(
              convertDateToDisplay(_.first(data)?.StartDate) + ' - ' + convertDateToDisplay(_.first(data)?.ReportDate),
              doc.internal.pageSize.width / 2,
              35,
              {
                align: 'center',
              },
            )
          }

          if (_.first(data)?.FinancialStatement === 'Balance Sheet') {
            doc.setFontSize(15)

            doc.text(_.first(data)?.FinancialStatement ?? '', doc.internal.pageSize.width / 2, 20, { align: 'center' })
            doc.setFontSize(10)

            doc.text(`Period Ending: ${data && convertDateToDisplay(_.first(data)?.ReportDate)}`, doc.internal.pageSize.width / 2, 40, {
              align: 'center',
            })
          }

          const headers = [
            [
              {
                content: 'Description',
                colSpan: 1,
                rowSpan: 1,
                styles: { fontStyle: 'normal', cellWidth: (doc.internal.pageSize.width - 2 * marginLeft) * (2 / 6) },
              },

              {
                content: 'Branch',
                colSpan: 1,
                rowSpan: 1,
                styles: { fontStyle: 'normal', cellWidth: (doc.internal.pageSize.width - 2 * marginLeft) * (1 / 6) },
              },
              {
                content: 'Starting Balance',
                colSpan: 1,
                rowSpan: 1,
                styles: { fontStyle: 'normal', cellWidth: (doc.internal.pageSize.width - 2 * marginLeft) * (1 / 6) },
              },
              {
                content: 'Period',
                colSpan: 1,
                rowSpan: 1,
                styles: { fontStyle: 'normal', cellWidth: (doc.internal.pageSize.width - 2 * marginLeft) * (1 / 6) },
              },
              {
                content: 'Ending Balance',
                colSpan: 1,
                rowSpan: 1,
                styles: { fontStyle: 'normal', cellWidth: (doc.internal.pageSize.width - 2 * marginLeft) * (1 / 6) },
              },
            ],
          ]

          Object.entries(groupedData).map(([accountType, subTypes]) => {
            doc.setFontSize(12)
            //@ts-ignore
            doc.setFont(undefined, 'bold')

            doc.text(accountType, doc.internal.pageSize.width / 2, (doc as any).lastAutoTable.finalY ? (doc as any).lastAutoTable.finalY + 30 : 60, {
              align: 'center',
            })

            Object.entries(subTypes)?.map(([subType, accounts], index) => {
              const { totalActivity, totalBalance, totalStartingBalance } = calculateSubTypeTotal(accounts)
              const data = [
                [
                  {
                    content: subType,
                    colSpan: 1,
                    rowSpan: 1,
                    styles: { cellWidth: (doc.internal.pageSize.width - 2 * marginLeft) / 5, fontStyle: 'bold' },
                  },
                  '',
                  '',
                  '',
                  '',
                ],
                ...accounts?.map((row: any) => [
                  {
                    content: row.AccountName,
                    colSpan: 1,
                    rowSpan: 1,
                    styles: { cellWidth: (doc.internal.pageSize.width - 2 * marginLeft) * (2 / 6) },
                  },
                  {
                    content: row.BranchID ?? '-',
                    colSpan: 1,
                    rowSpan: 1,
                    styles: { cellWidth: (doc.internal.pageSize.width - 2 * marginLeft) * (1 / 6) },
                  },

                  {
                    content: formatNumber(row.ClosingBalance),
                    colSpan: 1,
                    rowSpan: 1,
                    styles: { cellWidth: (doc.internal.pageSize.width - 2 * marginLeft) * (1 / 6) },
                  },

                  {
                    content: formatNumber(row.Activity),
                    colSpan: 1,
                    rowSpan: 1,
                    styles: { cellWidth: (doc.internal.pageSize.width - 2 * marginLeft) * (1 / 6) },
                  },

                  {
                    content: formatNumber(row.Balance),
                    colSpan: 1,
                    rowSpan: 1,
                    styles: { cellWidth: (doc.internal.pageSize.width - 2 * marginLeft) * (1 / 6) },
                  },
                ]),
                [
                  {
                    content: `${subType} Total`,
                    colSpan: 1,
                    rowSpan: 1,
                    styles: {
                      cellWidth: (doc.internal.pageSize.width - 2 * marginLeft) * (2 / 6),
                      lineWidth: { top: 1, right: 0, bottom: 0, left: 0 },
                      fontStyle: 'bold',
                    },
                  },
                  {
                    content: '-',
                    colSpan: 1,
                    rowSpan: 1,
                    styles: {
                      cellWidth: (doc.internal.pageSize.width - 2 * marginLeft) * (1 / 6),
                      lineWidth: { top: 1, right: 0, bottom: 0, left: 0 },
                    },
                  },
                  {
                    content: formatNumber(totalStartingBalance),
                    colSpan: 1,
                    rowSpan: 1,
                    styles: {
                      cellWidth: (doc.internal.pageSize.width - 2 * marginLeft) * (1 / 6),
                      lineWidth: { top: 1, right: 0, bottom: 0, left: 0 },
                    },
                  },

                  {
                    content: formatNumber(totalActivity),
                    colSpan: 1,
                    rowSpan: 1,
                    styles: {
                      cellWidth: (doc.internal.pageSize.width - 2 * marginLeft) * (1 / 6),
                      lineWidth: { top: 1, right: 0, bottom: 0, left: 0 },
                    },
                  },

                  {
                    content: formatNumber(totalBalance),
                    colSpan: 1,
                    rowSpan: 1,
                    styles: {
                      cellWidth: (doc.internal.pageSize.width - 2 * marginLeft) * (1 / 6),
                      lineWidth: { top: 1, right: 0, bottom: 0, left: 0 },
                    },
                  },
                ],
              ]

              if (index > 0) {
                let content = {
                  startY: (doc as any).lastAutoTable.finalY ? (doc as any).lastAutoTable.finalY + 10 : 70,
                  body: data,
                }
                //@ts-ignore
                autoTable(doc, content)
              } else {
                let content = {
                  headStyles: {
                    textColor: 0,
                    fillColor: false,
                    lineWidth: { top: 0, right: 0, bottom: 1, left: 0 },
                  },
                  startY: (doc as any).lastAutoTable.finalY ? (doc as any).lastAutoTable.finalY + 40 : 70,
                  head: headers,
                  body: data,
                }
                //@ts-ignore
                autoTable(doc, content)
              }

              // doc.text(title, marginLeft, 40)
            })

            let data = [
              [
                {
                  content: 'Total',
                  colSpan: 1,
                  rowSpan: 1,
                  styles: { fontStyle: 'bold', cellWidth: (doc.internal.pageSize.width - 2 * marginLeft) * (2 / 6) },
                },
                {
                  content: '-',
                  colSpan: 1,
                  rowSpan: 1,
                  styles: { fontStyle: 'bold', cellWidth: (doc.internal.pageSize.width - 2 * marginLeft) * (1 / 6) },
                },
                {
                  content: formatNumber(calculateAccountTypeTotal(subTypes).startingBalance),
                  colSpan: 1,
                  rowSpan: 1,
                  styles: { fontStyle: 'bold', cellWidth: (doc.internal.pageSize.width - 2 * marginLeft) * (1 / 6) },
                },
                {
                  content: formatNumber(calculateAccountTypeTotal(subTypes).totalActivity),
                  colSpan: 1,
                  rowSpan: 1,
                  styles: { fontStyle: 'bold', cellWidth: (doc.internal.pageSize.width - 2 * marginLeft) * (1 / 6) },
                },
                {
                  content: formatNumber(calculateAccountTypeTotal(subTypes).totalBalance),
                  colSpan: 1,
                  rowSpan: 1,
                  styles: { fontStyle: 'bold', cellWidth: (doc.internal.pageSize.width - 2 * marginLeft) * (1 / 6) },
                },
              ],
            ]
            let content = {
              startY: (doc as any).lastAutoTable.finalY ? (doc as any).lastAutoTable.finalY + 10 : 70,
              body: data,
            }
            //@ts-ignore
            autoTable(doc, content)
          })

          let data2 = [
            [
              {
                content: 'Net Profit',
                colSpan: 1,
                rowSpan: 1,
                styles: { fontStyle: 'bold', cellWidth: (doc.internal.pageSize.width - 2 * marginLeft) * (2 / 6) },
              },
              {
                content: '-',
                colSpan: 1,
                rowSpan: 1,
                styles: { fontStyle: 'bold', cellWidth: (doc.internal.pageSize.width - 2 * marginLeft) * (1 / 6) },
              },
              {
                content: formatNumber(netProfit.netProfitStartingBalance),
                colSpan: 1,
                rowSpan: 1,
                styles: { fontStyle: 'bold', cellWidth: (doc.internal.pageSize.width - 2 * marginLeft) * (1 / 6) },
              },
              {
                content: formatNumber(netProfit.netProfitActivity),
                colSpan: 1,
                rowSpan: 1,
                styles: { fontStyle: 'bold', cellWidth: (doc.internal.pageSize.width - 2 * marginLeft) * (1 / 6) },
              },
              {
                content: formatNumber(netProfit.netProfitBalance),
                colSpan: 1,
                rowSpan: 1,
                styles: { fontStyle: 'bold', cellWidth: (doc.internal.pageSize.width - 2 * marginLeft) * (1 / 6) },
              },
            ],
          ]
          let content2 = {
            startY: (doc as any).lastAutoTable.finalY ? (doc as any).lastAutoTable.finalY + 10 : 70,
            body: data2,
          }
          //@ts-ignore
          if (_.first(data)?.FinancialStatement !== 'Balance Sheet') autoTable(doc, content2)

          let name = `${_.first(data)?.FinancialStatement}_${
            data ? convertDateToDisplay(_.first(data)?.StartDate) + '-' + convertDateToDisplay(_.first(data)?.ReportDate) : ''
          }.pdf`
          doc.save(name)
        }}
      >
        <Iconify icon={'material-symbols:print-outline'} />
      </IconButton>
      <StyleWrapper className={classes.root} id='divToPrint'>
        <Grid container>
          <Grid item className={classes.headerContainer}>
            {_.first(data)?.FinancialStatement === 'Income Statement' && (
              <>
                <Typography variant='h5'>{_.first(data)?.FinancialStatement}</Typography>
                <Typography>
                  {data ? convertDateToDisplay(_.first(data)?.StartDate) + ' - ' + convertDateToDisplay(_.first(data)?.ReportDate) : null}
                </Typography>
              </>
            )}

            {_.first(data)?.FinancialStatement === 'Balance Sheet' && (
              <>
                <Typography variant='h3'>{_.first(data)?.FinancialStatement}</Typography>
                <Typography>Period Ending: {data && convertDateToDisplay(_.first(data)?.ReportDate)}</Typography>
              </>
            )}
          </Grid>
        </Grid>
        <Table>
          <TableHead>
            <TableRow>
              <TableCell align='right' sx={{ padding: '16px', width: '211px', fontWeight: 'bold' }}></TableCell>
              <TableCell align='right' sx={{ padding: '16px', width: '211px', fontWeight: 'bold' }}>
                Branch
              </TableCell>
              <TableCell align='right' sx={{ padding: '16px', width: '211px', fontWeight: 'bold' }}>
                Starting Balance
              </TableCell>
              <TableCell align='right' sx={{ padding: '16px', width: '211px', fontWeight: 'bold' }}>
                Period
              </TableCell>
              <TableCell align='right' sx={{ padding: '16px', width: '211px', fontWeight: 'bold' }}>
                Ending Balance
              </TableCell>
            </TableRow>
          </TableHead>
        </Table>

        {Object.entries(groupedData).map(([accountType, subTypes]) => {
          // let expandedAccordionCopy = expandedAccordion?.map((el) => el)
          // expandedAccordionCopy?.push(accountType)

          // setExpandedAccordion(expandedAccordionCopy)

          return (
            <Accordion
              defaultExpanded={true}
              expanded={
                expandedAccordion?.find((el) => el.name == accountType) ? expandedAccordion?.find((el) => el.name == accountType)?.status : true
              }
              onChange={(e, expand) => {
                let expandedAccordionCopy = expandedAccordion?.map((el) => el)
                console.log(expandedAccordionCopy)

                expandedAccordionCopy && expandedAccordionCopy?.findIndex((el) => el.name == accountType) !== -1
                  ? (expandedAccordionCopy[expandedAccordionCopy.findIndex((el) => el.name == accountType)].status = expand)
                  : expandedAccordionCopy?.push({
                      name: accountType,
                      status: expand,
                    })
                // expand == true
                //   ? expandedAccordionCopy?.push(accountType)
                //   : expandedAccordionCopy?.splice(
                //       expandedAccordionCopy.findIndex((el) => el == accountType),
                //       1,
                //     )

                setExpandedAccordion(expandedAccordionCopy)
              }}
            >
              {/* {console.log('expandedAccordion?.[accountType]', expandedAccordion)} */}
              <AccordionSummary expandIcon={<Iconify icon='eva:arrow-ios-downward-fill' />}>
                {/* <Typography variant='subtitle1' sx={{ width: '33%', flexShrink: 0 }}>
              {}
            </Typography>
            <Typography variant='subtitle2' textAlign={'right'} sx={{ width: '63%', flexShrink: 0 }}>
              {}
            </Typography> */}

                <Table size='small'>
                  <TableRow sx={{ width: '99%', flexShrink: 0 }}>
                    <TableCell
                      sx={{
                        padding: '0px',
                        width: '211px',
                        fontWeight: 'bold',
                      }}
                    >
                      {accountType}
                    </TableCell>
                    {!(expandedAccordion?.find((el) => el.name == accountType)
                      ? expandedAccordion?.find((el) => el.name == accountType).status
                      : true) && (
                      <>
                        <TableCell
                          align='right'
                          sx={{
                            padding: '0px',
                            width: '211px',
                            fontWeight: 'bold',
                          }}
                        >
                          -
                        </TableCell>

                        <TableCell
                          align='right'
                          sx={{
                            padding: '0px',
                            width: '211px',
                            fontWeight: 'bold',
                          }}
                        >
                          {formatNumber(calculateAccountTypeTotal(subTypes).startingBalance)}
                        </TableCell>
                        <TableCell
                          align='right'
                          sx={{
                            padding: '0px',
                            width: '211px',
                            fontWeight: 'bold',
                          }}
                        >
                          {formatNumber(calculateAccountTypeTotal(subTypes).totalActivity)}
                        </TableCell>
                        <TableCell
                          align='right'
                          sx={{
                            padding: '0px',
                            width: '211px',
                            fontWeight: 'bold',
                          }}
                        >
                          {formatNumber(calculateAccountTypeTotal(subTypes).totalBalance)}
                        </TableCell>
                      </>
                    )}
                  </TableRow>
                </Table>
              </AccordionSummary>
              <AccordionDetails>
                {Object.entries(subTypes)?.map(([subType, accounts]) => {
                  const { totalActivity, totalBalance, totalStartingBalance } = calculateSubTypeTotal(accounts)

                  return (
                    <TableContainer sx={{ position: 'relative', overflow: 'unset' }}>
                      <Scrollbar>
                        <Table size={table.dense ? 'small' : 'medium'} sx={{ minWidth: 960 }}>
                          {/* <TableHeadCustom
              order={table.order}
              orderBy={table.orderBy}
              headLabel={TABLE_HEAD}
              rowCount={Object.entries(groupedData)?.length}
              numSelected={table.selected.length}
              onSort={table.onSort}
            /> */}
                          <TableHead>
                            <TableRow>
                              <TableCell sx={{ fontWeight: 'bold' }}>{subType}</TableCell>
                              <TableCell
                                align='right'
                                sx={{
                                  padding: '16px ',
                                  width: '211px',
                                  fontWeight: 'bold',
                                }}
                              ></TableCell>
                              <TableCell
                                align='right'
                                sx={{
                                  padding: '16px ',
                                  width: '211px',
                                  fontWeight: 'bold',
                                }}
                              ></TableCell>
                              <TableCell
                                align='right'
                                sx={{
                                  padding: '16px ',
                                  width: '211px',
                                  fontWeight: 'bold',
                                }}
                              ></TableCell>
                              <TableCell
                                align='right'
                                sx={{
                                  padding: '16px ',
                                  width: '211px',
                                  fontWeight: 'bold',
                                }}
                              >
                                {/* {formatNumber(totalBalance)} */}
                              </TableCell>
                            </TableRow>
                          </TableHead>
                          <TableBody>
                            {accounts
                              // .slice(table.page * table.rowsPerPage, table.page * table.rowsPerPage + table.rowsPerPage)
                              ?.map((row: any) => (
                                <OrderTableRow
                                  key={row.PaymentID}
                                  row={row}
                                  data={data}
                                  // selected={table.selected.includes(row.id)}
                                  // onSelectRow={() => table.onSelectRow(row.id)}
                                  // onDeleteRow={() => handleDeleteRow(row.id)}
                                  // onViewRow={() => handleViewRow(row.id)}
                                />
                              ))}

                            {(() => {
                              return (
                                <TableRow hover>
                                  <TableCell
                                    sx={{
                                      padding: '16px',
                                      width: '211px',
                                      fontWeight: 'bold',
                                    }}
                                  >
                                    <Box>{subType} Total </Box>
                                  </TableCell>

                                  <TableCell sx={{ padding: '16px ', width: '211px' }} align='right'>
                                    <ListItemText
                                      primary={'-'}
                                      primaryTypographyProps={{
                                        typography: 'body2',
                                      }}
                                      secondaryTypographyProps={{
                                        component: 'span',
                                        color: 'text.disabled',
                                      }}
                                    />
                                  </TableCell>
                                  <TableCell sx={{ padding: '16px ', width: '211px' }} align='right'>
                                    <ListItemText
                                      primary={formatNumber(totalStartingBalance)}
                                      primaryTypographyProps={{
                                        typography: 'body2',
                                        noWrap: true,
                                      }}
                                      secondaryTypographyProps={{
                                        mt: 0.5,
                                        component: 'span',
                                        typography: 'caption',
                                      }}
                                    />
                                  </TableCell>
                                  <TableCell sx={{ padding: '16px ', width: '211px' }} align='right'>
                                    <ListItemText
                                      primary={formatNumber(totalActivity)}
                                      primaryTypographyProps={{
                                        typography: 'body2',
                                      }}
                                      secondaryTypographyProps={{
                                        component: 'span',
                                        color: 'text.disabled',
                                      }}
                                    />
                                  </TableCell>

                                  <TableCell sx={{ padding: '16px ', width: '211px' }} align='right'>
                                    {formatNumber(totalBalance)}
                                  </TableCell>
                                </TableRow>
                              )
                            })()}

                            <TableEmptyRows
                              height={denseHeight}
                              emptyRows={emptyRows(table.page, table.rowsPerPage, Object.entries(groupedData)?.length)}
                            />

                            <TableNoData notFound={notFound} />
                          </TableBody>
                        </Table>
                      </Scrollbar>
                    </TableContainer>
                  )
                })}
                <Table>
                  <TableBody>
                    <TableRow hover>
                      <TableCell
                        sx={{
                          padding: '16px',
                          width: '211px',
                          fontWeight: 'bold',
                        }}
                      >
                        <Box>Total </Box>
                      </TableCell>

                      <TableCell sx={{ padding: '16px ', width: '211px' }} align='right'>
                        <ListItemText
                          primary={'-'}
                          primaryTypographyProps={{
                            typography: 'body2',
                          }}
                          secondaryTypographyProps={{
                            component: 'span',
                            color: 'text.disabled',
                          }}
                        />
                      </TableCell>
                      <TableCell sx={{ padding: '16px ', width: '211px' }} align='right'>
                        <ListItemText
                          primary={formatNumber(calculateAccountTypeTotal(subTypes).startingBalance)}
                          primaryTypographyProps={{
                            typography: 'body2',
                            noWrap: true,
                          }}
                          secondaryTypographyProps={{
                            mt: 0.5,
                            component: 'span',
                            typography: 'caption',
                          }}
                        />
                      </TableCell>
                      <TableCell sx={{ padding: '16px ', width: '211px' }} align='right'>
                        <ListItemText
                          primary={formatNumber(calculateAccountTypeTotal(subTypes).totalActivity)}
                          primaryTypographyProps={{
                            typography: 'body2',
                          }}
                          secondaryTypographyProps={{
                            component: 'span',
                            color: 'text.disabled',
                          }}
                        />
                      </TableCell>

                      <TableCell sx={{ padding: '16px ', width: '211px' }} align='right'>
                        {formatNumber(calculateAccountTypeTotal(subTypes).totalBalance)}
                      </TableCell>
                    </TableRow>
                  </TableBody>
                </Table>
              </AccordionDetails>
            </Accordion>
          )
        })}

        {/* {Object.entries(groupedData).map(([accountType, subTypes]) => (
        <React.Fragment key={accountType}>
          <Accordion defaultExpanded={true} onChange={handleAccordionChange(accountType)} sx={{ padding: 0, boxShadow: 'none' }}>
            <AccordionSummary sx={{ padding: 0 }}>
              <ExpandMoreIcon />

              <Typography>{accountType}</Typography>
              {expandedAccordion !== accountType && (
                <Typography id='totalVal' sx={{ marginLeft: 'auto' }}>
                  {formatNumber(calculateAccountTypeTotal(subTypes).totalBalance)}
                </Typography>
              )}
            </AccordionSummary>
            <AccordionDetails sx={{ padding: 0 }}>
              <Table sx={{ padding: 0 }}>
                <TableBody sx={{ padding: 0 }}>
                  <TableRow sx={{ padding: 0 }}>
                    <TableCell sx={{ padding: 0 }}>
                      {Object.entries(subTypes).map(([subType, accounts]) => {
                        const { totalActivity, totalBalance, totalStartingBalance } = calculateSubTypeTotal(accounts)
                        return (
                          <React.Fragment key={subType}>
                            <Accordion defaultExpanded={true} sx={{ padding: 0, boxShadow: 'none' }} onChange={handleSubAccordionChange(subType)}>
                              <AccordionSummary sx={{ padding: '0 0 0 16px' }}>
                                <ExpandMoreIcon />
                                <Typography>{subType}</Typography>
                                {expandedSubAccordion !== subType && (
                                  <Typography id='subTotalVal' sx={{ marginLeft: 'auto' }}>
                                    {formatNumber(totalBalance)}
                                  </Typography>
                                )}
                              </AccordionSummary>

                              <AccordionDetails sx={{ padding: '0 0 0 16px', borderBottom: 'none' }}>
                                <Table size='small' sx={{ tableLayout: 'auto', borderBottom: 'none' }}>
                                  <TableBody sx={{ padding: 0 }}>
                                    {accounts.map((account) => (
                                      <TableRow key={account.AccountNumber}>
                                        <TableCell align='left' sx={{ width: '211px', padding: '5px 0px' }}>
                                          {account.AccountName}
                                        </TableCell>
                                        <TableCell align='right' sx={{ width: '211px', padding: '5px 0px' }}>
                                          {account.BranchID}
                                        </TableCell>
                                        <TableCell align='right' sx={{ width: '211px', padding: '5px 0px' }}>
                                          {formatNumber(account.ClosingBalance)}
                                        </TableCell>
                                        <TableCell align='right' sx={{ width: '211px', padding: '5px 0px' }}>
                                          {formatNumber(account.Activity)}
                                        </TableCell>
                                        <TableCell align='right' sx={{ width: '211px', padding: '5px 0px' }}>
                                          {formatNumber(account.Balance)}
                                        </TableCell>
                                      </TableRow>
                                    ))}
                                    <TableRow sx={{ width: '100%', borderBottom: 'none' }}>
                                      <TableCell align='left' sx={{ padding: '5px 0px', width: 100, borderBottom: 'none' }}>
                                        Total
                                      </TableCell>
                                      <TableCell width='50px' align='right' sx={{ padding: '5px 0px', borderBottom: 'none' }}>
                                        0
                                      </TableCell>
                                      <TableCell align='right' sx={{ padding: '5px 0px', width: '211px', borderBottom: 'none' }}>
                                        {formatNumber(totalStartingBalance)}
                                      </TableCell>
                                      <TableCell align='right' sx={{ padding: '5px 0px', width: '211px', borderBottom: 'none' }}>
                                        {formatNumber(totalActivity)}
                                      </TableCell>
                                      <TableCell align='right' sx={{ padding: '5px 0px', width: '211px', borderBottom: 'none' }}>
                                        {formatNumber(totalBalance)}
                                      </TableCell>
                                    </TableRow>
                                  </TableBody>
                                </Table>
                              </AccordionDetails>
                            </Accordion>
                          </React.Fragment>
                        )
                      })}
                    </TableCell>
                  </TableRow>
                </TableBody>
              </Table>
              {(() => {
                const { totalActivity, totalBalance, startingBalance } = calculateAccountTypeTotal(subTypes)
                return (
                  <TableRow
                    sx={{
                      display: 'flex',
                      alignItems: 'flex-end',
                      justifyContent: 'space-between',
                      padding: 0,
                    }}
                  >
                    <div style={{ margin: 0, padding: 0 }}>
                      <TableCell align='left' sx={{ width: '211px', borderBottom: 'none', paddingLeft: '0px' }}>
                        Total
                      </TableCell>
                    </div>
                    <div style={{ margin: 0, padding: '0 0px 0 0' }}>
                      <TableCell align='right' sx={{ padding: '16px 0', width: '211px', borderBottom: 'none' }}>
                        {formatNumber(startingBalance)}
                      </TableCell>
                      <TableCell align='right' sx={{ padding: '16px 0', width: '211px', borderBottom: 'none' }}>
                        {formatNumber(totalActivity)}
                      </TableCell>
                      <TableCell align='right' sx={{ padding: '16px 0', width: '211px', borderBottom: 'none' }}>
                        {formatNumber(totalBalance)}
                      </TableCell>
                    </div>
                  </TableRow>
                )
              })()}
            </AccordionDetails>
          </Accordion>
          {accountType === 'Cost of Sales' && (
            <Table>
              <TableBody>
                <TableRow id='grossProfit' sx={{ borderTop: '1px solid lightgray' }}>
                  <TableCell align='left'>Gross Profit</TableCell>
                  <TableCell align='left'></TableCell>
                  <TableCell align='right' sx={{ padding: '16px 0', width: '211px' }}>
                    {formatNumber(grossProfit.startingBalance)}
                  </TableCell>
                  <TableCell align='right' sx={{ padding: '16px 0', width: '211px' }}>
                    {formatNumber(grossProfit.grossProfitActivity)}
                  </TableCell>
                  <TableCell align='right' sx={{ padding: '16px 0', width: '211px' }}>
                    {formatNumber(grossProfit.grossProfitBalance)}
                  </TableCell>
                </TableRow>
              </TableBody>
            </Table>
          )}
        </React.Fragment>
      ))} */}

        {_.first(data)?.FinancialStatement !== 'Balance Sheet' && (
          <Table>
            <TableBody>
              <TableRow>
                <TableCell sx={{ padding: '16px', width: '211px', fontWeight: 'bold' }}>Net Profit</TableCell>
                <TableCell align='right' sx={{ padding: '16px', width: '211px', fontWeight: 'bold' }}>
                  -
                </TableCell>

                <TableCell align='right' sx={{ padding: '16px', width: '211px', fontWeight: 'bold' }}>
                  {formatNumber(netProfit.netProfitStartingBalance)}
                </TableCell>
                <TableCell align='right' sx={{ padding: '16px', width: '211px', fontWeight: 'bold' }}>
                  {formatNumber(netProfit.netProfitActivity)}
                </TableCell>
                <TableCell align='right' sx={{ padding: '16px', width: '211px', fontWeight: 'bold' }}>
                  {formatNumber(netProfit.netProfitBalance)}
                </TableCell>
              </TableRow>
            </TableBody>
          </Table>
        )}
      </StyleWrapper>
    </div>
  )
}

export default IncomeStatement
