import {
  Box,
  Card,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  FormControl,
  Grid,
  InputLabel,
  MenuItem,
  Modal,
  Select,
  Typography,
  generateUtilityClasses,
  useTheme,
} from '@mui/material'
import { border, styled } from '@mui/system'
import React, { useEffect, useMemo, useState } from 'react'
import { Form } from 'react-final-form'
import DropdownFieldSearch from '../../../../ui/DropdownFieldSearch'
import { CustomCircular, TextField } from '../../../../ui'
import Button from '@mui/material/Button'
import LoadingButton from '@mui/lab/LoadingButton'
import { UspConfigurationCallOutcomeViewResult } from '../../../../models/apiTypes'
import {
  useAddCallOutcomeMutation,
  useAddSmsTemplateMutation,
  useDeleteSmsTemplateMutation,
  useEditCallOutcomeMutation,
  useEditSmsTemplateMutation,
  useGetSmsTemplateMutation,
  useGetSmsTemplateTokenMutation,
  useLazyGetCallOutcomeQuery,
  useSearchSmsTemplateCategoryMutation,
} from '../../../../services'

const classes = generateUtilityClasses('Box', ['root', 'row', 'buttonWrap', 'saveBtn'])

const StyledBox = styled(Box)(({ theme }) => ({
  [`&.${classes.root}`]: {
    position: 'absolute' as 'absolute',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    width: '390px',
    backgroundColor: theme.palette.background.main,
    boxShadow: 24,
    padding: theme.spacing(3),
  },
  [`& .${classes.row}`]: {
    margin: theme.spacing(1, 0, 2, 0),
  },
  [`& .${classes.buttonWrap}`]: {
    display: 'flex',
    justifyContent: 'flex-start',
    flexDirection: 'row-reverse',
    padding: theme.spacing(3, 0, 2, 0),
    marginTop: 'auto',
  },
  [`& .${classes.saveBtn}`]: {
    marginLeft: theme.spacing(2),
  },
}))

interface Props {
  rowData: UspConfigurationCallOutcomeViewResult
  open: boolean
  onClose: () => void
  type: string
  submit: (...args: any) => any
}
const AddEditSmsTemplate = ({ open, onClose, rowData, type, submit }: Props) => {
  const theme = useTheme()
  const [initialValues, setInitialValues] = useState({})
  const [tokenValue, setTokenValue] = useState(null)
  const [addSmsTemplate, { isLoading: isLoadingSmsTemplate, isSuccess: isSuccessSmsTemplate }] = useAddSmsTemplateMutation()
  const [editSmsTemplate, { isLoading: isLoadingEditSmsTemplate, isSuccess: isSuccessEditSmsTemplate }] = useEditSmsTemplateMutation()
  const [getSmsTemplate, { isLoading: isLoadingGetSmsTemplate, isSuccess: isSuccessGetSmsTemplate, data }] = useGetSmsTemplateMutation()
  const [getSmsTemplateCategory, { isLoading: isLoadingSmsTemplateCategory, isSuccess: isSuccessSmsTemplateCategory, data: templateCategory }] =
    useSearchSmsTemplateCategoryMutation()
  const [getSmsTemplateToken, { isLoading: isLoadingSmsTemplateToken, isSuccess: isSuccessSmsTemplateToken, data: templateToken }] =
    useGetSmsTemplateTokenMutation()

  const handleSubmit = (values: any) => {
    if (type === 'edit') {
      editSmsTemplate({
        smsTemplateId: rowData?.ID?.toString(),
        uspConfigurationSmsTemplateAddEdit: {
          message: values.Message,
          templateCategoryID: values.Category,
          templateName: values.Name,
          dataSource: values.DataSource,
          isActive: values.IsActive,
        },
      })
    } else {
      addSmsTemplate({
        uspConfigurationSmsTemplateAddEdit: {
          message: values.Message,
          templateCategoryID: values.Category,
          templateName: values.Name,
          dataSource: values.DataSource,
          isActive: values.IsActive,
        },
      })
    }
  }

  useEffect(() => {
    if (open && rowData && type === 'edit')
      getSmsTemplate({
        sms_template_id: rowData?.ID?.toString() as string,
      })

    getSmsTemplateCategory({
      simpleSearch: {
        isIncludeInactive: false,
        searchString: null,
      },
    })
  }, [open, type, rowData])

  useEffect(() => {
    if (type === 'add') {
      setInitialValues({
        Outcome: '',
        IsActive: true,
      })
    }
    if (isSuccessGetSmsTemplate && data && type === 'edit') {
      setInitialValues({
        DataSource: data?.DataSource,
        Category: data?.TemplateCategoryID,
        Message: data?.Message,
        IsActive: data?.IsActive,
        Name: data?.TemplateName,
      })
      getSmsTemplateToken({
        data_source: data?.DataSource,
      })
    }
  }, [isSuccessGetSmsTemplate, data, type])

  useEffect(() => {
    if (isSuccessSmsTemplate || isSuccessEditSmsTemplate) {
      onClose()
      submit()
    }
  }, [isSuccessSmsTemplate, isSuccessEditSmsTemplate])

  const isLoading = useMemo(
    () => isLoadingGetSmsTemplate || isLoadingEditSmsTemplate || isLoadingSmsTemplate,
    [isLoadingGetSmsTemplate, isLoadingEditSmsTemplate, isLoadingSmsTemplate],
  )
  return (
    <Dialog open={open} onClose={onClose}>
      <DialogTitle>Sms Template</DialogTitle>
      <Form onSubmit={handleSubmit} initialValues={initialValues}>
        {({ values, handleSubmit, form: { change } }: any) => (
          <form onSubmit={handleSubmit}>
            <DialogContent>
              <Grid container spacing={2} py={1}>
                <Grid item xs={12}>
                  <TextField required name='Name' label='Name' fullWidth />
                </Grid>
                <Grid item xs={12}>
                  <DropdownFieldSearch
                    name='DataSource'
                    label='Data Source'
                    options={[
                      { key: 'Lead', values: 'Lead' },
                      { key: 'Deal', values: 'Deal' },
                    ]}
                    optionKey='key'
                    onChange={(value) => {
                      getSmsTemplateToken({
                        data_source: value,
                      })
                    }}
                    optionValue='values'
                    required
                  />
                </Grid>
                <Grid item xs={12}>
                  <DropdownFieldSearch
                    name='Category'
                    label='Category'
                    options={
                      templateCategory?.map((el) => {
                        return { key: el.ID, value: el.TemplateCategory }
                      }) ?? []
                    }
                    optionKey='key'
                    optionValue='value'
                    required
                  />
                </Grid>
                <Grid item xs={12}>
                  <Box sx={{ border: 1, borderColor: '#e8ecee', width: '100%', borderRadius: '8px' }} pb={2}>
                    <Grid container item xs={12} spacing={1} justifyContent={'flex-end'}>
                      <Grid item xs={12}>
                        <TextField
                          sx={{ '& fieldset': { border: 'none' } }}
                          required
                          InputLabelProps={{
                            sx: { background: theme.palette.background.paper, '&.Mui-focused': { background: theme.palette.background.paper } },
                          }}
                          name='Message'
                          label='Message'
                          multiline
                          rows={4}
                          fullWidth
                        />
                      </Grid>
                      <Grid item xs={4} px={2}>
                        <FormControl fullWidth>
                          <InputLabel size='small' id='demo-simple-select-label'>
                            Token
                          </InputLabel>
                          <Select
                            size='small'
                            labelId='demo-simple-select-label'
                            id='demo-simple-select'
                            sx={{ border: 'unset' }}
                            fullWidth
                            placeholder='Token'
                            label='Token'
                            value={tokenValue}
                            onChange={(e) => {
                              change('Message', `${values.Message ? values.Message : ''}{${e.target.value}}`)
                              setTokenValue(null)
                            }}
                          >
                            {templateToken?.ReturnData &&
                              JSON.parse(templateToken?.ReturnData).map((el: any) => (
                                <MenuItem value={el.Token}>{el.Token.replaceAll('_', ' ')}</MenuItem>
                              ))}
                          </Select>
                        </FormControl>
                      </Grid>
                    </Grid>
                  </Box>
                </Grid>
                <Grid item xs={12}>
                  <DropdownFieldSearch
                    name='IsActive'
                    label='Is Active?'
                    options={[
                      { key: true, values: 'Yes' },
                      { key: false, values: 'No' },
                    ]}
                    optionKey='key'
                    optionValue='values'
                  />
                </Grid>
              </Grid>
            </DialogContent>
            <DialogActions>
              <LoadingButton type='submit' className={classes.saveBtn} variant='contained'>
                Save
              </LoadingButton>
              <Button variant='contained' color='error' onClick={onClose}>
                Cancel
              </Button>
            </DialogActions>
          </form>
        )}
      </Form>
      <CustomCircular open={isLoading} />
    </Dialog>
  )
}

export default AddEditSmsTemplate
