import React from 'react'
import { styled } from '@mui/system'
import { CardHeader, Grid, Typography } from '@mui/material'
import { FEINTextField, DateField, SSNTextField } from '../../../../../ui'
import { generateUtilityClasses } from '@mui/material'
import { useFormState } from 'react-final-form'
import { useParams } from 'react-router-dom'

const classes = generateUtilityClasses('Grid', ['root', 'row', 'optionalText'])

const StyledGrid = styled(Grid)(({ theme }) => ({
  [`&.${classes.root}`]: {},
  [`& .${classes.row}`]: {
    margin: theme.spacing(1, 0, 2, 0),
  },
  [`& .${classes.optionalText}`]: {
    fontFamily: 'Open Sans, sans-serif',
    fontWeight: 400,
    Size: '0.8750em',
    fontStyle: 'italic',
    lineHeight: '1.1875em',
    marginLeft: theme.spacing(1),
  },
}))

interface Props {}

export const IdentityForm = ({}: Props) => {
  const { userId } = useParams()
  const { values } = useFormState()

  return (
    <>
      <CardHeader title='Identity (Optional)' />
      <Grid container spacing={2} px={3} pt={3}>
        {values?.accountType === 'individual' ? (
          <>
            <Grid item xs={12} sm={6}>
              <DateField name='dateOfBirth' label='Date Of Birth' disableFuture fullWidth />
            </Grid>
            <Grid item xs={12} sm={6}>
              <SSNTextField name='taxIDNumber' label='SSN' fullWidth />
            </Grid>
          </>
        ) : (
          <Grid item xs={12} sm={6}>
            <FEINTextField name='taxIDNumber' label='FEIN' fullWidth />
          </Grid>
        )}
      </Grid>
    </>
  )
}

export default IdentityForm
