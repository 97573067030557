import React, { useCallback, useEffect, useState } from 'react'
import { Button, Container, Grid, Popover, Typography, generateUtilityClasses } from '@mui/material'
import { styled } from '@mui/system'
import DropdownFieldSearch from '../../../../ui/DropdownFieldSearch'
import AddIcon from '@mui/icons-material/Add'
import { DateField, DropdownCheckboxes } from '../../../../ui'
import { getBranch } from '../../../../redux/slices/userDropdownSlice'
import { useSelector } from 'react-redux'
import { useFormState } from 'react-final-form'
import { withPermissionsDropdown } from '../../../../hoc'
import { debounce } from 'lodash'
import { VendorResponseSp } from '../../../../models'
import KeyboardArrowDownIcon from '@mui/icons-material/KeyboardArrowDown'
import AddSingleLineBillModal from '../components/AddSingleLineBillModal'
import { appRoute } from '../../../../constants'
import { useNavigate } from 'react-router-dom'
import { getUserAccessRights } from '../../../../redux/slices/authSlice'

const classes = generateUtilityClasses('Container', ['root', 'titleWrap', 'searchWrap', 'searchBtn'])

const StyledFormWrap = styled(Container)(({ theme }) => ({
  [`&.${classes.root}`]: {
    padding: theme.spacing(1, 1, 1, 1),
    margin: 0,
    display: 'flex',
    backgroundColor: theme.palette.background.main,
    width: '100%',
    maxWidth: 'unset',
    position: 'sticky',
    top: 0,
    zIndex: 10,
    alignItems: 'center',
  },
  [`& .${classes.titleWrap}`]: {
    margin: theme.spacing(2, 2, 1, 2),
  },
  [`& .${classes.searchWrap}`]: {
    width: theme.spacing(20),
    margin: theme.spacing(0, 4, 0, 0),
  },

  [`& .${classes.searchBtn}`]: {
    margin: theme.spacing(0, 2, 0, 'auto'),
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'space-evenly',
    minWidth: '90px',
    padding: '10px',
  },
}))

interface Props {
  submit: (...args: any) => any
  vendorListData: VendorResponseSp[]
  anchorElAdd?: any
  open?: any
  handleCloseAddMenu?: any
}

const BillingSearchBlock = ({ handleCloseAddMenu, submit, vendorListData, anchorElAdd, open }: Props) => {
  const branchData = useSelector(getBranch)
  const { values } = useFormState()
  const navigate = useNavigate()
  const userAccess = useSelector(getUserAccessRights)
  const [openAddSingleLineBillModal, setOpenAddSingleLineBillModal] = React.useState(false)

  const id = open ? 'add-menu-popover' : undefined

  const updateWithDebounce = useCallback(
    debounce(() => submit(values), 500),
    [],
  )

  const handleOpenAddSingleLineBillModal = () => setOpenAddSingleLineBillModal(true)
  const handleCloseAddSingleLineBillModal = () => setOpenAddSingleLineBillModal(false)

  useEffect(() => {
    // search with debounce
    if (values?.startDate?.length > 1 || values?.endDate) {
      updateWithDebounce()
    }
  }, [values?.startDate])

  useEffect(() => {
    // search with debounce
    if (values?.endDate?.length > 1 || values?.startDate) {
      updateWithDebounce()
    }
  }, [values?.endDate])

  useEffect(() => {
    if (values?.searchStatus || values?.branch || values?.dateRange || values?.dateRange !== '' || values?.searchVendor) {
      submit(values)
    }
  }, [values?.branch, values.searchStatus, values?.dateRange, values?.searchVendor])

  return (
    <>
      <Grid container spacing={2} p={3}>
        <Grid item xs={12} sm={3} md={2}>
          <DropdownFieldSearch
            name='searchStatus'
            label='Status'
            options={[
              { key: 'All', value: 'All' },
              { key: 'Unpaid', value: 'Unpaid' },
              { key: 'Paid', value: 'Paid' },
            ]}
            optionKey='key'
            optionValue='value'
          />
        </Grid>
        <Grid item xs={12} sm={3} md={2}>
          <DropdownCheckboxes fullWidth name='searchVendor' label='Vendor' options={vendorListData} optionKey='ID' optionValue='Name' />
        </Grid>
        {branchData?.length > 0 && (
          <Grid item xs={12} sm={3} md={2}>
            <DropdownCheckboxes
              name='branch'
              label='Branch'
              labelId='BranchId'
              fullWidth
              options={branchData}
              optionKey='BranchID'
              optionValue='Branch'
            />
          </Grid>
        )}
        <Grid item xs={12} sm={3} md={2}>
          <DropdownFieldSearch
            name='dateRange'
            label='Date'
            options={[
              { key: 'between', value: 'Between' },
              { key: 'today', value: 'Today' },
              { key: 'yesterday', value: 'Yesterday' },
              { key: 'lastSevenDays', value: 'Last Seven Days' },
              { key: 'lastThirtyDays', value: 'Last Thirty Days' },
            ]}
          />
        </Grid>
        {values?.dateRange === 'between' && (
          <>
            <Grid item xs={12} sm={3} md={2}>
              <DateField name='startDate' label='Start Date' fullWidth disableFuture />
            </Grid>
            <Grid item xs={12} sm={3} md={2}>
              <DateField name='endDate' label='End Date' fullWidth disableFuture />
            </Grid>
          </>
        )}

        <Popover
          id={id}
          open={open}
          anchorEl={anchorElAdd}
          onClose={handleCloseAddMenu}
          anchorOrigin={{
            vertical: 'bottom',
            horizontal: 'left',
          }}
        >
          <Grid item style={{ display: 'flex', flexDirection: 'column', width: '150px' }}>
            <Button
              style={{ justifyContent: 'flex-start', textTransform: 'capitalize', padding: '15px' }}
              onClick={() => {
                handleOpenAddSingleLineBillModal()
                handleCloseAddMenu()
              }}
            >
              Single-Line Bill
            </Button>
            <Button
              style={{ justifyContent: 'flex-start', textTransform: 'capitalize', padding: '15px' }}
              onClick={() => {
                navigate(appRoute.AccountingBillingMultiline)
                handleCloseAddMenu()
              }}
            >
              Multi-Line Bill
            </Button>
          </Grid>
        </Popover>
      </Grid>
      <AddSingleLineBillModal open={openAddSingleLineBillModal} handleClose={handleCloseAddSingleLineBillModal} vendorListData={vendorListData} />
    </>
  )
}

export default withPermissionsDropdown(BillingSearchBlock)
