import React, { useEffect, useState, useMemo } from 'react'
import { styled } from '@mui/material/styles'
import { AmountField, CustomCircular, DateField, TextField } from '../../../ui'
import { Field, Form } from 'react-final-form'
import { withPermissionsDropdown } from '../../../hoc'
import {
  Autocomplete,
  Box,
  Card,
  CardHeader,
  Container,
  FormControlLabel,
  IconButton,
  MenuItem,
  Stack,
  Switch,
  Tab,
  Tabs,
  generateUtilityClasses,
} from '@mui/material'
import validateForm from '../../../utils/validateForm'
import { addLeadsValidation } from '../../../validation'
import VpnKeyIcon from '@mui/icons-material/VpnKey'
import Grid from '@mui/material/Grid'
import Button from '@mui/material/Button'
import { useLocation, useNavigate, useParams } from 'react-router-dom'
import { TextField as MuiTextField } from '@mui/material'
import { appRoute } from '../../../constants'
import {
  selectDropdownByName,
  selectDropdownByNameActiveOnly,
  useAddContactFullMutation,
  useEditContactFullMutation,
  useFinalizeDealFinalizeUnwindFinanceMutation,
  useGetContactMutation,
  useLazyCarsVinReadQuery,
  useLazyGetContactCreditQuery,
  useLazyGetListFeeQuery,
  useLazyGetListProductQuery,
  useLazyGetStateDealSettingsQuery,
} from '../../../services'
import {
  UspContactFullAddEdit,
  UspDealAddEditFeeData,
  UspDealAddEditProductData,
  UspDealFinalizeUnwindFinance,
  UspGetDataContact,
} from '../../../models'
import { enqueueNotification } from '../../../redux/slices/notificationsSlice'
import { useDispatch, useSelector } from 'react-redux'
import ReactRouterPrompt from 'react-router-prompt'
import ConfirmDialog from '../../../components/ConfirmDialog'
import { getUserAccessRights } from '../../../redux/slices/authSlice'
import { setCreditAppID } from '../../../redux/slices/creditAppSlice'
import CustomBreadcrumbs from '../../../components/custom-breadcrumbs/custom-breadcrumbs'
import Label from '../../../components/label'
import Iconify from '../../../components/iconify'
import LoanAddEditBuyer from './LoanAddEditBuyer'
import BuyerItem from '../../Leads/LeadsList/components/buyer-item'
import CustomPopover from '../../../components/custom-popover/custom-popover'
import { usePopover } from '../../../components/custom-popover'
import DropdownFieldSearch from '../../../ui/DropdownFieldSearch'
import { getStates } from '../../../redux/slices/userDropdownSlice'
import FeePopover from '../../Deals/DealsAddEdit/subpages/DealStructure/components/FeePopover'
import ContractItemization from './components/ContractItemization/ContractItemization'
import Dealer from './components/Dealer/Dealer'
import Settings from './components/Settings/Settings'
import Contract from './components/Contract/Contract'
import removeMask from '../../../utils/removeMask'
import { addLoanValidation } from '../../../validation/addLoanValidation'
import { convertDateToString } from '../../../utils/dateUtils'

interface Props {
  initialTab?: 'applicant' | 'employment' | 'income'
}

export const AccountsNewLoan = ({ initialTab }: Props) => {
  const [getListProduct, { data: listProductData, isLoading: listProductIsLoading }] = useLazyGetListProductQuery()
  const [feesForm, setFeesForm] = useState<any[]>([])
  const [productsForm, setProductsForm] = useState<any[]>([])

  const [getStateDealSettings, { data: stateDealSettingsData, isSuccess: stateDealSettingsIsSuccess, isLoading: stateDealSettingsIsLoading }] =
    useLazyGetStateDealSettingsQuery()
  const [initFormValues, setInitFormValues] = useState<any>({
    salesPrice: '0.0',
    cashDown: '0.0',
    tradeAllowance: '0.0',
    tradeLien: '0.0',
    Fees: '0.0',
    Products: '0.0',
    stateTax: '0.0',
    cityTax: '0.0',
    countyTax: '0.0',
    miscellaneousTax: '0.0',
    docStamps: '0.0',
    odometerTypeID: 1,
    discountType: 'Rate',
  })
  const [formChanged, setFormChanged] = useState(false)
  const [fieldsDisabled, setDisableFields] = useState(false)
  const [showAddBuyer, setShowAddBuyer] = useState<any | null>(null)
  const [getContact, { data: searchContactData, isSuccess: getContactIsSuccess, isLoading: getContactIsLoading }] = useGetContactMutation()
  const [finalizeUnwind, { data: finalizeUnwindData, isSuccess: finalizeUnwindIsSuccess, isLoading: finalizeUnwindIsLoading }] =
    useFinalizeDealFinalizeUnwindFinanceMutation()
  const exteriorColor = useSelector((state) => selectDropdownByName(state, 'CarExteriorColor'))
  const interiorColor = useSelector((state) => selectDropdownByName(state, 'CarInteriorColor'))
  const carOdometerType = useSelector((state) => selectDropdownByName(state, 'CarOdometerType'))
  const carBodyType = useSelector((state) => selectDropdownByNameActiveOnly(state, 'CarBodyType'))
  const [currentVin, setCurrentVin] = useState('')
  const [carsVin, { data: carsVinData, isLoading: carsVinIsLoading }] = useLazyCarsVinReadQuery()
  const [contactId1, setContactId1] = useState<any>(null)
  const [contactId2, setContactId2] = useState<any>(null)
  const [contactId1Display, setContactId1Display] = useState<any>(null)
  const [contactId2Display, setContactId2Display] = useState<any>(null)
  const [userSelected, setUserSelected] = useState(0)
  const popover = usePopover()
  const [deleteConfirmDialog, setDeleteConfirmDialog] = useState(false)
  const states = useSelector(getStates)

  const dispatch = useDispatch()
  const navigate = useNavigate()
  const { state } = useLocation()
  // const { state } = useParams()

  const userAccess = useSelector(getUserAccessRights)
  const handleSubmit = (values: any) => {
    const jsonFeeData: UspDealAddEditFeeData[] = []
    Object?.keys(values)?.forEach((item: string) => {
      if (item?.includes('FeeFieldValue')) {
        console.log(item)
        const id = item?.replace('FeeFieldValue', '')
        if (values[`FeeFieldIsActive${id}`] && Number(removeMask(values[`FeeFieldValue${id}`])) !== 0) {
          jsonFeeData.push({
            StateFeeID: Number(id),
            FeePrice: Number(removeMask(values[`FeeFieldValue${id}`])) || 0,
          })
        }
      }
    })

    const jsonProductData: UspDealAddEditProductData[] = []
    Object?.keys(values)?.forEach((item: string) => {
      if (item?.includes('ProductFieldValue')) {
        const id = item?.replace('ProductFieldValue', '')
        if (values[`ProductFieldIsActive${id}`] && Number(removeMask(values[`ProductFieldValue${id}`])) !== 0) {
          jsonProductData.push({
            StateProductID: Number(id),
            ProductPrice: Number(removeMask(values[`ProductFieldValue${id}`])) || 0,
          })
        }
      }
    })
    const formData: UspDealFinalizeUnwindFinance = {
      financeDealID: null,
      salesDealID: null,
      isDealCalculated: false,
      contactID1: contactId1,
      contactID2: contactId2,
      stockNumber: values.stockNumber,
      vin: values.vin,
      modelYear: values.modelYear,
      make: values.make,
      modelTrim: values.modelTrim,
      model: values.model,
      bodyTypeID: values.bodyTypeID,
      mileage: values.mileage,
      odometerTypeID: values.odometerTypeID,
      exteriorColor: values.exteriorColor,
      bookValue1: removeMask(values.bookValue1) as string as string,
      bookValue2: removeMask(values.bookValue2) as string,
      firstDueDate: convertDateToString(values.firstDueDate) as string,
      amountFinanced: removeMask(values.amountFinanced) as string,
      financeCharges: removeMask(values.financeCharges) as string,
      paymentSchedule: values.paymentSchedule,
      paymentAmount: removeMask(values.paymentAmount) as string,
      term: values.term,
      finalPaymentAmount: removeMask(values.finalPaymentAmount) as string,
      dealState: values.state,
      interestMethod: values.interestMethod,
      apr: values.apr,
      lastPaidDate: convertDateToString(values.lastPaidDate) as string,
      nextDueDate: convertDateToString(values.nextDueDate) as string,
      principalBalance: removeMask(values.principalBalance) as string,
      grossBalance: removeMask(values.balanceRemaining) as string,
      interestPaid: removeMask(values.interestPaid) as string,
      paidTowardsAdvance: removeMask(values.paidTowardsAdvance) as string,
      branchID: values.branch,
      collectorUserID: values.collector,
      salespersonUserID: values.salesperson,
      salesPrice: removeMask(values.salesPrice) as string,
      cashDownAmount: removeMask(values.cashDown) as string,
      tradeAllowance: removeMask(values.tradeAllowance) as string,
      tradeLien: removeMask(values.tradeLien) as string,
      jsonFeeData: jsonFeeData,
      jsonProductData: jsonProductData,
      stateTaxAmount: removeMask(values.stateTax) as string,
      cityTaxAmount: removeMask(values.cityTax) as string,
      countyTaxAmount: removeMask(values.countyTax) as string,
      miscTaxAmount: removeMask(values.miscellaneousTax) as string,
      docStamps: removeMask(values.docStamps) as string,
      dealerID: values.Dealer,
      discountAmount: removeMask(values.discountAmount) as string,
      reserveAmount1: removeMask(values.reserveAmount1) as string,
      reserveAmount2: removeMask(values.reserveAmount2) as string,
      reserveAmount3: removeMask(values.reserveAmount3) as string,
      reserveAmount4: removeMask(values.reserveAmount4) as string,
      fiUserID: values.manager,
      dealDate: convertDateToString(values.contractDate) as string,
      advertisementID: values.leadSource,
    }
    finalizeUnwind({
      uspDealFinalizeUnwindFinance: formData,
    })
    setFormChanged(false)
  }

  useEffect(() => {
    if (finalizeUnwindData) {
      dispatch(enqueueNotification({ message: finalizeUnwindData.Response }))
    }
    if (finalizeUnwindIsSuccess) {
      navigate(appRoute.Accounts)
    }
  }, [finalizeUnwindData, finalizeUnwindIsSuccess, stateDealSettingsIsLoading])

  useEffect(() => {
    const bodyType = carBodyType?.find(({ BodyType }) => BodyType === carsVinData?.BodyType)?.ID

    if (carsVinData) {
      const vinBasedInitialValues = {
        modelYear: carsVinData?.ModelYear,
        make: carsVinData?.Make,
        vin: currentVin,
        model: carsVinData?.Model,
        modelTrim: carsVinData?.ModelTrim,
        bodyType: bodyType,
        engine: carsVinData?.Engine,
        fuelType: carsVinData?.FuelType,
        doors: carsVinData?.Doors,
        drivetrain: carsVinData?.Drivetrain,
        cylinders: carsVinData?.Cylinders,
        bodyTypeID: carsVinData?.BodyTypeID,
        fuelTypeID: carsVinData?.FuelTypeID,
        driveTrainID: carsVinData?.DriveTrainID,
      }
      setInitFormValues({
        ...initFormValues,
        ...vinBasedInitialValues,
      })
    }
  }, [carsVinData])

  useEffect(() => {
    async function fetchContactData() {
      if (contactId1) {
        const res = await getContact({
          contactId: contactId1,
        })
        setContactId1Display((res as any)?.data)
        setFormChanged(true)
      }
    }
    fetchContactData()
  }, [contactId1, showAddBuyer])

  useEffect(() => {
    async function fetchContactData() {
      if (contactId2) {
        const res = await getContact({
          contactId: contactId2,
        })
        setContactId2Display((res as any)?.data)
        setFormChanged(true)
      }
    }
    fetchContactData()
  }, [contactId2, showAddBuyer])

  useEffect(() => {
    if (currentVin) {
      carsVin({ vin: currentVin })
    }
  }, [currentVin])

  useEffect(() => {
    getStateDealSettings({ state: 'XX' })
  }, [])

  const handleDelete = () => {
    // if (contactId2?.ContactID2) {
    //   deleteCoBuyer({
    //     dealId: dealID,
    //     coBuyerId: contactId2?.ContactID2,
    //   })
    // }

    setDeleteConfirmDialog(false)
  }

  useEffect(() => {
    const initialData: any = {}
    const feeData: any[] = [] // set deal fee data
    let feeInitialSum = 0
    if (stateDealSettingsData) {
      stateDealSettingsData?.StateFeeDataInJson?.forEach((item) => {
        if (item?.IsActive) {
          feeData.push({
            id: item?.StateFeeID,
            fieldName: `FeeFieldValue${item?.StateFeeID}`,
            fieldLabel: item?.FeeLabel,
            fieldCheckboxName: `FeeFieldIsActive${item?.StateFeeID}`,
            feeAmount: item?.FeeAmount || '0',
            feeInitialAmount: item?.FeeAmount || '0',
            isFixedAmount: item?.IsFixedAmount,
            isTaxable: item?.IsTaxable,
          })
          if (item?.FeeLabel && item?.IsFixedAmount) {
            feeInitialSum += Number(item?.FeeAmount)
          }

          initialData[`FeeFieldValue${item?.StateFeeID}`] = item?.IsFixedAmount ? item?.FeeAmount?.toString() : '0'
          initialData[`FeeIsTaxableValue${item?.StateFeeID}`] = item?.IsTaxable
          initialData[`FeeFieldIsActive${item?.StateFeeID}`] = false //item?.IsFixedAmount
          initialData[`FeeFieldProfitType${item?.StateFeeID}`] = item?.ProfitType
          initialData[`FeeFieldFeeLabel${item?.StateFeeID}`] = item?.FeeLabel
        }
      })
      setFeesForm(feeData)

      const productData: any[] = [] // set products fee data
      stateDealSettingsData?.ProductDataInJson?.forEach((item) => {
        if (item?.IsActive) {
          productData.push({
            id: item?.StateProductID,
            fieldName: `ProductFieldValue${item?.StateProductID}`,
            fieldLabel: item?.ProductLabel,
            fieldCheckboxName: `ProductFieldIsActive${item?.StateProductID}`,
            productAmount: item?.ProductPrice || '0',
            productInitialAmount: item?.ProductPrice || '0',
            isFixedAmount: item?.IsFixedPrice,
            isTaxable: item?.IsTaxable,
          })
          // if (item?.ProductLabel) {
          //   productsInitialSum += Number(item?.ProductPrice)
          // }

          initialData[`ProductFieldValue${item?.StateProductID}`] = '0' //item?.ProductPrice?.toString() || '0'
          initialData[`ProductIsTaxableValue${item?.StateProductID}`] = item?.IsTaxable
          initialData[`ProductFieldIsActive${item?.StateProductID}`] = false //item?.IsFixedPrice
          initialData[`ProductFieldProfitType${item?.StateProductID}`] = item?.ProfitType
          initialData[`ProductFieldProfitLabel${item?.StateProductID}`] = item?.ProductLabel
          initialData[`ProductFieldProfitCostRate${item?.StateProductID}`] = item?.CostRate
          initialData[`ProductFieldProfitCostAmount${item?.StateProductID}`] = item?.CostAmount
        }
      })

      setProductsForm(productData)

      initialData.interestMethod = stateDealSettingsData?.InterestMethod
      initialData.state = stateDealSettingsData?.State
      setInitFormValues({
        ...initialData,
        ...initFormValues,
      })
    }
  }, [stateDealSettingsData])

  return (
    <Container>
      <Form keepDirtyOnReinitialize onSubmit={handleSubmit} initialValues={initFormValues} validate={validateForm(addLoanValidation)}>
        {({
          handleSubmit,
          values,
          form: { change }, // pass custom mutator here
        }: any) => (
          <form
            style={{ display: showAddBuyer ? 'none' : 'block' }}
            onSubmit={handleSubmit}
            onChange={() => {
              setFormChanged(true)
            }}
          >
            <ReactRouterPrompt when={formChanged} beforeConfirm={async () => {}}>
              {({ isActive, onConfirm, onCancel }) => (
                <ConfirmDialog open={!!isActive} onOk={onConfirm} handleClose={onCancel} notification={'Warning: Your changes haven’t been saved.'} />
              )}
            </ReactRouterPrompt>

            {/* <Header
                  handleTabChange={handleTabChange}
                  tabValue={tabValue}
                  errorsInTabs={errorsInTabs}
                  headerData={searchContactData as unknown as UspGetDataContact}
                /> */}
            <Box display={'flex'}>
              <CustomBreadcrumbs
                heading='Account'
                links={[
                  { name: 'List', href: '/leads' },
                  {
                    name: 'New',
                  },
                ]}
                sx={{
                  mb: { xs: 3, md: 5 },
                }}
              />
              {/* <Label color={creditAppData?.LeadStatusColor as LabelColor} sx={{ ml: 2, alignSelf: 'center' }}>
                    {creditAppData?.LeadStatus}
                  </Label> */}
            </Box>
            <Grid container spacing={3}>
              <Grid container item xs={12} md={8} spacing={3} direction={'column'}>
                <Grid item>
                  <Card sx={{ height: '100%' }}>
                    <CardHeader
                      title='Buyer'
                      action={
                        (contactId1 == null || contactId2 == null) && (
                          <Button
                            size='small'
                            color='primary'
                            onClick={() => (contactId1 ? setShowAddBuyer('co-buyer') : setShowAddBuyer('buyer'))}
                            startIcon={<Iconify icon='mingcute:add-line' />}
                          >
                            {contactId1 ? 'New Co-Buyer' : 'New Buyer'}
                          </Button>
                        )
                      }
                    />
                    <Stack spacing={2.5} sx={{ p: 3 }}>
                      {contactId1Display && (
                        <BuyerItem
                          variant='outlined'
                          name={contactId1Display?.DisplayName}
                          address={contactId1Display?.ContactAddressData?.[0]}
                          phoneNumber={contactId1Display?.ContactPhoneData?.[0]?.PhoneNumber}
                          action={
                            <IconButton
                              color={popover.open ? 'inherit' : 'default'}
                              onClick={(e) => {
                                popover.onOpen(e)
                                setUserSelected(contactId1Display.ContactID)
                              }}
                              sx={{ position: 'absolute', top: 8, right: 8 }}
                            >
                              <Iconify icon='eva:more-vertical-fill' />
                            </IconButton>
                          }
                          sx={{
                            p: 2.5,
                            borderRadius: 1,
                          }}
                        />
                      )}
                      {contactId2Display && (
                        <BuyerItem
                          variant='outlined'
                          name={contactId2Display?.DisplayName}
                          address={contactId2Display?.ContactAddressData?.[0]}
                          phoneNumber={contactId2Display?.ContactPhoneData?.[0]?.PhoneNumber}
                          action={
                            <IconButton
                              onClick={(event: React.MouseEvent<HTMLElement>) => {
                                popover.onOpen(event)

                                setUserSelected(contactId1Display.ContactID)
                                // console.log(buyer.id)
                              }}
                              sx={{ position: 'absolute', top: 8, right: 8 }}
                            >
                              <Iconify icon='eva:more-vertical-fill' />
                            </IconButton>
                          }
                          sx={{
                            p: 2.5,
                            borderRadius: 1,
                          }}
                        />
                      )}
                    </Stack>
                  </Card>
                </Grid>
                <Grid item>
                  <Card sx={{ height: '100%' }}>
                    <CardHeader title='Collateral' />
                    <Grid container spacing={3} sx={{ p: 3 }}>
                      <Grid item xs={12} sm={6}>
                        <TextField name='stockNumber' label='Stock Number (Optional)' fullWidth autoComplete='off' />
                      </Grid>
                      <Grid item xs={12} sm={6}>
                        <TextField
                          onChange={(e) => {
                            setCurrentVin(e.target.value)
                          }}
                          name='vin'
                          label='VIN'
                          fullWidth
                          autoComplete='off'
                          required
                        />
                      </Grid>
                      <Grid item xs={12} sm={3} md={2}>
                        <TextField name='modelYear' label='Year' fullWidth autoComplete='off' />
                      </Grid>
                      <Grid item xs={12} sm={3} md={4}>
                        <TextField name='make' label='Make' fullWidth autoComplete='off' required />
                      </Grid>
                      <Grid item xs={12} sm={6}>
                        <TextField name='model' label='Model' fullWidth autoComplete='off' />
                      </Grid>
                      <Grid item xs={12} sm={6}>
                        <TextField name='modelTrim' label='Trim' fullWidth autoComplete='off' />
                      </Grid>
                      {carBodyType && (
                        <Grid item xs={12} sm={6}>
                          <DropdownFieldSearch
                            name='bodyTypeID'
                            label='Body'
                            options={carBodyType}
                            optionKey='ID'
                            optionValue='BodyType'
                            autoComplete='off'
                            onChange={() => {
                              //   onDropdownFormChange()
                            }}
                          />
                        </Grid>
                      )}

                      <Grid item xs={12} sm={3}>
                        <TextField name='mileage' label='Mileage' fullWidth autoComplete='off' />
                      </Grid>
                      {carOdometerType && (
                        <Grid item xs={12} sm={3}>
                          <DropdownFieldSearch
                            name='odometerTypeID'
                            label='Odometer Type'
                            fullWidth
                            options={carOdometerType?.ReturnData}
                            optionKey='ID'
                            optionValue='OdometerType'
                            initialValue={1}
                            disableClearable
                            onChange={() => {
                              //   onDropdownFormChange()
                            }}
                          />
                        </Grid>
                      )}
                      <Grid item xs={12} sm={6}>
                        {/* <TextField  name='exteriorColor' label='Color' fullWidth autoComplete='off' /> */}
                        <Field name='exteriorColor'>
                          {(props) => (
                            <Autocomplete
                              freeSolo
                              onChange={(e, value) => {
                                props.input.onChange(value)
                              }}
                              value={props.input.value}
                              options={exteriorColor?.ReturnData?.map((option: any) => option.Color) ?? []}
                              renderInput={(params) => (
                                <MuiTextField
                                  onChange={(e) => {
                                    props.input.onChange(e.target.value)
                                  }}
                                  {...params}
                                  label='Color'
                                />
                              )}
                            />
                          )}
                        </Field>
                      </Grid>
                      {/* <Grid item xs={12} sm={6}>
                        <Field name='interiorColor'>
                          {(props) => (
                            <Autocomplete
                              freeSolo
                              onChange={(e, value) => {
                                props.input.onChange(value)
                              }}
                              value={props.input.value}
                              options={exteriorColor?.ReturnData?.map((option: any) => option.Color) ?? []}
                              renderInput={(params) => (
                                <MuiTextField
                                  onChange={(e: any) => {
                                    props.input.onChange(e.target.value)
                                  }}
                                  {...params}
                                  label='Interior Color'
                                />
                              )}
                            />
                          )}
                        </Field>
                      </Grid> */}
                      <Grid item xs={12} sm={6}>
                        <AmountField variant='standard' name='bookValue1' label='Book Value 1' fullWidth autoComplete='off' />
                      </Grid>
                      <Grid item xs={12} sm={6}>
                        <AmountField variant='standard' name='bookValue2' label='Book Value 2' fullWidth autoComplete='off' />
                      </Grid>
                    </Grid>
                  </Card>
                </Grid>
                <Grid item>
                  <Contract />
                </Grid>

                <Grid item>
                  <ContractItemization productsForm={productsForm} feesForm={feesForm} />
                </Grid>
                <Grid item>
                  <Settings />
                </Grid>
                <Grid item>
                  <Dealer />
                </Grid>
                <Box
                  sx={{
                    display: 'flex',
                    flexDirection: 'row',
                    gap: 2,
                    marginLeft: 'auto',
                    position: { xs: 'absolute', sm: 'sticky' },
                    bottom: { xs: 0, sm: 16 },
                    right: 20,
                    p: 3,
                    width: 'fit-content',
                  }}
                >
                  <Button variant='contained' color='error' onClick={() => navigate(-1)}>
                    Cancel
                  </Button>
                  <Button disabled={!formChanged} type='submit' variant='contained'>
                    Save & Close
                  </Button>
                </Box>
              </Grid>
            </Grid>
          </form>
        )}
      </Form>
      {/* <CustomCircular open={isLoading} /> */}
      <CustomPopover open={popover.open} onClose={popover.onClose} arrow='top-right' sx={{ width: 140 }}>
        <MenuItem
          onClick={(event: React.MouseEvent<HTMLElement>) => {
            setShowAddBuyer(userSelected == contactId1 ? 'buyer' : 'co-buyer')
            popover.onClose()

            // navigate(
            //   appRoute.DealEditBuyer.replace(':dealID', dealID as string)
            //     .replace(':contactID', userSelected?.toString() || 'add')
            //     .replace(':contactType', ${userSelected === data?.ContactID2 ? 'co-buyer' : 'buyer'}),
            // )
          }}
        >
          <Iconify icon='solar:pen-bold' />
          Edit
        </MenuItem>
        {userSelected === contactId2?.ContactID2 && (
          <MenuItem
            onClick={() => {
              setDeleteConfirmDialog(true)
              popover.onClose()
            }}
            sx={{ color: 'error.main' }}
          >
            <Iconify icon='solar:trash-bin-trash-bold' />
            Delete
          </MenuItem>
        )}
      </CustomPopover>

      <ConfirmDialog
        open={deleteConfirmDialog}
        onOk={handleDelete}
        notificationTitle={'Delete Co-Buyer'}
        handleClose={() => setDeleteConfirmDialog(false)}
        notification={'Co-Buyer will be removed'}
      />

      {showAddBuyer && (
        <LoanAddEditBuyer
          setShowAddBuyer={setShowAddBuyer}
          contactID={userSelected ? userSelected : 'add'}
          setContactID={showAddBuyer == 'buyer' ? setContactId1 : setContactId2}
        />
      )}
    </Container>
  )
}

export default withPermissionsDropdown(AccountsNewLoan)
