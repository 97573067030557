import { Box, Button, Card, Container, Grid, Paper, generateUtilityClasses } from '@mui/material'
import { styled } from '@mui/system'
import React, { useEffect, useMemo, useState } from 'react'
import { useNavigate, useParams } from 'react-router-dom'
import { appRoute } from '../../../../constants'
import { Form } from 'react-final-form'
import Header from './components/Header/Header'
import NameForm from './components/NameForm/NameForm'
import AdministrativeForm from './components/AdministrativeForm/AdministrativeForm'
import InventoryForm from './components/InventoryForm/InventoryForm'
import CreditAppsForm from './components/CreditAppsForm/CreditAppsForm'
import DealsForm from './components/DealsForm/DealsForm'
import AccountsForm from './components/AccountsForm/AccountsForm'
import CollectionsForm from './components/CollectionsForm/CollectionsForm'
import InsightsForm from './components/InsightsForm/InsightsForm'
import TitleForm from './components/TitleForm/TitleForm'
import AccountingForm from './components/AccountingForm/AccountingForm'
import { useDispatch } from 'react-redux'
import { useAddUserRoleMutation, useEditUserRoleMutation, useLazyGetUserRoleQuery } from '../../../../services'
import { enqueueNotification } from '../../../../redux/slices/notificationsSlice'
import { transformToFormValues, transformToRequestValues } from './transform'
import { CustomCircular } from '../../../../ui'
import SettingsForm from './components/SettingsForm/SettingsForm'
import CustomBreadcrumbs from '../../../../components/custom-breadcrumbs/custom-breadcrumbs'

const classes = generateUtilityClasses('Box', ['root', 'cardRow', 'buttonWrap', 'saveBtn', 'pageBody'])

const StyledBox = styled(Box)(({ theme }) => ({
  [`&.${classes.root}`]: {
    display: 'flex',
    flexDirection: 'column',
    minHeight: '100vh',
  },
  [`& .${classes.pageBody}`]: {
    padding: theme.spacing(2, 2, 0, 2),
  },
  [`& .${classes.cardRow}`]: {
    padding: theme.spacing(2, 3, 3, 3),
    margin: theme.spacing(0),
    borderRadius: 0,
  },
  [`& .${classes.buttonWrap}`]: {
    display: 'flex',
    justifyContent: 'flex-between',
    flexDirection: 'row-reverse',
    padding: theme.spacing(2, 2, 2, 2),
    marginTop: 'auto',
  },
  [`& .${classes.saveBtn}`]: {
    marginLeft: theme.spacing(2),
  },
}))

const AddEditAdminUserRoles = () => {
  const navigate = useNavigate()
  const { userRoleId } = useParams()
  const dispatch = useDispatch()
  const [initialValues, setInitialValues] = useState({})

  const [getUserRole, { data: userRoleData, isLoading: getUserRoleIsLoading, isSuccess: getUserRoleIsSuccess }] = useLazyGetUserRoleQuery()
  const [addUserRole, { isSuccess: isSuccessUserRoleDataAdd, isLoading: addUserRoleIsLoading }] = useAddUserRoleMutation()
  const [editUserRole, { isSuccess: isSuccessUserRoleDataEdit, isLoading: editUserRoleIsLoading }] = useEditUserRoleMutation()

  useEffect(() => {
    if (userRoleId !== 'add') {
      getUserRole({ userRoleId: userRoleId as string })
    }
  }, [userRoleId])

  useEffect(() => {
    if (getUserRoleIsSuccess) {
      setInitialValues(transformToFormValues(userRoleData))
    }
  }, [userRoleData, getUserRoleIsSuccess, userRoleId])

  const handleSubmit = (values: any) => {
    if (userRoleId === 'add') {
      addUserRole({
        uspConfigurationUserRoleAddEdit: transformToRequestValues(values) as any,
      })
    } else {
      editUserRole({
        userRoleId: userRoleId as string,
        uspConfigurationUserRoleAddEdit: transformToRequestValues(values) as any,
      })
    }
  }

  useEffect(() => {
    if (isSuccessUserRoleDataEdit || isSuccessUserRoleDataAdd) {
      dispatch(enqueueNotification({ message: 'Success' }))
      navigate(appRoute.SettingsAdminUserRoles)
    }
  }, [isSuccessUserRoleDataEdit, isSuccessUserRoleDataAdd])

  const isLoading = useMemo(
    () => getUserRoleIsLoading || addUserRoleIsLoading || editUserRoleIsLoading,
    [getUserRoleIsLoading, addUserRoleIsLoading, editUserRoleIsLoading],
  )

  return (
    <Container>
      <CustomBreadcrumbs
        heading='User Role'
        links={[{ href: appRoute.SettingsAdminUserRoles, name: 'List' }, { name: 'User Role' }]}
        // action={
        //   <Button
        //     onClick={() => navigate(appRoute?.VendorAddEdit.replace(':vendorId', 'add'))}
        //     variant='contained'
        //     startIcon={<Iconify icon='mingcute:add-line' />}
        //   >
        //     Add new
        //   </Button>
        // }
        sx={{
          mb: {
            xs: 3,
            md: 5,
          },
        }}
      />
      <Card sx={{ overflow: 'visible' }}>
        <Form onSubmit={handleSubmit} initialValues={{ IsActive: true, ...initialValues }}>
          {({ handleSubmit }: any) => (
            <form onSubmit={handleSubmit}>
              <Box sx={{ overflow: 'visible', display: 'flex', flexDirection: 'column', alignItems: 'flex-end', height: '100%' }}>
                {/* <Header /> */}
                <Grid container>
                  <Grid item xs={12}>
                    <NameForm />
                  </Grid>
                  <Grid item xs={12}>
                    <SettingsForm />
                  </Grid>
                  <Grid item xs={12}>
                    <InventoryForm />
                  </Grid>
                  <Grid item xs={12}>
                    <CreditAppsForm />
                  </Grid>
                  <Grid item xs={12}>
                    <DealsForm />
                  </Grid>
                  <Grid item xs={12}>
                    <AccountsForm />
                  </Grid>
                  <Grid item xs={12}>
                    <CollectionsForm />
                  </Grid>
                  <Grid item xs={12}>
                    <AccountingForm />
                  </Grid>
                  <Grid item xs={12}>
                    <InsightsForm />
                  </Grid>
                  <Grid item xs={12}>
                    <TitleForm />
                  </Grid>
                  <Grid item xs={12}>
                    <AdministrativeForm />
                  </Grid>
                </Grid>
                <Box
                  sx={{
                    display: 'flex',
                    flexDirection: 'row',
                    gap: 2,
                    position: { xs: 'absolute', sm: 'sticky' },
                    bottom: { xs: 0, sm: 16 },
                    right: 20,
                    py: 3,
                    width: 'fit-content',
                  }}
                >
                  <Button variant='contained' color='error' onClick={() => navigate(appRoute?.SettingsAdminUserRoles)}>
                    Cancel
                  </Button>
                  <Button type='submit' variant='contained'>
                    Save & Close
                  </Button>
                </Box>
              </Box>
            </form>
          )}
        </Form>
      </Card>
      <CustomCircular open={isLoading} />
    </Container>
  )
}

export default AddEditAdminUserRoles
