import React from 'react'
import { styled } from '@mui/system'
import { CardHeader, Grid, Typography } from '@mui/material'
import { FEINTextField } from '../../../../../../ui'
import { generateUtilityClasses } from '@mui/material'

const classes = generateUtilityClasses('Grid', ['root', 'row'])

const StyledGrid = styled(Grid)(({ theme }) => ({
  [`&.${classes.root}`]: {},
  [`& .${classes.row}`]: {
    margin: theme.spacing(1, 0, 2, 0),
  },
}))

interface Props {}

export const IdentityForm = ({}: Props) => {
  return (
    <>
      <CardHeader title='Identity' />
      <Grid container spacing={2} px={3} pt={3}>
        <Grid item xs={12} sm={6}>
          <FEINTextField name='TaxIDNumber' label='FEIN' fullWidth />
        </Grid>
      </Grid>
    </>
  )
}

export default IdentityForm
