import React, { useEffect, useState } from 'react'
import { Form } from 'react-final-form'
import { useSearchInsuranceMutation } from '../../../services'
import { UspConfigurationInsuranceViewResult } from '../../../models'
import Header from './components/Header/Header'
import InsuranceTable from './components/InsuranceTable/InsuranceTable'
import { Button, Card, Container, Grid } from '@mui/material'
import CustomBreadcrumbs from '../../../components/custom-breadcrumbs/custom-breadcrumbs'
import Iconify from '../../../components/iconify'
import { appRoute } from '../../../constants'
import { useNavigate } from 'react-router-dom'

const SettingsDealsInsurance = () => {
  const [searchIPAddresses, { data, isLoading }] = useSearchInsuranceMutation()
  const [insuranceData, setInsuranceData] = useState<UspConfigurationInsuranceViewResult[]>([])
  const navigate = useNavigate()
  const handleSubmit = (values?: any) => {
    searchIPAddresses({
      simpleSearch: {
        searchString: values?.searhString,
        isIncludeInactive: values?.isIncludeInactive,
      },
    })
  }

  useEffect(() => {
    if (data) {
      setInsuranceData(data)
    }
  }, [data])

  return (
    <Container>
      <CustomBreadcrumbs
        heading='Insurance'
        links={[
          {
            name: 'List',
          },
        ]}
        action={
          <Button
            onClick={() => navigate(appRoute.SettingsDealsInsuranceAddEdit.replace(':insuranceId', 'add'))}
            variant='contained'
            startIcon={<Iconify icon='mingcute:add-line' />}
          >
            Add new
          </Button>
        }
        sx={{
          mb: {
            xs: 3,
            md: 5,
          },
        }}
      />
      <Form onSubmit={handleSubmit} initialValues={{ searhString: null, isIncludeInactive: false }}>
        {({ handleSubmit, form: { submit } }: any) => (
          <>
            <form onSubmit={handleSubmit}>
              <Card>
                <Grid container spacing={2}>
                  <Grid item xs={12}>
                    <Header submit={submit} title='' />
                  </Grid>
                  <Grid item xs={12}>
                    <InsuranceTable loading={isLoading} data={insuranceData} setData={setInsuranceData} />
                  </Grid>
                </Grid>
              </Card>
            </form>
          </>
        )}
      </Form>
    </Container>
  )
}

export default SettingsDealsInsurance
