import { createApi } from '../utils/api'
import { ReactComponent as Dashboard } from '../../src/assets/icons/dashboard.svg'
import { ReactComponent as Inventory } from '../../src/assets/icons/inventory.svg'
import { ReactComponent as Leads } from '../../src/assets/icons/leads.svg'
import { ReactComponent as Deals } from '../../src/assets/icons/deals.svg'
import { ReactComponent as Accounts } from '../../src/assets/icons/accounts.svg'
import { ReactComponent as Collections } from '../../src/assets/icons/collections.svg'
import { ReactComponent as Accounting } from '../../src/assets/icons/accounting.svg'
import { ReactComponent as Insights } from '../../src/assets/icons/insights.svg'
import { ReactComponent as Settings } from '../../src/assets/icons/settings.svg'
import { uniqueId } from '../utils/general'

export const apiEndpoint = process.env.REACT_APP_API_ENDPOINT || 'https://api.elev8dms.com/api/v1'

export const appApi = createApi(apiEndpoint)

export const appRoute = {
  Home: '/',
  SignIn: '/sign-in',
  SignUp: '/invite/:inviteID',
  // http://elev8dms.com/invite/dc276d2f-f6fd-4033-847b-592f7d0b1e55/
  ResetPassword: '/reset-password',
  ChangePassword: '/change-password',
  SelectSession: '/select-session',
  LeadsList: '/leads',
  LeadsAddEdit: '/leads/:leadID/:contactID/:contactType',
  LeadsTask: '/leads/:leadID/task',
  LeadsReference: '/leads/:leadID/:contactID/reference',
  LeadsReferenceAddEdit: '/leads/:leadID/:contactID/reference/:referenceID',
  LeadsStipulations: '/leads/:leadID/stipulations',
  Inventory: '/inventory',
  DealsList: '/deals',
  DealsAddEdit: '/deals/:dealID',
  DealsAddEditLease: '/deals/:dealID/lease',
  DealsTask: '/deals/:dealID/task',
  DealAddBuyer: '/deals/:dealID/add/buyer',
  DealEditBuyer: '/deals/:dealID/:contactID/:contactType',
  DealEditCoBuyer: '/deals/:dealID/:contactID/co-buyer',
  DealsTrade: '/deals/:dealID/trade',
  DealsTradeAdd: '/deals/trade/:dealID/add',
  DealTradeAddVin: '/deals/trade/:dealID/:vin/add',
  DealsTradeEdit: '/deals/trade/:dealID/:tradeID/edit',
  DealsInsuranceAdd: '/deals/:dealID/insurance/add',
  DealsInsuranceEdit: '/deals/:dealID/insurance/:insuranceID/edit',
  DealsReference: '/deals/:dealID/:contactID/reference',
  DealsReferenceAddEdit: '/deals/:dealID/:contactID/reference/:referenceID',
  DealsCommissions: '/deals/:dealID/commissions',
  DealsProducts: '/deals/:dealID/products',
  DealsReferral: '/deals/:dealID/referral',
  DealsReferralAddEdit: '/deals/:dealID/referral/:referral',
  Accounts: '/accounts',
  AccountsLoanPurchase: '/accounts/loan-purchase',
  AccountsLoanPurchaseNew: '/accounts/loan-purchase/new',
  AccountsOverview: '/accounts/:dealID/overview',
  AccountsPayHistory: '/accounts/:dealID/pay-history',
  AccountsNoteHistory: '/accounts/:dealID/note-history',
  AccountsBuyerEdit: '/accounts/:dealID/:contactID/:contactType',
  AccountsReferencesList: '/accounts/:dealID/references',
  AccountsReferencesAddEdit: '/accounts/:dealID/:contactID/reference/:referenceID',
  AccountsBankruptcy: '/accounts/:dealID/bankruptcy',
  AccountsReferralEdit: '/accounts/:dealID/referral/:referral',
  AccountsTradeEdit: '/accounts/trade/:dealID/:tradeID/edit',
  AccountsTitle: '/accounts/:dealID/title',
  AccountsRepo: '/accounts/:dealID/repo',
  AccountsDeal: '/accounts/:dealID/deal',
  Payments: '/payments',
  AccountingTasks: '/accounting',
  AccountingList: '/accounting/ledgerdetails',
  AccountingFinancials: '/accounting/financials',
  AccountingBankDeposits: '/accounting/bankDeposits',
  AccountingJournalEntry: '/accounting/journal-entry',
  AccountingChartOfAccounts: '/accounting/chart-of-accounts',
  AccountingChecks: '/accounting/checks',
  AccountingChecksAdd: '/accounting/checks/add',
  AccountingBilling: '/accounting/billing',
  AccountingBillingMultiline: '/accounting/billing/multiline',
  AccountingBillPay: '/accounting/bill-pay',
  AccountingFinancialsIncomeStatement: '/accounting/financials/income-statement',
  AccountingFinancialsBalanceSheet: '/accounting/financials/balance-sheet',
  AccountingFinancialsTrialBalance: '/accounting/financials/trial-balance',
  AccountingDetails: '/ledgerdetails/:accountingLedgerDetailID',
  CollectionsMyWorklist: '/collections/my-work-list',
  CollectionsCreateWorklist: '/collections/create-work-list',
  CollectionsLogWorklist: '/collections/log-work-list',
  Insights: '/reports',
  InsightsInventoryAging: '/reports/reports-inventory-aging',
  InsightsTradeLiens: '/reports/reports-trade-liens',
  InsightsAccountsPayableAging: '/reports/reports-accounts-payable-aging',
  InsightsAccountsSubsidiaryReconciliation: '/reports/reports-accounts-subsidiary-reconciliation',
  InsightsAccountsChargeOffs: '/reports/reports-accounts-charge-offs',
  InsightsSalesTax: '/reports/reports-sales-tax',
  InsightsSales: '/reports/reports-sales',
  InsightsPayments: '/reports/reports-payments',
  InsightsReceivables: '/reports/reports-receivables',
  InsightsReceivablesAging: '/reports/reports-receivables-aging',
  InsightsReceivablesRecency: '/reports/reports-receivables-recency',
  InsightsSalesCommissions: '/reports/reports-sales-commissions',
  Dashboard: '/dashboard',
  Profile: '/profile',
  UserList: '/settings/admin/users',
  VendorsList: '/settings/admin/vendors',
  VendorAddEdit: '/settings/admin/vendors/:vendorId',
  LendersList: '/settings/admin/lenders',
  LenderAddEdit: '/settings/admin/lenders/:lenderId',
  UserAddEdit: '/settings/admin/users/:userId',
  UsersSearch: '/settings/users/search',
  UsersContacts: '/settings/users/contacts',
  InventoryAddVin: '/inventory/add/:vin',
  InventoryAdd: '/inventory/add',
  InventoryEdit: '/inventory/edit/:carID/general',
  InventoryEditExpenses: '/inventory/edit/:carID/expenses',
  InventoryEditNotes: '/inventory/edit/:carID/notes',
  InventoryEditFloorplan: '/inventory/edit/:carID/floorplan',
  InventoryEditPictures: '/inventory/edit/:carID/pictures',
  InventoryEditPayments: '/inventory/edit/:carID/payments',
  InventoryEditDocuments: '/inventory/edit/:carID/documents',
  InventoryEditPrint: '/inventory/edit/:carID/print',
  SettingsAdminUserRoles: '/settings/admin/user-roles',
  SettingsAdminAddEditUserRoles: '/settings/admin/user-roles/:userRoleId',
  SettingsAdminVendorTypes: '/settings/admin/vendor-types',
  SettingsAdmingGlobalSettings: '/settings/admin/global-settings',
  SettingsAdminIPAddresses: '/settings/admin/ip-addresses',
  SettingsAdminDealers: '/settings/admin/dealers',
  DealerAddEdit: '/settings/admin/dealers/:dealerId',
  SettingsAdminRFC: '/settings/admin/rfc',
  SettingsAdminLots: '/settings/admin/lots',
  SettingsInventoryExpenses: '/settings/inventory/expenses',
  SettingsInventoryPurchaseTypes: '/settings/inventory/purchase-types',
  SettingsInventoryCarMakes: '/settings/inventory/car-makes',
  SettingsInventoryDrivetrains: '/settings/inventory/drivetrains',
  SettingsInventoryFuelTypes: '/settings/inventory/fuel-types',
  SettingsInventoryTitleTypes: '/settings/inventory/title-types',
  SettingsInventoryBodyTypes: '/settings/inventory/body-types',
  SettingsInventoryConditions: '/settings/inventory/conditions',
  SettingsInventoryPackFees: '/settings/inventory/pack-fees',
  SettingsInventoryStatus: '/settings/inventory/inventory-status',
  SettingsLeadsLeadSources: '/settings/leads/lead-sources',
  SettingsLeadsStipulations: '/settings/leads/stipulations',
  SettingsLeadsInterestLevels: '/settings/leads/interest-levels',
  SettingsLeadsReferenceRelationships: '/settings/leads/reference-relationships',
  SettingsLeadsCreditAppStatuses: '/settings/leads/credit-app-statuses',
  SettingsLeadsCreditAppExpenses: '/settings/leads/credit-app-expenses',
  SettingsDealsDealStatuses: '/settings/deals/deal-statuses',
  SettingsDealsDealerReserve: '/settings/deals/dealer-reserve',
  SettingsDealsInsurance: '/settings/deals/insurance',
  SettingsDealsInsuranceAddEdit: '/settings/deals/insurance/:insuranceId',
  SettingsPaymentCheckTypes: '/settings/payment/check-types',
  SettingsPaymentPaymentForms: '/settings/payment/payment-forms',
  SettingsPaymentPaymentMethods: '/settings/payment/payment-methods',
  SettingsPaymentPaymentSideNote: '/settings/payment/side-note',
  SettingsPaymentCreditCardTypes: '/settings/payment/credit-card-types',
  SettingsCollectionsRepoTypes: '/settings/collections/repo-types',
  SettingsStateSettings: '/settings/state/state-settings',
  SettingsCommunicationsCallOutcome: '/settings/communications/call-outcomes',
  SettingsCommunicationsSmsTemplate: '/settings/communications/sms-templates',
  SettingsStateCityTaxes: '/settings/state/city-taxes',
  SettingsStateCountyTaxes: '/settings/state/county-taxes',
  SettingsFormsList: '/settings/form/form-mapping',
  SettingsFormsMapping: '/settings/form/forms-mapping/:formId',
  SettingsFormsPacksList: '/settings/form/form-packs',
  SettingsFormsFormList: '/settings/form-list/:formPackId',
  PrintForms: '/actions/print-forms/:dealID',
}

export const accountingLinks = [
  { id: uniqueId('BankDeposits', 0), link: null, text: 'Bank Deposits', ItemIcon: null },
  { id: uniqueId('Billing', 1), link: appRoute.AccountingBilling, text: 'Billing', ItemIcon: null },
  { id: uniqueId('BillPay', 2), link: appRoute.AccountingBillPay, text: 'Bill Pay', ItemIcon: null },
  { id: uniqueId('ChartOfAccounts', 3), link: appRoute.AccountingChartOfAccounts, text: 'Chart Of Accounts', ItemIcon: null },
  { id: uniqueId('Checks', 4), link: appRoute.AccountingChecks, text: 'Checks', ItemIcon: null },
  { id: uniqueId('Financials', 5), link: appRoute.AccountingFinancials, text: 'Financials', ItemIcon: null },
  { id: uniqueId('JournalEntry', 6), link: appRoute.AccountingJournalEntry, text: 'Journal Entry', ItemIcon: null },
  { id: uniqueId('Reconciliation', 7), link: null, text: 'Reconciliation', ItemIcon: null },
  { id: uniqueId('ViewLedgerDetails', 8), link: appRoute.AccountingList, text: 'View Ledger Details', ItemIcon: null },
]

export const collectionsLinks = [
  { id: uniqueId('MyWorklist', 0), link: appRoute.CollectionsMyWorklist, text: 'My Worklist', ItemIcon: null },
  { id: uniqueId('CreateWorklist', 1), link: appRoute.CollectionsCreateWorklist, text: 'Create a Worklist', ItemIcon: null },
  { id: uniqueId('LogIntoWorklist', 2), link: appRoute.CollectionsLogWorklist, text: 'Log into a Worklist', ItemIcon: null },
]

export const StorageKey = {
  access: 'access',
  refresh: 'refresh',
  email: 'email',
  clientID: 'clientID',
  companyType: 'companyType',
  branchID: 'branchID',
}

export const HeaderKey = {
  Authorization: 'Authorization',
  ClientID: 'CLIENT-UID',
  CompanyType: 'COMPANY-TYPE',
  CompanyBranch: 'COMPANY-BRANCH',
}

export const links = [
  { id: uniqueId('Dashboard', 0), link: appRoute.Dashboard, ItemIcon: Dashboard, text: 'Dashboard' },
  { id: uniqueId('Inventory', 1), link: appRoute.Inventory, ItemIcon: Inventory, text: 'Inventory' },
  { id: uniqueId('Leads', 2), link: appRoute.LeadsList, ItemIcon: Leads, text: 'Leads' },
  { id: uniqueId('Deals', 3), link: appRoute.DealsList, ItemIcon: Deals, text: 'Deals' },
  { id: uniqueId('Accounts', 4), link: appRoute.Accounts, ItemIcon: Accounts, text: 'Accounts' },
  {
    id: uniqueId('Collections', 5),
    link: null,
    ItemIcon: Collections,
    text: 'Collections',
    subLinks: collectionsLinks.map((link, index) => ({ ...link, id: uniqueId(link.text, index) })),
  },
  {
    id: uniqueId('Accounting', 6),
    link: null,
    ItemIcon: Accounting,
    text: 'Accounting',
    subLinks: accountingLinks.map((link, index) => ({ ...link, id: uniqueId(link.text, index) })),
  },
  { id: uniqueId('Insights', 7), link: appRoute.Insights, ItemIcon: Insights, text: 'Insights' },
  { id: uniqueId('Settings', 8), link: null, ItemIcon: Settings, text: 'Settings' },
]

export const taskLinks = [
  { id: uniqueId('GlobalSettings', 0), link: appRoute.SettingsAdmingGlobalSettings, text: 'Global Settings', ItemIcon: null },
  { id: uniqueId('Branches', 1), link: null, text: 'Branches', ItemIcon: null },
  { id: uniqueId('Companies', 2), link: null, text: 'Companies', ItemIcon: null },
  { id: uniqueId('Dealers', 3), link: appRoute.SettingsAdminDealers, text: 'Dealers', ItemIcon: null },
  { id: uniqueId('IPAddresses', 4), link: appRoute.SettingsAdminIPAddresses, text: 'IP Addresses', ItemIcon: null },
  { id: uniqueId('Lenders', 5), link: appRoute.LendersList, text: 'Lenders', ItemIcon: null },
  { id: uniqueId('Lots', 6), link: appRoute.SettingsAdminLots, text: 'Lots', ItemIcon: null },
  { id: uniqueId('RFC', 7), link: appRoute.SettingsAdminRFC, text: 'RFC', ItemIcon: null },
  { id: uniqueId('UserRoles', 8), link: appRoute.SettingsAdminUserRoles, text: 'User Roles', ItemIcon: null },
  { id: uniqueId('Users', 9), link: appRoute.UserList, text: 'Users', ItemIcon: null },
  { id: uniqueId('VendorTypes', 10), link: appRoute.SettingsAdminVendorTypes, text: 'Vendor Types', ItemIcon: null },
  { id: uniqueId('Vendors', 11), link: appRoute.VendorsList, text: 'Vendors', ItemIcon: null },
]

export const collectionLinks = [{ id: uniqueId('RepoTypes', 1), link: appRoute.SettingsCollectionsRepoTypes, text: 'Repo Types', ItemIcon: null }]

export const communicationLinks = [
  { id: uniqueId('CallOutcomes', 0), link: appRoute.SettingsCommunicationsCallOutcome, text: 'Call Outcomes', ItemIcon: null },
  { id: uniqueId('SMSTemplates', 1), link: appRoute.SettingsCommunicationsSmsTemplate, text: 'SMS Templates', ItemIcon: null },
  { id: uniqueId('SMSTemplateCategories', 2), link: null, text: 'SMS Template Categories', ItemIcon: null },
]

export const creditAppLeadLinks = [
  { id: uniqueId('LeadSources', 0), link: appRoute.SettingsLeadsLeadSources, text: 'Lead Sources', ItemIcon: null },
  { id: uniqueId('ApplicantExpenses', 1), link: appRoute.SettingsLeadsCreditAppExpenses, text: 'Applicant Expenses', ItemIcon: null },
  { id: uniqueId('CreditAppStatuses', 4), link: appRoute.SettingsLeadsCreditAppStatuses, text: 'Credit App Statuses', ItemIcon: null },
  { id: uniqueId('InterestLevels', 5), link: appRoute.SettingsLeadsInterestLevels, text: 'Interest Levels', ItemIcon: null },
  { id: uniqueId('ReferenceRelationships', 6), link: appRoute.SettingsLeadsReferenceRelationships, text: 'Reference Relationships', ItemIcon: null },
  { id: uniqueId('Stipulations', 7), link: appRoute.SettingsLeadsStipulations, text: 'Stipulations', ItemIcon: null },
]

export const dealLinks = [
  { id: uniqueId('DealStatuses', 0), link: appRoute.SettingsDealsDealStatuses, text: 'Deal Statuses', ItemIcon: null },
  { id: uniqueId('DealerReserves', 1), link: appRoute.SettingsDealsDealerReserve, text: 'Dealer Reserves', ItemIcon: null },
  { id: uniqueId('FeesMaster', 2), link: null, text: 'Fees (Master)', ItemIcon: null },
  { id: uniqueId('FeesState', 3), link: null, text: 'Fees (State)', ItemIcon: null },
  { id: uniqueId('FIProductsMaster', 4), link: null, text: 'F&I Products (Master)', ItemIcon: null },
  { id: uniqueId('FIProductsState', 5), link: null, text: 'F&I Products (State)', ItemIcon: null },
  { id: uniqueId('Insurance', 6), link: appRoute.SettingsDealsInsurance, text: 'Insurance', ItemIcon: null },
]

export const paymentLinks = [
  { id: uniqueId('CheckTypes', 0), link: appRoute.SettingsPaymentCheckTypes, text: 'Check Types', ItemIcon: null },
  { id: uniqueId('CreditCardTypes', 1), link: appRoute.SettingsPaymentCreditCardTypes, text: 'Credit Card Types', ItemIcon: null },
  { id: uniqueId('PaymentForms', 2), link: appRoute.SettingsPaymentPaymentForms, text: 'Payment Forms', ItemIcon: null },
  { id: uniqueId('PaymentMethods', 3), link: appRoute.SettingsPaymentPaymentMethods, text: 'Payment Methods', ItemIcon: null },
  { id: uniqueId('SideNotes', 4), link: appRoute.SettingsPaymentPaymentSideNote, text: 'Side Notes', ItemIcon: null },
]

export const stateLinks = [
  { id: uniqueId('StateSettings', 0), link: appRoute.SettingsStateSettings, text: 'State Settings', ItemIcon: null },
  { id: uniqueId('CityTaxes', 1), link: appRoute.SettingsStateCityTaxes, text: 'City Taxes', ItemIcon: null },
  { id: uniqueId('CountyTaxes', 2), link: appRoute.SettingsStateCountyTaxes, text: 'County Taxes', ItemIcon: null },
]

export const formsLinks = [
  { id: uniqueId('FormMapping', 0), link: appRoute.SettingsFormsList, text: 'Form Mapping', ItemIcon: null },
  { id: uniqueId('FormPacks', 1), link: appRoute.SettingsFormsPacksList, text: 'Form Packs', ItemIcon: null },
]
