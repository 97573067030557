import React from 'react'
import { Grid } from '@mui/material'
import { TextField } from '../../../../../ui'
import DropdownFieldSearch from '../../../../../ui/DropdownFieldSearch'
import { useSelector } from 'react-redux'
import { selectDropdownByNameActiveOnly } from '../../../../../services'

interface Props {
  onDropdownFormChange: () => void
  disableFields: boolean
}

export const AssignmentForm = ({ onDropdownFormChange, disableFields }: Props) => {
  const branch = useSelector((state) => selectDropdownByNameActiveOnly(state, 'Branch'))
  const carStatus = useSelector((state) => selectDropdownByNameActiveOnly(state, 'CarStatus'))
  const carFinder = useSelector((state) => selectDropdownByNameActiveOnly(state, 'CarFinder'))

  return (
    <Grid container spacing={{ xs: 2, md: 3 }}>
      <Grid item xs={12} sm={6}>
        {branch && (
          <DropdownFieldSearch
            disabled={disableFields}
            name='branchID'
            label='Branch'
            fullWidth
            options={branch}
            optionKey='BranchID'
            optionValue='Branch'
            onChange={() => {
              onDropdownFormChange()
            }}
          />
        )}
      </Grid>
      <Grid item xs={12} sm={6}>
        <DropdownFieldSearch
          disabled={disableFields}
          name='lotID'
          label='Lot'
          fullWidth
          options={[]}
          optionKey='ID'
          optionValue='Lot'
          onChange={() => {
            onDropdownFormChange()
          }}
        />
      </Grid>

      <Grid item xs={12} sm={6}>
        <TextField disabled={disableFields} name='location' label='Location' fullWidth autoComplete='off' />
      </Grid>

      <Grid item xs={12} sm={6}>
        {carStatus && (
          <DropdownFieldSearch
            disabled={disableFields}
            name='carStatusID'
            label='Status'
            options={carStatus}
            optionKey='ID'
            optionValue='Status'
            autoComplete='off'
            onChange={() => {
              onDropdownFormChange()
            }}
          />
        )}
      </Grid>

      {carFinder && (
        <Grid item xs={12} sm={6}>
          <DropdownFieldSearch
            disabled={disableFields}
            name='finderID'
            label='Finder'
            options={carFinder}
            optionKey='ID'
            optionValue='Name'
            autoComplete='off'
            onChange={() => {
              onDropdownFormChange()
            }}
          />
        </Grid>
      )}
      <Grid item xs={12} sm={6}>
        <TextField disabled={disableFields} name='tagNumber' label='Tag Number' fullWidth autoComplete='off' />
      </Grid>
      <Grid item xs={12} sm={6}>
        <DropdownFieldSearch
          disabled={disableFields}
          name='isSmogCompleted'
          label='Smog Completed?'
          options={[
            { key: true, value: 'Yes' },
            { key: false, value: 'No' },
          ]}
          autoComplete='off'
          onChange={() => {
            onDropdownFormChange()
          }}
        />
      </Grid>
    </Grid>
  )
}

export default AssignmentForm
