import React, { useEffect, useState } from 'react'
import { Form } from 'react-final-form'
import { useSearchCityTaxesMutation } from '../../../services'
import { UspStateCityTaxRateViewResult } from '../../../models'
import CityTaxesTable from './components/CityTaxesTable'
import Header from './components/Header'
import AddEditCityTaxes from './components/AddEditCityTaxes'
import { withPermissionsDropdown } from '../../../hoc'
import { Button, Card, Container, Grid } from '@mui/material'
import CustomBreadcrumbs from '../../../components/custom-breadcrumbs/custom-breadcrumbs'
import Iconify from '../../../components/iconify'

const SettingsStateCityTaxes = () => {
  const [rowData, setRowData] = useState({})
  const [openAddEditModal, setOpenAddEditModal] = useState(false)
  const [type, setType] = useState('')
  const [searchCityTaxes, { data, isLoading }] = useSearchCityTaxesMutation()
  const [cityTaxesData, setCityTaxesData] = useState<UspStateCityTaxRateViewResult[]>([])

  const handleSubmit = (values?: any) => {
    searchCityTaxes({
      uspStateCityTaxRateView: {
        state: values?.State || null,
        city: values?.City || null,
      },
    })
  }

  const handleOpenModal = () => {
    setOpenAddEditModal(true)
    setType('add')
  }

  const handleCloseModal = () => {
    setOpenAddEditModal(false)
  }

  useEffect(() => {
    if (data) {
      setCityTaxesData(data)
    }
  }, [data])
  return (
    <Container>
      <CustomBreadcrumbs
        heading='City Taxes'
        links={[
          {
            name: 'List',
          },
        ]}
        action={
          <>
            <Button onClick={handleOpenModal} variant='contained' startIcon={<Iconify icon='mingcute:add-line' />}>
              Add new
            </Button>
          </>
        }
        sx={{
          mb: {
            xs: 3,
            md: 5,
          },
        }}
      />
      <Form onSubmit={handleSubmit} initialValues={{ State: '', City: '' }}>
        {({ handleSubmit, form: { submit } }: any) => (
          <>
            <form onSubmit={handleSubmit}>
              <Card>
                <Grid container spacing={2}>
                  <Grid item xs={12}>
                    <Header submit={submit} title='City Taxes' handleOpenModal={handleOpenModal} />
                  </Grid>
                  <Grid item xs={12}>
                    <CityTaxesTable
                      setOpenAddEditModal={setOpenAddEditModal}
                      setType={setType}
                      setRowData={setRowData}
                      handleOpenModal={handleOpenModal}
                      loading={isLoading}
                      data={cityTaxesData}
                      setData={setCityTaxesData}
                    />
                  </Grid>
                </Grid>
              </Card>
            </form>
            <AddEditCityTaxes
              submit={submit}
              open={openAddEditModal}
              onClose={handleCloseModal}
              type={type}
              rowData={rowData as UspStateCityTaxRateViewResult}
            />
          </>
        )}
      </Form>
    </Container>
  )
}

export default withPermissionsDropdown(SettingsStateCityTaxes)
