import { useCallback, useEffect, useMemo, useState } from 'react'
import Grid from '@mui/material/Grid'
import { Box, Container, Dialog, IconButton, Paper, Tab, Tabs, Tooltip, Typography, useMediaQuery, useTheme } from '@mui/material'
import { Header, SettingsForm } from './components'
import { CustomCircular } from '../../../ui'
import { DealsCard } from './components/DealsCard'
import StipulationsCard from './components/StipulationsCard'
import AddEditStipulationsFormModal from '../LeadsStipulations/components/AddEditStipulationsFormModal'
import ReferencesCard from './components/ReferencesCard'
import InterestedInCard from './components/InterestedInCard'
import NotesCard from './components/NotesCard/NotesCard'
import { CreditAppStipulation } from '../../../models/leadTypes'
import InfoCard from '../../../components/InfoCard/InfoCard'
import EditIcon from '@mui/icons-material/Edit'
import InfoIcon from '@mui/icons-material/Info'
import { applyPhoneMask } from '../../../utils/general'
import DeleteIcon from '@mui/icons-material/Delete'
import { useNavigate, useParams } from 'react-router-dom'
import { appRoute } from '../../../constants'
import ConfirmDialog from '../../../components/ConfirmDialog'
import {
  selectDropdownByNameActiveOnly,
  useDeleteCreditAppCoAplicantMutation,
  useGetAccountNotesHistoryMutation,
  useGetCreditAppQuery,
  useLazyGetCreditAppQuery,
} from '../../../services'
import { useDispatch, useSelector } from 'react-redux'
import { enqueueNotification } from '../../../redux/slices/notificationsSlice'
import Address from '../../../components/InfoCard/Address'
import AddNoteModal from '../../Accounts/Header/components/AddNoteModal/AddNoteModal'
import LeadGeneral from '../LeadsList/components/leads-general'
import LeadNotes from '../LeadsList/components/leads-notes'
import CustomBreadcrumbs from '../../../components/custom-breadcrumbs/custom-breadcrumbs'
import Iconify from '../../../components/iconify'
import Label from '../../../components/label/label'
import { LabelColor } from '../../../components/label'
import PullCreditModal from './components/Modals/PullCreditModal'

export const LeadsTask = () => {
  const { leadID } = useParams()
  const navigate = useNavigate()
  const dispatch = useDispatch()
  const theme = useTheme()
  const isMobile = useMediaQuery(theme.breakpoints.down('sm'))

  const [currentTab, setCurrentTab] = useState('general')

  const handleChangeTab = useCallback((event: React.SyntheticEvent, newValue: string) => {
    setCurrentTab(newValue)
  }, [])

  const TABS = [
    {
      value: 'general',
      label: 'General',
      icon: <Iconify icon='solar:user-id-bold' width={24} />,
    },
    {
      value: 'notes',
      label: 'Notes',
      icon: <Iconify icon='solar:notes-bold' width={24} />,
    },
    {
      value: 'documents',
      label: 'Documents',
      disabled: true,
      icon: <Iconify icon='solar:bill-list-bold' width={24} />,
    },
  ]

  const [openSettingsModal, setOpenSettingsModal] = useState(false)
  const [openStipulationModal, setOpenStipulationModal] = useState(false)
  const [stipulationToEdit, setStipulationToEdit] = useState<CreditAppStipulation>()
  const [deleteConfirmDialog, setDeleteConfirmDialog] = useState(false)
  const [addNoteModalOpen, setAddNoteModalOpen] = useState(false)
  const [refetch, setRefetch] = useState(false)

  const [getAccountNotesHistory] = useGetAccountNotesHistoryMutation()
  const [getCreditAppData] = useLazyGetCreditAppQuery()
  const { data: creditAppData, isLoading: creditAppIsLoading } = useGetCreditAppQuery({
    creditAppId: leadID as string,
  })
  const [deleteCoApplicant, { isSuccess: deleteCoApplicantSuccess }] = useDeleteCreditAppCoAplicantMutation()
  const [isPullCreditModalOpen, setIsPullCreditModalOpen] = useState(false)
  const INTEGRATION_VENDOR = '700Credit'
  const leadLostReasonDropdown = useSelector((state) => selectDropdownByNameActiveOnly(state, 'Integrations'))
  const integrationVendor = leadLostReasonDropdown?.find(({ IntegrationVendor }) => IntegrationVendor === INTEGRATION_VENDOR)
  const bureausData = useMemo(() => (integrationVendor?.IntegrationData ? JSON.parse(integrationVendor?.IntegrationData) : []), [integrationVendor])

  const settingsCardData = useMemo(
    () =>
      creditAppData
        ? [
            { title: 'Branch', value: creditAppData.BranchCode },
            { title: 'Salesperson', value: creditAppData.Salesperson },
            { title: 'Lead Source', value: creditAppData.Advertisement },
            { title: 'App Status', value: creditAppData.Status },
            { title: 'Interest Level', value: creditAppData.InterestLevel },
            {
              title: 'Credit App',
              value: (
                <Typography
                  sx={{
                    color: (theme) => (creditAppData.IsPaperApplicationSigned ? theme.palette.success.light : theme.palette.error.main),
                  }}
                >
                  {creditAppData.IsPaperApplicationSigned ? 'Signed' : 'Not Signed'}
                </Typography>
              ),
            },
            {
              title: 'Privacy Policy',
              value: (
                <Typography
                  sx={{
                    color: (theme) => (creditAppData.IsPrivacyDisclosureProvided ? theme.palette.success.light : theme.palette.error.main),
                  }}
                >
                  {creditAppData.IsPrivacyDisclosureProvided ? 'Provided' : 'Not Provided'}
                </Typography>
              ),
            },
          ]
        : [],
    [creditAppData],
  )

  const handleDelete = () => {
    if (creditAppData?.CreditAppID && creditAppData?.ContactID2) {
      deleteCoApplicant({
        leadId: creditAppData?.CreditAppID,
        coApplicantId: creditAppData?.ContactID2,
      })
    }

    setDeleteConfirmDialog(false)
  }

  useEffect(() => {
    if (deleteCoApplicantSuccess) {
      dispatch(enqueueNotification({ message: 'Co-Buyer removed' }))
    }
  }, [deleteCoApplicantSuccess, dispatch, leadID])

  const buyerData = useMemo(
    () => [
      { title: '', value: creditAppData?.ApplicantName },
      {
        title: '',
        value: <Address addressData={creditAppData?.ApplicantAddressData[0]} />,
      },
      {
        title: '',
        value: creditAppData?.ApplicantPhone && <Typography textAlign='center'>Cell: {applyPhoneMask(creditAppData?.ApplicantPhone)}</Typography>,
      },
      {
        title: '',
        value: creditAppData?.ApplicantWorkPhone && (
          <Typography textAlign='center'>Work: {applyPhoneMask(creditAppData?.ApplicantWorkPhone)}</Typography>
        ),
      },
      { title: '', value: creditAppData?.Email },
    ],
    [creditAppData],
  )

  const coBuyerData = useMemo(
    () => [
      { title: '', value: creditAppData?.CoApplicantName },
      {
        title: '',
        value: <Address addressData={creditAppData?.CoApplicantAddressData[0]} />,
      },
      {
        title: '',
        value: creditAppData?.CoApplicantPhone && <Typography textAlign='center'>Cell: {applyPhoneMask(creditAppData?.CoApplicantPhone)}</Typography>,
      },
      {
        title: '',
        value: creditAppData?.CoApplicantWorkPhone && (
          <Typography textAlign='center'>Work: {applyPhoneMask(creditAppData?.CoApplicantWorkPhone)}</Typography>
        ),
      },
      { title: '', value: creditAppData?.CoApplicantEmail },
    ],
    [creditAppData],
  )

  useEffect(() => {
    // if redirected from another page (i.e. add references page)
    if (leadID) {
      getCreditAppData({
        creditAppId: leadID as string,
      })
    }
  }, [leadID, refetch])

  return (
    <>
      {/* <Grid container spacing={2} padding={2} justifyContent='flex-end'>
        <Grid item xs={12} sm={6} md={4} lg={4} xl={3}>
          <Box display='flex' flexDirection='column' gap={2}>
            <InfoCard
              data={buyerData}
              heading='Buyer'
              info={
                <Tooltip title={creditAppData?.ApplicantStatus} placement='right'>
                  <InfoIcon
                    sx={{
                      fill: creditAppData?.ApplicantStatusColor,
                      cursor: 'pointer',
                    }}
                  />
                </Tooltip>
              }
              action={
                <IconButton
                  color='primary'
                  onClick={() =>
                    navigate(
                      appRoute?.LeadsAddEdit.replace(':leadID', leadID as string)
                        .replace(':contactID', creditAppData?.ContactID1.toString() as string)
                        .replace(':contactType', 'applicant'),
                    )
                  }
                >
                  <EditIcon />
                </IconButton>
              }
            />
            {creditAppData?.IsHasCoApplicant && (
              <Grid item>
                <InfoCard
                  data={coBuyerData}
                  heading='Co-Buyer'
                  info={
                    <Tooltip title={creditAppData?.CoApplicantStatus} placement='right'>
                      <InfoIcon
                        sx={{
                          fill: creditAppData?.CoApplicantStatusColor,
                          cursor: 'pointer',
                        }}
                      />
                    </Tooltip>
                  }
                  action={
                    <Box>
                      <IconButton
                        color='primary'
                        onClick={() =>
                          navigate(
                            appRoute?.LeadsAddEdit.replace(':leadID', leadID as string)
                              .replace(':contactID', creditAppData?.ContactID2 !== null ? creditAppData?.ContactID2.toString() : '')
                              .replace(':contactType', 'applicant'),
                          )
                        }
                      >
                        <EditIcon />
                      </IconButton>

                      <Tooltip title='Delete'>
                        <IconButton color='error' onClick={() => setDeleteConfirmDialog(true)}>
                          <DeleteIcon />
                        </IconButton>
                      </Tooltip>
                    </Box>
                  }
                />
              </Grid>
            )}
            {creditAppData && (
              <InfoCard
                data={settingsCardData}
                action={
                  <IconButton color='primary' onClick={() => setOpenSettingsModal(true)}>
                    <EditIcon />
                  </IconButton>
                }
                info={
                  <Tooltip title={creditAppData.SettingsStatus} placement='right'>
                    <InfoIcon style={{ fill: creditAppData.SettingsStatusColor ? creditAppData.SettingsStatusColor : '#FFB946' }} />
                  </Tooltip>
                }
                heading='Settings'
              />
            )}
            {creditAppData && !!creditAppData.DealData.length && (
              <Grid item>
                <DealsCard deals={creditAppData.DealData} />
              </Grid>
            )}

            <Paper
              sx={{
                padding: 2,
              }}
            >
              <Typography variant='h6' align='center'>
                Credit insights
              </Typography>
            </Paper>
          </Box>
        </Grid>

        <Grid item container xs={12} sm={6} md={8} lg={8} xl={5} direction='column' rowSpacing={2}>
          <Grid item>
            {creditAppData && (
              <StipulationsCard
                addStipulation={(stipulation?: CreditAppStipulation) => {
                  if (st\ipulation) {
                    setStipulationToEdit(stipulation)
                  }
                  setOpenStipulationModal(true)
                }}
                stipulationsData={creditAppData.StipulationData}
                stipulationStatusColor={creditAppData.StipulationStatusColor}
                stipulationStatus={creditAppData.StipulationStatus}
              />
            )}
          </Grid>
          <Grid item>
            {creditAppData && (
              <ReferencesCard
                referencesData={creditAppData?.ReferenceData}
                referenceStatusColor={creditAppData.ReferenceStatusColor}
                ContactID1={creditAppData?.ContactID1}
              />
            )}
          </Grid>
          <Grid item>
            <InterestedInCard />
          </Grid>
        </Grid>

        <Grid item xs={12} sm={6} md={8} lg={8} xl={4}>
          <NotesCard openAddNoteModal={() => setAddNoteModalOpen(true)} />
        </Grid>
      </Grid> */}

      <CustomCircular open={creditAppIsLoading} />

      <Dialog open={openSettingsModal} onClose={() => setOpenSettingsModal(false)}>
        <SettingsForm creditAppData={creditAppData} closeModal={() => setOpenSettingsModal(false)} />
      </Dialog>
      {creditAppData && (
        <PullCreditModal
          isPullCreditModalOpen={isPullCreditModalOpen}
          setIsPullCreditModalOpen={setIsPullCreditModalOpen}
          bureausData={bureausData}
          dealData={creditAppData.DealData}
        />
      )}

      <ConfirmDialog
        open={deleteConfirmDialog}
        onOk={handleDelete}
        handleClose={() => setDeleteConfirmDialog(false)}
        notification={'Co-Buyer will be removed'}
      />

      <AddEditStipulationsFormModal
        open={openStipulationModal}
        onClose={() => {
          setStipulationToEdit(undefined)
          setOpenStipulationModal(false)
        }}
        editStipulation={stipulationToEdit}
      />

      <AddNoteModal
        open={addNoteModalOpen}
        onClose={() => setAddNoteModalOpen(false)}
        getAccountNotesHistory={getAccountNotesHistory}
        includeDeletedNotes={false}
      />

      <Container>
        <Box display={'flex'} sx={{ position: 'relative' }}>
          <CustomBreadcrumbs
            heading='Lead'
            links={[
              { name: 'List', href: '/leads' },
              {
                name: `${creditAppData?.ApplicantName}  ${creditAppData?.ApplicantPhone ? ' | ' + creditAppData?.ApplicantPhone : ''} ${
                  creditAppData?.Email ? ' | ' + creditAppData?.Email : ''
                } `,
              },
            ]}
            action={<Header />}
            sx={{
              mb: { xs: 3, md: 5 },
            }}
          />
          <Label
            color={creditAppData?.LeadStatusColor as LabelColor}
            sx={{ ml: 2, alignSelf: 'center', position: { xs: 'absolute', sm: 'relative' }, top: { xs: 3, sm: 0 }, left: { xs: 48, sm: 0 } }}
          >
            {creditAppData?.LeadStatus}
          </Label>
        </Box>

        <Tabs
          value={currentTab}
          onChange={handleChangeTab}
          sx={{
            mb: { xs: 3, md: 5 },
          }}
          variant='scrollable'
          // scrollButtons='auto'
          scrollButtons={false}
        >
          {TABS.map((tab) => (
            <Tab key={tab.value} label={tab.label} icon={tab.icon} disabled={tab.disabled} value={tab.value} />
          ))}
        </Tabs>

        {currentTab === 'general' && <LeadGeneral setRefetch={setRefetch} data={creditAppData} />}

        {currentTab === 'notes' && <LeadNotes data={creditAppData} />}
      </Container>
    </>
  )
}

export default LeadsTask
